import { IGallery } from '../interfaces/vision-recap-album.interface';

export const galleryData: IGallery[] = [
  {
    header: 'Day 0',
    title: 'Sky Suite Private Party',
    description: "Witness the magical view from City of Dreams Mediterranean's Sky Suite and party alongside HOI Agency, its exclusive sponsor!",
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/10.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/11.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/12.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/13.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/14.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/15.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/16.png',
      },
    ],
  },
  {
    header: 'Day 0',
    title: 'Welcome Sunset Party',
    description: "Step back in time and relive the magic of i-Con's pre-opening party and witness the start of it all!",
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/10.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/11.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/12.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/13.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/14.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/15.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/16.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/17.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/18.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/19.png',
      },
    ],
  },
  {
    header: 'Day 1',
    title: 'Exhibition',
    description: "Step into i-Con's exhibition, where networking takes center stage and collaborations take shape!",
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/10.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/11.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/12.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/13.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/14.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/15.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/16.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/17.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/18.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/19.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/20.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/21.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/22.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/23.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/24.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/25.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/26.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/27.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/28.png',
      },
    ],
  },
  {
    header: 'Day 1',
    title: 'Speeches',
    description: `Discover the value of i-Con's speeches and gain valuable knowledge from industry experts.`,
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/10.png',
      },
    ],
  },
  {
    header: 'Day 1',
    title: 'Aura Lunch Break',
    description: `Taste flavours from across the globe by paying a visit to City of Dream's Aura Restaurant!`,
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/aura-lunch/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/aura-lunch/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/aura-lunch/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/aura-lunch/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/aura-lunch/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/aura-lunch/thumbnails/6.png',
      },
    ],
  },
  {
    header: 'Day 1',
    title: 'i-Conic Opening Party',
    description: 'End an amazing day filled with networking and connections the only way you know how; Partying!',
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/10.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/11.png',
      },
    ],
  },
  {
    header: 'Day 1',
    title: 'Registration',
    description: 'Grab your badge and receive the warmest of welcomes to the world of i-Con!',
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/registration/1.jpg',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/registration/2.jpg',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/registration/3.jpg',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/registration/4.jpg',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/registration/5.jpg',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/registration/6.jpg',
      }
    ],
  },
  {
    header: 'Day 2',
    title: 'Spiritual Yoga Session',
    description: 'Replenish your mind, body and soul before another fun day of networking and valuable insights takes place.',
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/yoga-session/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/yoga-session/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/yoga-session/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/yoga-session/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/yoga-session/thumbnails/5.png',
      },
    ],
  },
  {
    header: 'Day 2',
    title: 'Exhibition',
    description:
      'Meet our amazing line-up with some of the biggest names in the industry! Visit their booths, talk business, and arrange profit-increasing meetings.',
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/10.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/11.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/12.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/13.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/14.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/15.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/16.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/17.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/18.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/19.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/20.png',
      },
    ],
  },
  {
    header: 'Day 2',
    title: 'Speeches',
    description: 'Meet some of the most influential speakers of the digital marketing world and pick their brains about the latest trends and tactics!',
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/10.png',
      },
    ],
  },
  {
    header: 'Day 2',
    title: 'Aura Lunch Break',
    description: `Experience delicious flavours carefully prepared by the Head Chefs of the Aura Restaurant at Europe's largest casino resort!`,
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/aura-lunch/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/aura-lunch/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/aura-lunch/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/aura-lunch/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/aura-lunch/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/aura-lunch/thumbnails/6.png',
      },
    ],
  },
  {
    header: 'Day 2',
    title: 'Closing Party',
    description: `As two networking-filled days come to an end, join the rest of your new connections for an exhilarating time at i-Con's Closing Party!`,
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/10.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/11.png',
      },
    ],
  },
  {
    header: 'Miconos',
    title: 'Miconos VIP Trip',
    description: 'Take a trip down the blue and white streets of Mykonos alongside CEOs, Business Owners and Super Affiliates!',
    images: [
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/1.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/2.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/3.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/4.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/5.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/6.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/7.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/8.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/9.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/10.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/11.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/12.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/13.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/14.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/15.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/16.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/17.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/18.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/19.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/20.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/21.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/22.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/23.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/24.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/25.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/26.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/27.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/28.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/29.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/30.png',
      },
      {
        src: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/31.png',
      },
    ],
  },
];
