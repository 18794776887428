import { Component, Input } from '@angular/core';

@Component({
  selector: 'vision-recap',
  templateUrl: './vision-recap.component.html',
  styleUrls: ['./vision-recap.component.scss'],
})
export class VisionRecapComponent {
  @Input() class_name: string = 'vision-recap';
  @Input() phrase: string = 'i-Con 2023';
  @Input() title1: string = 'RECAP';
}
