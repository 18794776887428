import { Component, Inject } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { UserAnswersService } from '../../service/userAnswers.service';
import { FIND_OUT_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-findout',
  templateUrl: './findout.component.html',
  styleUrl: './findout.component.scss',
})
export class FindOutComponent {
  checkedValues: IQuestionnaireUserAnswers;
  otherValue: string = '';
  otherSelected: boolean = false;
  constructor(@Inject(FIND_OUT_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService) {}

  handleCheckboxChange(item: ISurveyAnswer): void {
    if (item.value === 'other') {
      this.otherValue = '';
    }
    //if nothing is selected
    if (!this.checkedValues) {
      this.checkedValues = {
        questionId: this.content.id,
        answers: [
          {
            answerId: item.id,
            value: [item.value],
          },
        ],
      };
    }
    //else we at least have one selection
    else {
      const existingIndex = this.checkedValues.answers.findIndex(answer => answer.answerId === item.id);
      if (existingIndex !== -1) {
        this.checkedValues.answers.splice(existingIndex, 1);
      } else {
        const newItem = {
          answerId: item.id,
          value: [item.value],
        };
        this.checkedValues.answers.push(newItem);
      }
    }
    if (item.value === 'other') {
      this.otherSelected = !this.otherSelected;
    }
  }

  isValueIncluded(value: string): boolean {
    return this.checkedValues?.answers.some(answer => answer.value.includes(value));
  }

  handleInputChange(newValue: string): void {
    // Do something with the new input value
    this.otherValue = newValue;
    const index = this.checkedValues.answers.findIndex(answer => answer.value.includes('other'));
    if (index !== -1) {
      this.checkedValues.answers[index].description = this.otherValue;
    }
  }

  shouldNextBeEnabled(): boolean {
    return this.checkedValues?.answers?.length > 0 && (!this.otherSelected || this.otherValue.length !== 0);
  }

  nextClick() {
    if (this.checkedValues) this.userAnswersService.pushNewAnswer(this.checkedValues);
  }
}
