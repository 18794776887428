<div class="container">
    <h3>WANT TO PAY A VISIT TO ONE OF OUR EXHIBITORS?</h3>
    <p>You can now find their spot on our floor plan! As long as you have this map of our
        exhibit
        you won't miss any of your important networking meetings!</p>
    <div class="map-img">
        <img src="https://cdn-images.island-conference.com/attend/floor-plan/{{image}}_2024.png" />
    </div>
</div>
<div class="exhibitors-legend-container">
    <i-con-exhibitors-legend></i-con-exhibitors-legend>
</div>