import { ICard } from '../interfaces/winning-pitch.interface';

export const cardsData: ICard[] = [
  {
    id: 1,
    imageUrl: 'https://cdn-images.island-conference.com/attend/winning-pitch/complimentary-exhibition.svg',
    description:
      'Complimentary exhibition space in the specialized "tech" area, complete with a roller banner featuring their brand and a desk with two stools.',
    alt: 'Complimentary exhibition',
  },
  {
    id: 2,
    imageUrl: 'https://cdn-images.island-conference.com/attend/winning-pitch/complimentary-tickets.svg',
    description: 'Two complimentary tickets for each participating team.',
    alt: 'Complimentary tickets',
  },
  {
    id: 3,
    imageUrl: 'https://cdn-images.island-conference.com/attend/winning-pitch/speaking-slot.svg',
    description: 'A speaking slot on our schedule, enabling them to present their offerings to fellow exhibitors.',
    alt: 'Speaking slot',
  },
  {
    id: 4,
    imageUrl: 'https://cdn-images.island-conference.com/attend/winning-pitch/invitations-of-ceos-and-business-owners.svg',
    description: 'Invitations to CEOs and business owners who will attend these speeches and panels, potentially leading to investment opportunities.',
    alt: 'Invitations to CEOs and business owners',
  },
  {
    id: 5,
    imageUrl: 'https://cdn-images.island-conference.com/attend/winning-pitch/audience.svg',
    description: 'Guaranteed audience from the attendees heading to the Speakers Area.',
    alt: 'Guaranteed audience',
  },
  {
    id: 6,
    imageUrl: 'https://cdn-images.island-conference.com/attend/winning-pitch/extensive-network.svg',
    description: 'An extensive network-building opportunity and guidance from C-level executives in the online industry.',
    alt: 'Extensive network',
  },
];
