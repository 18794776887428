<div class="content">
    <h1>{{content.title}}</h1>
    <img class="astro" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2 [innerHTML]="content.description"></h2>
    <div class="options">
        <div class="row">
            <span class="empty"></span>
            <div class="numbers">
                <span *ngFor="let number of [].constructor(5); let i = index;">{{ i + 1 }}</span>
            </div>
        </div>
        <div class="row" *ngFor="let r of content.surveyAnswer">
            <span class="name">{{r.description}}</span>
            <mat-radio-group class="numbers" aria-label="Select an option">
                <mat-radio-button *ngFor="let rating of r.surveySubAnswers" [value]="rating"
                    (change)="onRadioChange(r.id, rating.name, rating.id)"></mat-radio-button>
            </mat-radio-group>
        </div>

    </div>

    <i-con-ctabuttons [nextEnabled]="selectedValues.size === content.surveyAnswer.length"
        (nextClick)="nextClick()"></i-con-ctabuttons>
</div>