<div class="questionnaire-header">
    <img src="https://cdn-images.island-conference.com/questionnaire/icon-logo.svg" alt="Island Conference">
</div>
<div class="container">
    <div class="card">

        <ng-container *ngIf="initialRenderedView$ | async as initialRenderedView">
            <div class="loadingSpinner"
                *ngIf="initialRenderedView.loading && !initialRenderedView.apiCallSucceeded; else contentContainer">
                <loading-spinner></loading-spinner>
            </div>
            <ng-template #contentContainer>
                <ng-container *ngIf="initialRenderedView.apiCallSucceeded; else errorContainer">
                    <ng-container
                        *ngComponentOutlet="getRenderedComponent().componentType; injector: getRenderedComponent().injector"></ng-container>
                </ng-container>
                <ng-template #errorContainer>
                    <ng-container *ngIf="errorObj$ | async as errorObj">
                        <div [ngClass]="{'alreadySubmitted': errorObj.statusCode === 409}" class="errorScreen">
                            <img [src]="errorObj.imageUrl" alt="">
                            <p class="error">{{errorObj.message}}</p>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-template>
        </ng-container>

    </div>

    <mat-progress-bar class="progress" [ngClass]="{'applyBorderRadius': progressBar > 30}" *ngIf="questionId !== 13"
        mode="determinate" [value]="progressBar"></mat-progress-bar>
</div>