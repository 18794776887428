import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TermsAndConditionsComponent } from '../../shared/footer/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../../shared/footer/privacy-policy/privacy-policy.component';
import { SubscribeDialogService } from '../../core/_services/subscribe-dialog/subscribe-dialog.service';


declare global {
	interface Window {
		webkitAudioContext: typeof AudioContext
	}
}


@Component({
	selector: 'app-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

	@Input() homepage: string = 'homepage';

	constructor(private activeRoute: ActivatedRoute,
				public dialog: MatDialog,
				private router: Router,
				private subscribeDialog: SubscribeDialogService
				) { 
				}
	
	ngOnInit(): void {

		
		
		this.activeRoute.queryParams.subscribe((param) => {
			const parameter = param['enquiry'];

			switch (parameter) {
				case 'terms': {
					this.dialog.open(TermsAndConditionsComponent, {
						maxHeight: '80vh',
						maxWidth: '85vw',
						autoFocus: false
					});
					break;
				}
				case 'privacy': {
					this.dialog.open(PrivacyPolicyComponent, {
						maxHeight: '80vh',
						maxWidth: '85vw',
						autoFocus: false
					});
					break;
				}
				case 'contact': {
					this.router.navigate(['/contact']);
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	appStore() {
		window.open('https://apps.apple.com/cy/app/island-conference-i-con/id6450279521','_blank');
	}

	playStore() {
		window.open('https://play.google.com/store/apps/details?id=com.nexxie.islandconference','_blank');
	}

	openDialog() {
		
	}
}
