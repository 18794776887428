<div class="headerForm">
    <img src="https://cdn-images.island-conference.com/pageheader/header_logo.png" alt="i-Con">
    <h1>{{fd.heading}}</h1>
    <button class="close-btn" mat-button mat-dialog-close (click)="closeDialog()">
        <img src="https://cdn-images.island-conference.com/icons/close-icon.svg" alt="Close">
    </button>
</div>

<div class="formContainer-wrapper"
    [ngClass]="{ fullHeight: responseState.success, overflowHidden: responseState.success}"
    *ngIf="responseState$ | async as responseState">

    <div class="success-msg flexr" *ngIf="responseState.success">
        <div class="left">
            <h2>Your Winning Pitch submission has been received!</h2>
            <p>
                Thank you for your interest in being featured under i-Con's StartUp companies.
                <br /> <br />
                A member of our team will contact you to find out more about your company and what being featured on
                i-Con's Winning Pitch would mean to you.
                <br /> <br />
                In the meantime, we have sent you an email outlining further information regarding your submission.
            </p>
        </div>
        <div class="right">
            <img class="success" src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
        </div>
    </div>

    <div class="formContainer" *ngIf="!responseState.success">
        <span>{{fd.description}}</span>
        <p class="header-error errorMessageColor" *ngFor="let message of responseState.return_msg">
            {{ message }}
        </p>
        <form (ngSubmit)="onSubmit()" [formGroup]="winningPitchForm">
            <div class="formItemsContainer">
                <div class="inputContainer">
                    <input type="{{fd.input1Attributes.type}}" class="{{fd.input1Attributes.className}}"
                        placeholder="{{fd.input1Attributes.placeholder}}" formControlName="fullnameControl">
                    <div *ngIf="submitted && f['fullnameControl'].errors" class="invalid-feedback">
                        <div *ngIf="f['fullnameControl'].errors['required']">Name is required
                        </div>
                        <div *ngIf="f['fullnameControl'].errors['minlength']">
                            A minimum of
                            {{ f['fullnameControl'].errors["minlength"].requiredLength }}
                            characters length is
                            required
                        </div>
                        <div *ngIf="f['fullnameControl'].errors['maxlength']">
                            A maximum of
                            {{ f['fullnameControl'].errors["maxlength"].requiredLength }}
                            characters length is
                            required
                        </div>
                    </div>
                </div>

                <div class="inputContainer">
                    <input type="{{fd.input2Attributes.type}}" class="{{fd.input2Attributes.className}}"
                        placeholder="{{fd.input2Attributes.placeholder}}"
                        formControlName="{{fd.input2Attributes.className}}Control">
                    <div *ngIf="submitted && f['emailControl'].errors" class="invalid-feedback">
                        <div *ngIf="f['emailControl'].errors['required']">Email is required</div>
                        <div *ngIf="f['emailControl'].errors['email']">Please write a valid email</div>
                        <div *ngIf="f['emailControl'].errors['maxlength']">
                            A maximum of
                            {{ f["emailControl"].errors["maxlength"].requiredLength }} characters length is
                            required
                        </div>
                    </div>
                </div>

                <div class="inputContainer">
                    <input type="{{fd.input3Attributes.type}}" class="{{fd.input3Attributes.className}}"
                        placeholder="{{fd.input3Attributes.placeholder}}"
                        formControlName="{{fd.input3Attributes.className}}Control">
                    <div *ngIf="submitted && f['industryControl'].errors" class="invalid-feedback">
                        <div *ngIf="f['industryControl'].errors['required']">Industry is required
                        </div>
                        <div *ngIf="f['industryControl'].errors['minlength']">
                            A minimum of
                            {{ f['industryControl'].errors["minlength"].requiredLength }}
                            characters length is
                            required
                        </div>
                        <div *ngIf="f['industryControl'].errors['maxlength']">
                            A maximum of
                            {{ f['industryControl'].errors["maxlength"].requiredLength }}
                            characters length is
                            required
                        </div>
                    </div>
                </div>

                <div class="inputContainer">
                    <mat-form-field class="{{fd.multiSelectAttributes.className}}">
                        <mat-label>{{fd.multiSelectAttributes.placeholder}}</mat-label>
                        <mat-select formControlName="topicsControl" multiple>
                            @for (value of valuesList; track value) {
                            <mat-option
                                [disabled]="f['topicsControl'].value?.length! >= 2 && !f['topicsControl'].value?.includes(value)"
                                [value]="value">{{value}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submitted && f['topicsControl'].errors" class="invalid-feedback">
                        <div *ngIf="f['topicsControl'].errors['required']">Topic is required</div>
                    </div>
                </div>
                <div class="inputContainer">
                    <textarea class="textarea" placeholder="{{fd.textareaPlaceholder}}"
                        formControlName="descriptionControl"></textarea>
                    <div *ngIf="submitted && f['descriptionControl'].errors" class="invalid-feedback">
                        <div *ngIf="f['descriptionControl'].errors['required']">Startup idea is required</div>
                        <div *ngIf="f['descriptionControl'].errors['minlength']">
                            A minimum of
                            {{ f["descriptionControl"].errors["minlength"].requiredLength }} characters length
                            is required
                        </div>
                        <div *ngIf="f['descriptionControl'].errors['maxlength']">
                            A maximum of
                            {{ f["descriptionControl"].errors["maxlength"].requiredLength }} characters length
                            is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttonContainer">
                <button class="primaryBtn main-btn" type="submit">{{fd.primaryButtonText}}</button>
                <button class="secondaryBtn" type="button" (click)="closeDialog()">{{fd.secondaryButtonText}}</button>
            </div>
        </form>
    </div>
</div>