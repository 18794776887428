<div class="content">
    <h1>{{content.title}}</h1>
    <img class="speaker" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2>{{content.description}}</h2>
    <div class="buttonContainer">
        <div class="btn" *ngFor="let img of content.surveyAnswer" (click)="imageClick(img)">
            <img [src]="buttonClicked && selectedItem.answers[0].answerId === img.id 
                ? 'https://cdn-images.island-conference.com/questionnaire/' + img.imageUrl[1] 
                : 'https://cdn-images.island-conference.com/questionnaire/' + img.imageUrl[0]" />

        </div>

    </div>
    <i-con-ctabuttons [shouldJustGoNext]="false" [nextEnabled]="selectedItem !== undefined"
        (nextClick)="nextClick()"></i-con-ctabuttons>
</div>