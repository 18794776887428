<div *ngIf="ticket" class="ticket" [ngClass]="{ 'centerpos': ticket.id === 3}">
  <div class="soldout_label" *ngIf="!ticket.inStock">Sold Out</div>
  <div class="ticket-wrap">
    <h2 class="c{{ color }}">{{ ticket.description }}</h2>

    <div class="prices" [ngClass]="{ doorActive: ticket.onTheDoor !== null, npactive: !checkEarlyActive(ticket.discounts[0].endsOn) === false }">
      <div class="earlyBird" *ngIf="!checkEarlyActive(ticket.discounts[0].endsOn)">
        <div class="imgContainer">
          <img src="https://cdn-images.island-conference.com/attend/tickets/early-bird-{{ color }}.svg" alt="" />
          <span class="c{{ color }}">EARLY BIRD</span>
        </div>
        <span class="price">{{ Math.round(ticket.price * (1 - ticket.discounts[0].percentage) * 100) / 100 | currency : 'EUR' : 'symbol' : '1.0-0' }}</span>
      </div>
      <div class="vl"></div>
      <div class="normal">
        <span class="c{{ color }}">NORMAL PRICE</span>
        <span class="price">{{ ticket.price | currency : 'EUR' : 'symbol' : '1.0-0' }}</span>
      </div>
      <ng-container *ngIf="ticket.onTheDoor !== null">
        <div class="vl"></div>
        <div class="ondoor">

          <span class="c{{ color }}">ON THE DOOR</span>
          <span class="price">{{ ticket.onTheDoor | currency : 'EUR' : 'symbol' : '1.0-0' }}</span>
        </div>
      </ng-container>
    </div>

    <div ngClass="c{{ color }}" class="hor-line"></div>

    <div class="includes-icons">
      <ng-container *ngIf="ticket.id === 2">
        <div><img src="https://cdn-images.island-conference.com/attend/tickets/ajaxhotel-logo.png"/></div>
      </ng-container>
    </div>

    <!-- <div ngClass="c{{ color }}" class="availableUntil">
      <span>Available until {{ formatDate(ticket.discounts[0].endsOn) }}</span>
    </div> -->

    <div class="perksContainer">
      <div *ngFor="let benefit of ticket.includes; let i = index" class="perks">
        <div class="circleAndText" *ngIf="ticket.id === 1 || ticket.id === 3">
          <div class="externalCircle">
            <div [ngClass]="{ unavailable: ticket.id === 1 && i > 5 }" class="innerCircle c{{ color }}"></div>
          </div>
          <span [ngClass]="{ unavailable: ticket.id === 1 && i > 5 }">{{ benefit.content }}</span>
        </div>
        <div class="circleAndText" *ngIf="ticket.id === 2">
          <div class="externalCircle">
            <div [ngClass]="{ unavailable: ticket.id === 2 && i > 6 }" class="innerCircle c{{ color }}"></div>
          </div>
          <span [ngClass]="{ unavailable: ticket.id === 2 && i > 6 }">{{ benefit.content }}</span>
        </div>
        <hr />
      </div>
    </div>
  </div>
  <button class="main-btn" ngClass="c{{ color }}" (click)="purchaseTicketClick(ticket)" [disabled]="!ticket.inStock">Purchase Ticket</button>
</div>
