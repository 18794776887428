import { Component, Input, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss'],
})
export class VisionComponent {
  @Input() class_name: string = 'vision_page';
  @Input() phrase: string = 'Creating the';
  @Input() title1: string = 'AFFILIATE CONFERENCE OF THE FUTURE';
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ViewChild('slickModalNexxie') slickModalNexxie: SlickCarouselComponent;
  codSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplaySpeed: 2000,
    autoplay: true,
    dots: false,
    infinite: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  nexxieSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplaySpeed: 2000,
    autoplay: true,
    dots: false,
    infinite: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  constructor() {}
}
