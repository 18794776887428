import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepService } from '../../service/step.service';
import { UserAnswersService } from '../../service/userAnswers.service';

@Component({
  selector: 'i-con-ctabuttons',
  templateUrl: './CTAButtons.component.html',
  styleUrl: './CTAButtons.component.scss',
})
export class CTAButtonsComponent implements OnInit {
  constructor(private stepService: StepService, private userAnswersService: UserAnswersService) {}

  @Input() nextText: string = 'Next';
  @Input() backText: string = 'Back';
  @Input() hideBack: boolean = false;
  @Input() nextEnabled: boolean = true;
  @Input() shouldJustGoNext: boolean = true;
  @Output() nextClick: EventEmitter<number | undefined> = new EventEmitter<number | undefined>();

  ngOnInit(): void {
    this.userAnswersService.scrollToTop();
  }

  nextButtonClick() {
    if (!this.nextEnabled) return;
    this.nextClick.emit();
    if (this.shouldJustGoNext) this.stepService.goNext();
  }

  backClick() {
    this.userAnswersService.removeLastAnswer();
    this.stepService.goPrevious();
  }
}
