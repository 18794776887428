import { Component, Inject } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { UserAnswersService } from '../../service/userAnswers.service';
import { HOW_HAPPY_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-howhappy',
  templateUrl: './howhappy.component.html',
  styleUrls: ['./howhappy.component.scss'],
})
export class HowHappyComponent {
  constructor(@Inject(HOW_HAPPY_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService) {}

  selectedItem: IQuestionnaireUserAnswers;
  buttonClicked: boolean = false;

  imageClick(item: ISurveyAnswer) {
    this.buttonClicked = true;
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: item.id,
          value: [item.value],
        },
      ],
    };
  }

  nextClick() {
    if (this.selectedItem) {
      this.userAnswersService.pushNewAnswer(this.selectedItem);
    }
  }
}
