import { Component, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { TripReservationComponent } from '../trip-reservation/trip-reservation.component';

@Component({
  selector: 'app-mykonos',
  templateUrl: './mykonos.component.html',
  styleUrls: ['./mykonos.component.scss'],
})
export class MykonosComponent {
  @Input() homepage: string = 'miconos';

  constructor(public dialog: MatDialog) {}

  openTripDialog() {
    event?.preventDefault();
    const dialogRef = this.dialog.open(TripReservationComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      width: '950px',
      autoFocus: false,
      panelClass: 'paddless',
    });
  }
}
