import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEventReservationTypeResponse } from './../../_models/responses/event-reservation-type-response.interface';
import { EnvironmentService, StateService } from '@i-con/shared/common';

interface IEventReservationState {
	postData: IEventReservationTypeResponse;
}

const initialStateValue: any = {
	postData: {},
};

@Injectable({ providedIn: 'root' })
export class EventReservationService extends StateService<IEventReservationState> {
	postData$: Observable<IEventReservationTypeResponse> = this.getState((state) => state.postData);

	constructor(private http: HttpClient,
        private _environment: EnvironmentService) {
		super(initialStateValue);
	}

	eventReservation(eventReservationInfo: object) {
		this.http
        .post<IEventReservationTypeResponse>(`${this._environment.getApiUrl}/event-reservation`, eventReservationInfo)
        .subscribe({
            error: (error: any) => {
                this.setState({ postData: error.error });
            },
            next: (response: any) => {
                this.setState({ postData: response });
            },
        });
	}
}
