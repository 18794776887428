import { InjectionToken } from '@angular/core';
import { IQuestionnaire } from '../interface/questionnaire.interface';

export const HOW_HAPPY_DATA = new InjectionToken<IQuestionnaire>('HowHappyData');
export const FIND_OUT_DATA = new InjectionToken<IQuestionnaire>('FindOutData');
export const RATE_FEATURES_DATA = new InjectionToken<IQuestionnaire>('RateFeaturesData');
export const ATTEND_PRESENTATIONS_DATA = new InjectionToken<IQuestionnaire>('AttendPresentationsData');
export const SPEAKERS_DATA = new InjectionToken<IQuestionnaire>('SpeakersData');
export const NOT_ATTEND_DATA = new InjectionToken<IQuestionnaire>('NotAttendData');
export const HAZE_EVENTS_DATA = new InjectionToken<IQuestionnaire>('HazeEventsData');
export const GOOD_TIME_DATA = new InjectionToken<IQuestionnaire>('GoodTimeData');
export const EVENTS_LIKE_DATA = new InjectionToken<IQuestionnaire>('EventsLikeData');
export const EVENTS_SATISFIED_DATA = new InjectionToken<IQuestionnaire>('EventsSatisfiedData');
export const IMPROVEMENTS_DATA = new InjectionToken<IQuestionnaire>('ImprovementsData');
export const I_CON_2025_DATA = new InjectionToken<IQuestionnaire>('ICon2025Data');
export const SEE_YOU_DATA = new InjectionToken<IQuestionnaire>('SeeYouData');
