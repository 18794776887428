<div class="content" *ngIf="data.type === 1">
    
    <img  class="mainImg" src="{{data.gallery.images[data.index].src.replace('thumbnails', 'hd').replace('png', 'jpg')}}"
        alt="">
    
    <button class="close-btn" mat-button mat-dialog-close (click)="closeDialog()">
        <img src="https://cdn-images.island-conference.com/icons/close-icon.svg" alt="Close">
    </button>
    <button class="back" (click)="backClick()"></button>
    <button class="next" (click)="nextClick()"></button>    
</div>

<div class="content afterMovie" *ngIf="data.type === 2 && data.aftermovie">
    <iframe class="video" [src]="data.safeUrl" frameborder="0" allowfullscreen></iframe>
</div>