import { Component, OnInit } from '@angular/core';
import { activityData } from './data/activity-data';

@Component({
  selector: 'i-con.app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit {

	public activityData: any = activityData;

  constructor() { }

  ngOnInit(): void {
  }
}
