import { Component, Inject } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { UserAnswersService } from '../../service/userAnswers.service';
import { GOOD_TIME_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-good-time',
  templateUrl: './good-time.component.html',
  styleUrl: './good-time.component.scss',
})
export class GoodTimeComponent {
  constructor(@Inject(GOOD_TIME_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService) {}

  selectedItem: IQuestionnaireUserAnswers;
  buttonClicked: boolean = false;

  imageClick(item: ISurveyAnswer) {
    this.buttonClicked = true;
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: item.id,
          value: [item.value],
        },
      ],
    };
  }

  nextClick() {
    if (this.selectedItem) {
      this.userAnswersService.pushNewAnswer(this.selectedItem);
    }
  }
}
