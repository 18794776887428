<div class="attend">
	<div class="header-wrapper">
		<div class="header">
			<div class="container">
				<h1 class="pageheader"><span>Our booths are</span>OUT OF THIS WORLD</h1>
			</div>
		</div>
	</div>
	<div class="exhibit section">
		<div class="container flexr">
			<div class="left">
				<div>
					<!-- <img src="https://cdn-images.island-conference.com/attend/attend-exhibit.jpg"/> -->
				</div>
			</div>
			<div class="right">
				<h2><span>CONFERENCE</span>EXHIBIT</h2>
				<h3>
					Secure one of our exclusive booths to reserve a dedicated space for your brand within the conference area!
				</h3>
				<p>
					When it comes to drawing other collaborations to your business's core, there's nothing quite like the magnetic pull of our booths. Our networking opportunities are unmatched and our offers are simply second to none, so you get to decide how large you want your gravitational pull to be and become one of the biggest stars of our galaxy!
				</p>
				<app-nav-button class="main-btn" [type]="'internal'" [new_path]="['/attend/booths']" [text]="'View Booths'"></app-nav-button>
			</div>
		</div>
	</div>
	<div class="booths section" *ngIf="boothData$ | async as booths">
		<div class="container" *ngIf="booths.length > 0">
			<div class="booths-header">
				<h3>Our Booths Are</h3>
				<h2>OUT OF THIS WORLD</h2>
				<div class="booths_dots"><img src="https://cdn-images.island-conference.com/attend/horizontal_dots.svg" alt="Rectangle shape made up of light blue dots"/></div>
			</div>
			<div class="booths-listing flexr">
				<div class="single-booth" id="{{ bdata.name }}" *ngFor="let bdata of booths; let i = index">
					<div class="single-booth-mobile-row">
						<div>
							<h3>{{ bdata.name }}</h3>
							<p>
								Floor Size <span>{{ bdata.floorSize }}&#13217;</span>
							</p>
						</div>
						<div class="booth-model">
							<img src="https://cdn-images.island-conference.com/attend/booths/{{ bdata.id }}/{{ bdata.modelUrl }}" />
							<div class="stock_ribbon flexr" [ngClass]="{ showElem: !bdata.inStock }">Sold Out</div>
						</div>
					</div>
					<app-nav-button class="main-btn" [disabled]="!bdata.inStock"  [routerLink]="['/attend/booths']" queryparams="booth:'bdata.name'"
						[text]="'View More'" [type]="'internal'"></app-nav-button>

					<p>Floor Size <span>{{ bdata.floorSize }}&#13217;</span></p>
				</div>
			</div>
		</div>
	</div>
	<div class="sponsorships section">
		<div class="container flexr">
			<div class="left">
				<h2><span>AFFILIATE</span>SPONSORSHIPS</h2>
				<h3>Take your brand awareness to new heights!</h3>
				<p>
					Looking for your company to take center stage at i-Con? Whether it's boosting online or in-person brand awareness, our sponsorships are your solution!
					<br/><br/>Give your product the spotlight it deserves with one of our sponsorships, which are designed to fit any requirement and budget. Take advantage of one of our sponsorship opportunities and make a Big Bang in i-Con's universe. <br/><br/>Making affiliates, networks, and traffic sources orbit around your brand has never been easier!
				</p>
				<app-nav-button class="main-btn" [type]="'internal'" [new_path]="['/attend/sponsorships']"
					[text]="'Available Sponsorships'" [target]="'_self'"></app-nav-button>
			</div>
			<div class="right">
				<div>
					<!-- <img src="https://cdn-images.island-conference.com/attend/sponsorships.gif" alt="Gif of purple speaker on top of green background emitting sound."/> -->
				</div>
			</div>
		</div>
	</div>
	<div class="individual-tickets section">
		<!-- *ngIf="(ticketData$) | async as tickets" -->
		<div class="container">
			<div class="upper-row flexr">
				<div class="left">
					<h2><span>Individual</span>Conference Tickets</h2>
					<h3>Your ticket is the key to experiencing all that i-Con has to offer!</h3>
					<app-nav-button class="main-btn" [class]="'buy-tickets'" [type]="'internal'" [new_path]="['/attend/tickets']"
						[text]="'Buy Tickets'" [target]="'_self'"></app-nav-button>
				</div>
				<div class="right"> <!-- *ngIf="tickets.length > 0" -->
					<p>
						Be sure to secure your tickets well in advance of the event's start time, and take advantage of our 'Early Bird' promotion!
						<br/><br/>Our tickets cover both days of the event and provide access to:
						<br/><br/>- Scheduled Speeches and Sessions
						<br/>- Exclusive conference networking app
						<br/>- Sponsored Food & Beverage
						<br/>- All of I-Con's Events and Parties						
					</p>
					<!-- <div>
						<p class="securion flexr">Powered by 
							<span>
								<img src="https://cdn-images.island-conference.com/exhibitors/logos/shift4-logo-white.svg" alt="Company logo of Shift4." />
							</span>
						</p>
					</div> -->
					
				</div>
			</div>
			<div class="bottom-row" >
				<!-- *ngIf="(bundleData$ | async) as bundles" -->
				<app-attend-tickets></app-attend-tickets>
			</div>
		</div>
	</div>
	<div class="winning-pitch">
		<winning-pitch></winning-pitch>
	</div>
	<div class="exhibitors">
		<app-exhibitors></app-exhibitors>
	</div>
</div>