import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BookingFormGroupComponent } from '@i-con/shared/ui';
import { SharedModule } from '../../shared/shared.module';
import { FanSitesCardComponent } from '../meetups-fan-sites/fan-sites-card/fan-sites-card.component';
import { MeetupsFanSitesComponent } from '../meetups-fan-sites/fan-sites.component';
import { ReserveTableComponent } from '../meetups-fan-sites/reserve-table/reserve-table.component';
import { MeetupsWorkshopsCardComponent } from '../meetups-workshops/meetups-workshops-card/meetups-workshops-card.component';
import { MeetupsWorkshopsComponent } from '../meetups-workshops/pages/meetups-workshops/meetups-workshops.component';
import { SeoWorkshopsComponent } from '../seo-workshops/seo-workshops.component';
import { MeetupsRoutingModule } from './meetups-routing.module';
import { MeetupsComponent } from './meetups.component';

@NgModule({
  declarations: [
    MeetupsComponent,
    MeetupsWorkshopsComponent,
    MeetupsWorkshopsCardComponent,
    MeetupsFanSitesComponent,
    FanSitesCardComponent,
    ReserveTableComponent,
    SeoWorkshopsComponent,
  ],
  imports: [CommonModule, MeetupsRoutingModule, SharedModule, BookingFormGroupComponent],
})
export class MeetupsModule {}
