<div class="container" *ngIf="tabValid">
    <div class="tabs">
        <div class="heading">
            <h1>i-Con 2023 RECAP</h1>
            <p>
                Connect with thousands of advertisers, publishers, affiliates, and more, during two days filled with networking opportunities. 
                Attend speeches and panels by the industry's key people and brightest minds, as they discuss the latest trends and topics. 
                Experience thrilling business events and lively parties! 
                And the best part? You can do all of this under the warm sun, by the beautiful sea, 
                and on the sandy shores of Europe's largest casino resort, located on the beautiful island of Cyprus! 
                Subscribe to our <a class="youtube" href="https://www.youtube.com/@Island-Conference" target="_blank">YouTube channel</a> now to watch last year's highlights and stay up-to-date with our latest news 
                and announcements about i-Con 2.0!
            </p>
        </div>
        <mat-tab-group dynamicHeight [(selectedIndex)]="tabIndex" (selectedTabChange)="tabChange()">            
            <mat-tab>
                <ng-template mat-tab-label>
                    <img src="https://cdn-images.island-conference.com/vision/recap/albums/Icon metro-images.svg">
                    <span>Photo Albums</span>
                </ng-template>

                <hr>
                <div class="mainContentContainer">

                    <div id="day-0" class="day0Container mainContent">
                        <vision-recap-album-card-section [content]="contentData[0]"></vision-recap-album-card-section>
                        <div class="day0">
                            <vision-recap-album-card class="card" *ngFor="let card of contentData[0].cards"
                                [card]="card"></vision-recap-album-card>
                        </div>
                    </div>

                    <div id="day-1" class="day1Container mainContent">
                        <vision-recap-album-card-section [content]="contentData[1]"></vision-recap-album-card-section>
                        <div class="day1">
                            <vision-recap-album-card class="card" *ngFor="let card of contentData[1].cards"
                                [card]="card"></vision-recap-album-card>
                        </div>
                    </div>

                    <div id="day-2" class="day2Container mainContent">
                        <vision-recap-album-card-section [content]="contentData[2]"></vision-recap-album-card-section>
                        <div class="day2">
                            <vision-recap-album-card class="card" *ngFor="let card of contentData[2].cards"
                                [card]="card"></vision-recap-album-card>
                        </div>
                    </div>
 
                    <div id="miconos" class="mykonosContainer mainContent">
                        <vision-recap-album-card-section [content]="contentData[3]"></vision-recap-album-card-section>
                        <div class="mykonos">
                            <vision-recap-album-card class="card" *ngFor="let card of contentData[3].cards"
                                [card]="card"></vision-recap-album-card>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label> 
                    <img
                        src="https://cdn-images.island-conference.com/vision/recap/albums/Icon material-ondemand-video.svg">
                    <span>Aftermovies</span>
                </ng-template>

                <hr>
                
                <div class="aftermovies-section">
                    <div class="aftermovies-container">
                        <div class="aftermovie-container official">
                            <div class="header">                                
                                <h2>OFFICIAL AFTERMOVIE</h2>

                                <a href="https://www.youtube.com/@Island-Conference" class="youtube-btn" target="_blank">
                                    <img class="youtube-icon" src="https://cdn-images.island-conference.com/vision/recap/youtube-icon.svg">
                                    SUBSCRIBE TO OUR CHANNEL
                                </a>
                            </div>

                            <div class="thumbnail" (click)="openImage(0, 2)">
                                <div class="overlay"></div>
                                <img class="play-btn" src="https://cdn-images.island-conference.com/vision/recap/play-icon-white.svg" >
                                <img class="thumbnail-photo" src="{{afterMovieData[0].thumb}}">
                            </div>

                            <h3>{{afterMovieData[0].title}}</h3>
                            <p>{{afterMovieData[0].description}}</p>
                        </div>

                        <div class="aftermovie-container mykonos">
                            <img class="mykonos-logo" src="https://cdn-images.island-conference.com/vision/recap/mykonos-logo-black.svg">

                            <div class="thumbnail" (click)="openImage(1, 2)">
                                <div class="overlay"></div>
                                <img class="play-btn" src="https://cdn-images.island-conference.com/vision/recap/play-icon-white.svg" >
                                <img class="thumbnail-photo" src="{{afterMovieData[1].thumb}}">
                            </div>

                            <h3>{{afterMovieData[1].title}}</h3>
                            <p>{{afterMovieData[1].description}}</p>
                        </div>

                        <div class="aftermovie-container events">
                            <div class="header">
                                <h2>i-CONIC EVENTS BY</h2>
                                <img class="haze-logo" src="https://cdn-images.island-conference.com/vision/haze_logo_black.svg">
                            </div>
                            <div class="videos">
                                <div class="video-container first">
                                    <div class="thumbnail" (click)="openImage(2, 2)">
                                        <div class="overlay"></div>
                                        <img class="play-btn" src="https://cdn-images.island-conference.com/vision/recap/play-icon-white.svg" >
                                        <img class="thumbnail-photo" src="{{afterMovieData[2].thumb}}">
                                    </div>
        
                                    <h3>{{afterMovieData[2].title}}</h3>
                                    <p>{{afterMovieData[2].description}}</p>
                                </div>
                                <div class="video-container second">
                                    <div class="thumbnail" (click)="openImage(3, 2)">
                                        <div class="overlay"></div>
                                        <img class="play-btn" src="https://cdn-images.island-conference.com/vision/recap/play-icon-white.svg" >
                                        <img class="thumbnail-photo" src="{{afterMovieData[3].thumb}}">
                                    </div>
        
                                    <h3>{{afterMovieData[3].title}}</h3>
                                    <p>{{afterMovieData[3].description}}</p>
                                </div>
                                <div class="video-container third">
                                    <div class="thumbnail" (click)="openImage(4, 2)">
                                        <div class="overlay"></div>
                                        <img class="play-btn" src="https://cdn-images.island-conference.com/vision/recap/play-icon-white.svg" >
                                        <img class="thumbnail-photo" src="{{afterMovieData[4].thumb}}">
                                    </div>
        
                                    <h3>{{afterMovieData[4].title}}</h3>
                                    <p>{{afterMovieData[4].description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                </div>


                <!-- <div class="test">
                    {{afterMovieData[0].title}}
                </div>
                <p>
                    {{afterMovieData[0].type}} dsjhdsjhf
                </p> -->
                <!-- <div class="videos mainContentContainer">
                    <a class="youtube-btn" href="https://www.youtube.com/@Island-Conference" target="_blank">View Youtube Channel</a>
                    <div class="aftermovieContainer mainContent">
                        <vision-recap-album-card-section [content]="videosData[0]"></vision-recap-album-card-section>
                        <div class="aftermovie">
                            <iframe class="video" [src]="safeUrls[0]" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="mykonosContainer mainContent">
                        <vision-recap-album-card-section [content]="videosData[1]"></vision-recap-album-card-section>
                        <div class="mykonosVideo">
                            <iframe class="video" [src]="safeUrls[1]" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div> -->
            </mat-tab>
        </mat-tab-group>

    </div>
</div>

<app-not-found *ngIf="!tabValid"></app-not-found>