import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@i-con/shared/common';
import { Observable } from 'rxjs/internal/Observable';
import { IQuestionnaireFinalResponse, IQuestionnaireFinalResponseFromAPI, IQuestionnaireResponse } from '../interface/questionnaire.interface';

@Injectable({ providedIn: 'root' })
export class QuestionnaireService {
  private encodedEmailAddress: string = '';
  constructor(private _environment: EnvironmentService, private http: HttpClient) {}

  getQuestionnaire(): Observable<IQuestionnaireResponse> {
    return this.http.get<IQuestionnaireResponse>(`${this._environment.getApiUrl}/survey/1/attendee/email/${this.encodedEmailAddress}`);
  }

  postUserAnswers(attendeeResponse: IQuestionnaireFinalResponse) {
    return this.http.post<IQuestionnaireFinalResponseFromAPI>(
      `${this._environment.getApiUrl}/survey/1/attendee/email/${this.encodedEmailAddress}`,
      attendeeResponse
    );
  }

  setEncodedEmailAddress(email: string) {
    this.encodedEmailAddress = email;
  }

  getEncodedEmailAddress(): string {
    return this.encodedEmailAddress;
  }
}
