import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private subject = new BehaviorSubject<number>(0);
  questionId$: Observable<number> = this.subject.asObservable();

  private questionIds: number[] = [0];
  private questionIdsSubject = new BehaviorSubject<number[]>(this.questionIds);
  questionIds$: Observable<number[]> = this.questionIdsSubject.asObservable();

  goNext() {
    const nextQuestion = this.subject.value + 1;
    this.questionIds.push(nextQuestion);
    this.subject.next(nextQuestion);
    this.questionIdsSubject.next(this.questionIds);
  }

  goPrevious() {
    if (this.questionIds.length > 1) {
      this.questionIds.pop();
      const previousQuestion = this.questionIds[this.questionIds.length - 1];
      this.subject.next(previousQuestion);
      this.questionIdsSubject.next(this.questionIds);
    }
  }

  getActiveQuestion(): number {
    return this.subject.value;
  }

  goToQuestionWithId(index: number) {
    this.questionIds.push(index);
    this.subject.next(index);
    this.questionIdsSubject.next(this.questionIds);
  }
}
