import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IWinningPitchTypeResponse } from '../../../_models/responses/winning-pitch-type-response.interface';
import { EnvironmentService, StateService } from '@i-con/shared/common';

interface IWinningPitchState {
  postData: IWinningPitchTypeResponse;
}

const initialStateValue: any = {
  postData: {},
};

@Injectable({ providedIn: 'root' })
export class WinningPitchService extends StateService<IWinningPitchState> {
  postData$: Observable<IWinningPitchTypeResponse> = this.getState(
    (state) => state.postData
  );

  constructor(
    private http: HttpClient,
    private _environment: EnvironmentService
  ) {
    super(initialStateValue);
  }

  winningPitchService(info: object) {
    this.http
      .post<IWinningPitchTypeResponse>(
        `${this._environment.getApiUrl}/winning-pitch`,
        info
      )
      .subscribe({
        error: (error: any) => {
          this.setState({ postData: error.error });
        },
        next: (resposnse: any) => {
          this.setState({ postData: resposnse });
        },
      });
  }
}
