<div class="form-container" *ngIf="responseState$ | async as responseState">
	<div class="top">
		<button class="close-btn" mat-button mat-dialog-close>
			<img class="close-icon" src="https://cdn-images.island-conference.com/icons/close-icon-white.svg" alt="Close">
		</button>
		<div class="center">
			<img class="mykonos-logo" src="https://cdn-images.island-conference.com/mykonos/meetup.svg"
				alt="Miconos logo including meetup logo below. Logos are in white text" />
		</div>
	</div>
	<div class="bottom">
		<div class="row header">
			<h3>Mykonos Trip | VIP List</h3>
		</div>
		<div class="success-msg flexr" *ngIf="responseState.success">
			<div class="left">
				<h2>Thank you for your interest in joining the Mykonos Trip!</h2>
				<p>
					Please be aware that there are specific requirements that must be met in order to be added to the
					VIP List. Our team will examine your inquiry and provide confirmation within 2 business days.
					<br />
				</p>
			</div>
			<div class="right">
				<img class="success" src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
			</div>
		</div>
		<div class="body" *ngIf="!responseState.success">
			<div class="row info">
				<p class="text-center">Fill in your info</p>
			</div>
			<p class="header-error" *ngFor="let message of responseState.return_msg">
				{{ message }}
			</p>
			<form class="form" [formGroup]="reserveTripFrom" (ngSubmit)="onSubmit()">
				<div class="row">
					<div class="name-field field-container">
						<input class="name-field" type="text" placeholder="Name" formControlName="nameControl" />
						<div *ngIf="submitted && f['nameControl'].errors" class="invalid-feedback">
							<div *ngIf="f['nameControl'].errors['required']">Name is required</div>
							<div *ngIf="f['nameControl'].errors['minlength']">
								A minimum of {{ f["nameControl"].errors["minlength"].requiredLength }} characters length
								is required
							</div>
							<div *ngIf="f['nameControl'].errors['maxlength']">
								A maximum of {{ f["nameControl"].errors["maxlength"].requiredLength }} characters length
								is required
							</div>
						</div>
					</div>
					<div class="surname-field field-container">
						<input class="name-field" type="text" placeholder="Surname" formControlName="surnameControl" />
						<div *ngIf="submitted && f['surnameControl'].errors" class="invalid-feedback">
							<div *ngIf="f['surnameControl'].errors['required']">Surame is required</div>
							<div *ngIf="f['surnameControl'].errors['minlength']">
								A minimum of {{ f["surnameControl"].errors["minlength"].requiredLength }} characters
								length is required
							</div>
							<div *ngIf="f['surnameControl'].errors['maxlength']">
								A maximum of {{ f["surnameControl"].errors["maxlength"].requiredLength }} characters
								length is required
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="phone-field field-container">
						<ngx-intl-tel-input [cssClass]="'phone-input'" [enablePlaceholder]="true"
							[searchCountryFlag]="true" [selectedCountryISO]="selected_countries.Cyprus"
							[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
							[enableAutoCountrySelect]="true" [maxLength]="15" [phoneValidation]="true"
							[separateDialCode]="true" name="phone" formControlName="phoneControl">
						</ngx-intl-tel-input>
						<div *ngIf="submitted && f['phoneControl'].errors" class="invalid-feedback">
							<div *ngIf="f['phoneControl'].errors['required']">Phone is required</div>
							<div *ngIf="f['phoneControl'].errors && !f['phoneControl'].errors['required']">
								Invalid Input
							</div>
						</div>
					</div>
					<div class="email-field field-container">
						<input class="email-field" type="text" placeholder="Email" formControlName="emailControl" />
						<div *ngIf="submitted && f['emailControl'].errors" class="invalid-feedback">
							<div *ngIf="
									f['emailControl'].errors['email'] &&
									f['emailControl'].errors &&
									!f['emailControl'].errors['minlength'] &&
									!f['emailControl'].errors['maxlength']
								">
								Please write a valid email
							</div>
							<div *ngIf="f['emailControl'].errors['required']">Email is required</div>
							<div *ngIf="f['emailControl'].errors['minlength']">
								A minimum of {{ f["emailControl"].errors["minlength"].requiredLength }} characters
								length is required
							</div>
							<div *ngIf="f['emailControl'].errors['maxlength']">
								A maximum of {{ f["emailControl"].errors["maxlength"].requiredLength }} characters
								length is required
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="company-field field-container">
						<input class="company-field" type="text" placeholder="Company"
							formControlName="companyControl" />
						<div *ngIf="submitted && f['companyControl'].errors" class="invalid-feedback">
							<div *ngIf="f['companyControl'].errors['required']">Company is required</div>
							<div *ngIf="f['companyControl'].errors['minlength']">
								A minimum of {{ f["companyControl"].errors["minlength"].requiredLength }} characters
								length is required
							</div>
							<div *ngIf="f['companyControl'].errors['maxlength']">
								A maximum of {{ f["companyControl"].errors["maxlength"].requiredLength }} characters
								length is required
							</div>
						</div>
					</div>
					<div class="position-field field-container">
						<input class="position-field" type="text" placeholder="Position"
							formControlName="positionControl" />
						<div *ngIf="submitted && f['positionControl'].errors" class="invalid-feedback">
							<div *ngIf="f['positionControl'].errors['required']">Position is required</div>
							<div *ngIf="f['positionControl'].errors['minlength']">
								A minimum of {{ f["positionControl"].errors["minlength"].requiredLength }} characters
								length is required
							</div>
							<div *ngIf="f['positionControl'].errors['maxlength']">
								A maximum of {{ f["positionControl"].errors["maxlength"].requiredLength }} characters
								length is required
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<button class="main-btn">Submit</button>
				</div>
			</form>
		</div>
	</div>
</div>