<div class="main-container">

    <div class="policy-header">
        <h1>PRIVACY POLICY</h1> 
    </div>

    <div class="policy-container">
        <h2>1. Introduction</h2>
        <p>
            Nexxie Group Ltd (here in after “Nexxie”, “we” or “us”), owner of the website 
            <a href='https://island-conference.com/' target="_blank">https://island-conference.com/</a> (the “Site”) and Island Conference (i-Con) (the “App”) 
            and organizer of the i-CON Island Conference, respects your privacy and is 
            committed to protecting your personal data.
        </p>
        <br>
        <h3>This privacy policy applies to:</h3>
        <p>
            • Your visit and use of the Site and the App;<br>
            • Any of the services provided by us through the Site and the App; and<br>
            • Aour attendance at the Event in accordance with the Attendee Terms and Conditions (“Terms and Conditions”) published on the Site and any services provided by us during the Event.
        </p>

        <p>Please read the following carefully to understand our practices regarding your personal data and how we will treat it.</p>
        <br>
        <h3>This privacy policy:</h3>
        <p>
            • Provides an overview of how we collect, process and protect your personal data;<br>
            • Informs you about your rights under the General Data Protection Regulation 2016/679 (GDPR) and any national law supplementing or implementing the GDPR, and<br>
            • Contains important information about what personal data we collect, what we do with such personal data, who we may share it with and your rights in relation to the personal data you have given us.
        </p>
        <br>
        <h3>For the purposes of this privacy policy:</h3>
        <p>
            • When we refer to “personal data” or “personal information” we mean data which identifies or may identify you and which may include, for example, your name, e-mail address and telephone number. It does not include data where your identity has been removed (anonymous data).<br>
            • When we refer to “processing” we mean the handling of your personal data by us, including collecting, safeguarding and storing your personal data.<br>
            • When we refer to “sensitive data” we mean special categories of personal data which identify or may identify you and which may reveal, among other things, details about your racial or ethnic origin, your political opinions, your religious or philosophical beliefs, your trade union memberships, your health, your sex life or your sexual orientation.<br>
            • When we refer to the “Event” we mean the the series of events collectively called i-CON Island Conference, which are to take place on the Event Dates at the Event Venue as such terms are described in the Terms and Conditions.<br>
        </p>

        <br>
        <h2>2. Who we are</h2>
        <p>
            Nexxie, the company which owns, operates and manages the Site, the App and the Event, is the controller and is therefore responsible for your personal data.
        </p>
        <p>
            If you have any questions or concerns regarding the collection and processing of your personal data by us or if you wish to exercise any of your rights (as set out in section 9 of this privacy policy), please do not hesitate to contact us using the details set out below and we will be happy to provide you with a response as soon as possible. 
        </p>
        <p>
            • Email address: <a href="mailto:info@islandconference.com">info&#64;islandconference.com</a><br>
            • Postal address: 23 Aristoteli Savva, Ficardos Centre, 8025 Paphos, Cyprus
        </p>
        <p>
            Nexxie takes the protection of your personal data very seriously and aims to handle any concern you may have in a timely and effective manner. If, despite our efforts, you are of the opinion that we have not dealt with your concerns adequately, you have the right to submit a complaint to the Office of the Commissioner for Data Protection. You can visit their website to find out how to submit a complaint (<a href='http://www.dataprotection.gov.cy' target='_blank' rel="nofollow" >http://www.dataprotection.gov.cy</a>).
        </p>

        <br>
        <h2>3. How we collect your personal data</h2>
        <p>
            We collect your personal data from various sources, as follows: 
        </p>
        <p>
            a) <span>Personal data collected directly from you.</span> This is information you submit to us directly by using the Site, the App or by attending the Event and/or by corresponding with us (for example, by email or phone). It includes information you provide when you:<br>
            • Use the Site or the App;<br>
            • Change any of your profile information on the App; <br>
            • Purchase a ticket for the Event either through the Site or by contacting us through e-mail or phone; <br>
            • Register at the Event to obtain your Event pass;<br>
            • Request to receive marketing information from us; and<br>
            • Report a problem to us in connection with the Site, the App or the Event or when you contact us for any other reason. If you contact us, we will keep a record of that correspondence. 
        </p>
        <p>
            b) <span>Automated technologies or interactions.</span>  Each time you access the Site and interact with it, we will automatically collect data in connection to your equipment, browsing actions and patterns.  We store this data by using cookies and other similar technologies. We may also collect data about you if you visit other sites employing tracking technologies. You can find out further details on how we use cookies in our cookie policy. 
        </p>
        <p>
            c) <span>Third parties.</span> We will receive personal data about you from various third parties as set out below: <br>
            • Event sponsors and/or exhibitors who may contact us to provide necessary information about you and your attendance at the Event in cases where you are attending the Event on their behalf;<br>
            • Attendees who may nominate you as a substitute to attend the Event in their place;<br>
            • Analytics providers (such as Google);<br>
            • Advertising networks;<br>
            • Payment service providers who assist us in facilitating your payments on the Site.
        </p>

        <br>
        <h2>4. The data we collect about you</h2>
        <p>
            We may collect and process the following personal data about you, depending on the type of connection you have with the Event, as described below: 
        </p>
        <div class="line"></div>
        <br>
        <h3>Attendees</h3>
        <p>
            • <span>Identity Data</span> including your full name and photograph (when you upload the latter on the App).<br>
            • <span>Contact Data</span> including your e-mail address and phone number.<br>
            • <span>Financial Data</span> including details of payments you have made to us and/or the payment service providers we work with (including the amount, date and time of payment, debit or credit card details as well as the relevant bank details used for the transaction) as well as details on the status of the transaction (i.e. whether or not it has been successful). <br>
            • <span>Purchase Data</span> meaning information in connection to your Ticket purchase(s) such as for example, the number of Tickets you have purchased. We also collect information in connection to any refund requests you have made in the past such as how many refund requests you have made and why.<br>
            • <span>Professional Data</span> including the company name of your employer, the industry in which it operates, your job position and your title.<br>
            • <span>Event Data</span> including any bookings you make in connection to your attendance to specific activities at the Event and any Event activities for which you express your interest through the App.<br>
            • <span>App Data</span> including any text, photographs or videos you upload or post on the App and details of how you use and/or interact with the App, including any reports you make in connection to the App or  reports made by others in connection to you and/or your use of the App.<br>
            • <span>Technical Data</span> including your internet protocol (IP) address, your browser type and version, details of your device, your time zone setting and location and your operating system and platform.<br>
            • <span>Usage Data</span> including information collected through cookies, such as the links you have clicked on (including date and time), services you viewed or searched for, page response times, download errors, what time you accessed the Site, length of visits to our webpages, page interaction information (such as scrolling and clicks) and methods used to browse away from our Site.<br>
            • <span>Marketing and Communications Data</span> including whether you wish to receive marketing communications from Nexxie and its affiliates. <br>
            • <span>Records</span> of any correspondence and/or communications we have with you, such us for example when you contact us in order to report an issue with your use of the Site, the App and/or your attendance at the Event. 
        </p>
        <div class="line"></div>
        <br>
        <h3>Exhibitors (where they relate to natural persons)</h3>
        <p>
            • <span>Identity Data</span> including your full name.<br>
            • <span>Contact Data</span> including your e-mail address, skype ID and phone number.<br>
            • <span>Financial Data</span> including details of payments you have made to us and/or the payment service providers we work with (including the amount, date and time of payment, debit or credit card details as well as the relevant bank details used for the transaction) as well as details on the status of the transaction (i.e. whether or not it has been successful).<br>
            • <span>Purchase Data</span> meaning information in connection to the type of booth you wish to exhibit at the Event and/or any requests you make to us in connection to your booth. We also collect information in connection to any refund requests you have made in the past such as how many refund requests you have made and why.<br>
            • <span>Technical Data</span> including your internet protocol (IP) address, your browser type and version, details of your device, your time zone setting and location and your operating system and platform.<br>
            • <span>Usage Data</span> including information collected through cookies, such as the links you have clicked on (including date and time), services you viewed or searched for, page response times, download errors, what time you accessed the Site, length of visits to our webpages, page interaction information (such as scrolling and clicks) and methods used to browse away from our Site.<br>
            • <span>Marketing and Communications Data</span> including whether you wish to receive marketing communications from Nexxie and its affiliates). <br>
            • <span>Records</span>of any correspondence and/or communications we have with you, such us for example when you contact us in connection to the specific logistics of setting up your booth.
        </p>
        <p>
            Where the exhibitor of a booth is a company and not an individual, we will ask for the Identity Data and Contact Data of any employees and/or other individuals who are authorised by the exhibitor to be present at the exhibitor’s booth during the Event.
        </p>
        <div class="line"></div>
        <br>
        <h3>Sponsors (where they relate to natural persons)</h3>
        <p>
            • <span>Identity Data</span> including your full name.<br>
            • <span>Contact Data</span> including your e-mail address, skype ID and phone number.<br>
            • <span>Financial Data</span> including details of payments you have made to us and/or the payment service providers we work with (including the amount, date and time of payment, debit or credit card details as well as the relevant bank details used for the transaction) as well as details on the status of the transaction (i.e. whether or not it has been successful).<br>
            • <span>Purchase Data</span> meaning information in connection to the type of sponsorship you have purchased. We also collect information in connection to any refund requests you have made in the past such as how many refund requests you have made and why.<br>
            • <span>Technical Data</span>including your internet protocol (IP) address, your browser type and version, details of your device, your time zone setting and location and your operating system and platform.<br>
            • <span>Usage Data</span> including information collected through cookies, such as the links you have clicked on (including date and time), services you viewed or searched for, page response times, download errors, what time you accessed the Site, length of visits to our webpages, page interaction information (such as scrolling and clicks) and methods used to browse away from our Site.<br>
            • <span>Marketing and Communications Data</span> including whether you wish to receive marketing communications from Nexxie and its affiliates.<br>
            • <span>Records</span> of any correspondence and/or communications we have with you, such us for example when you contact us with queries on specific aspects of the sponsorship. 
        </p>
        <p>
            Where the sponsor is a company we may still collect personal data of individuals, such as the Identity Data and Contact Data of any individuals who will be attending the Event on behalf of the sponsor, either as attendees or otherwise.
        </p>
        <div class="line"></div>
        <br>
        <h3>Speakers</h3>
        <p>
            • <span>Identity Data</span> including your full name.<br>
            • <span>Contact Data</span> including your e-mail address, skype ID and phone number.<br>
            • <span>Professional Data</span> including the company name of your employer (if applicable), the industry in which you or your employer operates, your area of expertise, your job position and your title. If you provide us with your CV, we may also use information contained therein to provide some background information on your professional qualifications to the Attendees of the Event. We may display such data on the Site, the App and/or on any brochures that are available at the Event.<br>
            • <span>Event Data</span> including the session type you are interested in or will participate in (e.g. panel discussion, workshop etc.) and the content description of your session.
        </p>
        <div class="line"></div>
        <br>
        <p>
            We do not intentionally collect any sensitive data about you or any information about criminal convictions and offences.
        </p>

        <br>
        <h2>5. How we use your personal data</h2>
        <p>
            We will only use your personal data when the law allows us to do so. Most commonly we will use your personal data in the following circumstances:
        </p>
        <p>
            • Where we need to perform a contract we are about to enter or have entered with you.<br>
            • Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests. Legitimate interest means the interest of our business in conducting and managing our business to enable us to give you the best service and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). <br>
            • Where you have provided your explicit consent before the processing. Consent means processing your personal data where you have signified your agreement by a statement or clear opt-in to processing for a specific purpose. Consent will only be valid if it is a freely given, specific, informed and unambiguous indication of what you want. You can withdraw your consent at any time by contacting us using the contact details set out in section 2 of this privacy policy.<br>
            • Where we need to comply with a legal or regulatory obligation that we are subject to, such as for example a tax obligation.
        </p>
        <p>
            We provide further details in connection to each legal basis on which we rely, as follows:
        </p>
        <br>
        <h3>Performance of a contract (Applicable to: Attendees, Exhibitors and Sponsors)</h3>
        <p>
            When you purchase a Ticket to attend the Event and agree to the Terms and Conditions (and other terms and conditions relating to the exhibitors and the sponsors of the Event), we will process your personal data in order to: 
        </p>
        <p>
            • Handle the general administration of your attendance at the Event and for planning and logistics (e.g. to accommodate any special access and/or dietary requirements you may have or any customisation requests for the set-up of your exhibition booth or your sponsorship rights), and<br>
            • Process your Ticket purchase including managing your payments and/or any refunds you may request, and<br>
            • Manage our relationship with you in accordance with the Terms and Conditions, including enabling you to attend the Event, notifying you of changes relating to the Event (including changes to the venue and/or date, if any), notifying you of any changes to the Site or the App that may affect you (including changes to the privacy policy and/or the Terms and Conditions) and liaising with you in order to resolve any issues or concerns you may have. 
        </p>
        <p>
            Where we need to collect personal data under the Terms and Conditions and you fail to provide that data when requested, we may not be able to provide you with our services either partly or fully. For example, if you fail to provide us and/or our payment service providers with the correct payment details, we will be unable to process your payments and you will therefore be unable to purchase a Ticket and subsequently attend the Event. 
        </p>
        <br>
        <h3>Legitimate interests (Applicable to: Attendees, Exhibitors, Sponsors and Speakers)</h3>
        <p>
            We may process your personal data on this basis in order to: 
        </p>
        <p>
            • Administer and protect our business, including the Site, the App and the organisation of the Event. To this end, we may keep track of any contact we have with you, including through phone calls, email or through other forms of communication. Records of such communications will assist us in checking your instructions to us (or vice versa) as well as your experience during future events. <br>
            • Promote and/or market the Event on any media. As explained in the Terms and Conditions, we may take photos or videos during the Event that may feature you which we may subsequently use to promote the Event and/or any future events we organise. <br>
            • Manage the security of our network and information systems relevant to the Site and the App (including troubleshooting, testing, system maintenance, support and hosting of data). <br>
            • Perform data analytics (such as market research, trend analysis, financial analysis and customer segmentation) to improve our services, marketing, customer relationships and experiences.<br>
            • Identify, prevent and investigate fraud or any other malicious activity during the Event by addressing ongoing or alleged breaches of the Terms and Conditions in order to be able to develop countermeasures and prevent recurrences in future events.<br>
            • Maintain our accounts and records.<br>
            • Receive professional advice (e.g. tax or legal advice) in connection to the Event.<br>
            • Defend, investigate or prosecute legal claims.<br>
            • Enable a sale, reorganisation, transfer or other transaction relating to our business.<br>
            • Deliver advertisements to you and measure or understand the effectiveness of the advertising we serve to you.<br>
            • Provide you with information about other services, events and offers that may be available on the Site from time to time.
        </p>
        <br>
        <h3>Legal obligation </h3>
        <p>
            In certain cases, we may need to retain your personal data in order to comply with our legal obligations. Examples include: 
        </p>
        <p>
            • When we need to contact you to deliver mandatory communications as required by any new law that comes into force;<br>
            • When we need to investigate certain fraudulent activities and/or any business disrupting incidents for which we are required to prepare relevant reports to the relevant supervisory authorities;<br>
            • When we need to comply with our tax obligations.  
        </p>
        <br>
        <h3>Consent</h3>
        <p>
            We will only request your consent in the circumstances described below.
        </p>
        <p><u>THIRD PARTY MARKETING</u></p>
        <p>
            We will get your express opt-in consent before we share your personal data with any third party for marketing and/or collaboration purposes and/or before we provide you with any information in connection to proposals, offers or promotions of third parties.  
        </p>
        <p><u>OPTING OUT </u></p>
        <p>
            You can ask us to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us by using the contact details in section 2 above.
        </p>

        <h2>6. With whom we may share your personal data</h2>
        <p>
            We may share your personal data with third parties as listed below for the purposes set out in section 5 above. When we do so, we request from such third parties to have appropriate technical and organisational measures in place to protect your personal data. We will not share any of your personal data for any purpose other than the purposes described in this privacy policy.
        </p>
        <p>
            • Service providers who provide us with:<br>
            <span class="subcat">- IT, web hosting and system administration services in connection to the Site and the App; </span><br>
            <span class="subcat">- banking and payment services in connection to the payments made either through the Site or otherwise in connection to the Event; </span><br>
            <span class="subcat">- customer support, marketing and payment solutions services; </span><br>
            <span class="subcat">- event planning services; </span><br>
            <span class="subcat">- accommodation, catering and hosting services. </span><br>
            • Other attendees at the Event who can see your profile information on the App. <br>
            • Professional advisers including lawyers, bankers, auditors, tax consultants and insurers who provide us with consultancy, banking, legal, tax, insurance and accounting services, to the extent that we need their advice in matters connected to the Event.<br>
            • Tax authorities, regulators and other governmental bodies or agencies who may require reporting of our processing activities in certain circumstances. <br>
            • Advertisers and marketing affiliates who assist us in advertising the Site, the App and the Event and who help us drive traffic to them.<br>
            • Third parties to whom we may choose to sell, transfer or merge parts of our business or assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners will use your personal data in the same way as set out in this privacy policy. <br>
            • Other entities of the Nexxie Group for specific purposes connected to the Event such as for example where they may assist in the running or organisation of the Event.<br>
            • Other third parties for which we will seek your consent prior to any disclosure. This may include, for example, third parties who may wish to advertise their products to you. 
        </p>
        <p>
            Other exhibitors and sponsors at the Event may request you to provide personal data for their own purposes. We have no control over the data protection practices of these third parties and cannot accept any liability for any personal data that you may give directly to them. Please ensure you have been adequately informed of the privacy practices of any organisation and of the reasons for which they request your personal data before you disclose your personal data to them at the Event.
        </p>
        <p><u>INTERNATIONAL TRANSFERS </u></p>
        <p>
            Many of our external third parties are based outside the European Economic Area (EEA) so their processing of your personal data will involve a transfer of data outside the EEA.
        </p>
        <p>
            Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
        </p>
        <p>
            • We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission.<br>
            • Where we use specific service providers or share your personal information with third parties, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. 
        </p>
        <p>
            Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.
        </p>

        <br>
        <h2>7. Safeguarding your data</h2>
        <p>
            All information you provide to us is stored on our secure servers.
        </p>
        <p>
            We perform regular vulnerability scans in order to ensure that our systems and servers remain secure and require that our payment-processing providers take the same measures to ensure stability and security of their own servers as well.
        </p>
        <p>
            We additionally have in place Secure Socket Layers to ensure that all communications carried out through the Site are secure. 
        </p>
        <p>
            If at any time you suspect or become aware of a breach of your data or if you believe that your information is no longer secure, please contact us immediately using the contact details in section 2 of this privacy policy, so that we can take any necessary action. 
        </p>

        <br>
        <h2>8. How long we keep your personal data</h2>
        <p>
            To determine the appropriate retention period for your personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
        </p>
        <p>
            In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
        </p>
        <p>
            If you have tried to purchase a Ticket as a visitor on the Site but have been unsuccessful (due to the fact that you have not provided us with all the information we have requested or you are not eligible to purchase a Ticket or for any other reason), we will delete any personal data you have provided to us during the said procedure immediately following your unsuccessful attempt.
        </p>

        <br>
        <h2>9. Your legal rights</h2>
        <p>
            You have the following rights in terms of the personal data and information we hold about you:
        </p>
        <p>
            <span>Receive access to your personal data </span><br>
            This enables you to receive a copy of the personal data we hold about you and to be informed on how we are lawfully processing it.
        </p>
        <p>
            <span>Request correction of the personal data we hold about you</span><br>
            This enables you to have any incomplete, inaccurate or out of date information we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
        </p>
        <p>
            <span>Request erasure of your personal data</span><br>
            This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below) or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal or contractual reasons which will be notified to you, if applicable, at the time of your request.
        </p>
        <p>
            <span>Object to processing of your personal data</span><br>
            You can object to the processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
        </p>
        <p>
            <span>Request the restriction of processing of your personal data</span><br>
            This enables you to ask us to suspend the processing of your personal data in the following scenarios:<br>
            (a) if you want us to establish the data's accuracy;<br>
            (b) where you consider that our use of the data is unlawful but you do not want us to erase it;<br>
            (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or<br>
            (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
        </p>
        <p>
            <span>The right to ‘data portability’</span><br>
            Where you request a transfer of your personal data, we will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
        </p>
        <p>
            <span>Withdraw your consent </span><br>
            In cases where you have given your consent to us, you will be able to withdraw that consent at any time. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
        </p>

        <br>
        <h2>10. How to exercise your rights</h2>
        <p>
            To exercise any of your rights as set out in section 9, you can use the contact details set out in section 2 above. 
        </p>
        <p>
            For security reasons, we may need to request specific information from you to help us confirm your identity and ensure your right to access to your personal data (or to exercise any of your other rights). We may also contact you to ask you for further information in relation to your request to speed up our response.
        </p>

        <br>
        <h2>11. Third Party links</h2>
        <p>
            Our Site may, from time to time, contain links to and from the websites of our partner networks and/or advertisers. Please note that these websites and any services that may be accessible through them have their own privacy policies. We do not accept any responsibility or liability for these third-party privacy policies or for any personal data that may be collected through these websites or services. Please check these policies before you submit any personal data to these websites or use these services.
        </p>

        <br>
        <h2>12. Children</h2>
        <p>
            We do not intend to solicit or collect personal data from anyone under the age of eighteen (18). If you are under eighteen (18) years of age, do not enter information on the Site or attend the Event. If you believe a child of yours under the age of eighteen (18) has entered personal data on the Site please contact us using the contact details in section 2 above to have the data removed. 
        </p>

        <br>
        <h2>13. Changes to our privacy policy and your duty to inform us of changes</h2>
        <p>
            We keep our privacy policy under regular review. This version of the privacy policy was last updated on 5th of August 2023. 
        </p>
        <p>
            If we update our privacy policy in the future, the changes will be posted on this page and, where appropriate, notified to you either by email or when you next access the Site. The new policy may be displayed on-screen and you may be required to read and accept the changes to continue your use of the Site.
        </p>
        <p>
            We do however encourage you to review this policy periodically so as to always be informed about how we are processing and protecting your personal information. 
        </p>
        <p>
            It is important that the personal data we hold about you is accurate and current. Please keep us informed of your personal data changes during our relationship with you.
        </p>
    </div>        
</div>