<div *ngIf="ticketTypeValid && !loading" class="ticket-header">
    <h3>Don't Forget Your</h3>
    <h1>CONFERENCE TICKETS!</h1>
</div>

<div *ngIf="ticketTypeValid && !loading" class="container">
    <div class="ticketBundlesTabs" *ngIf="bundle_types$ | async as bundle">
        <ng-container *ngIf="bundle.length > 0">
            <mat-tab-group class="ticketBundlesTabsGroup" [(selectedIndex)]="tabIndex" (selectedTabChange)="tabChange()"
                *ngIf="ticketTypes$ | async as ticketTypes">
                <ng-container *ngIf="ticketTypes.length > 0">
                    <mat-tab *ngFor="let ticket of ticketTypes; let i = index" [label]="ticket.description">
                        <ng-template mat-tab-label class="soldout_header" *ngIf="!ticket.inStock">
                            <span class="tab-number">Sold Out</span>
                            <span class="tab-text">{{ ticket.description }}</span>
                        </ng-template>
                        <div class="bundles">
                            <ng-container *ngIf="selectedBundles.length > 0">
                                <bundle-card *ngFor="let bundle of selectedBundles" [bundle]="bundle"
                                    [color]="ticket.color"></bundle-card>
                                    <!-- [color]="getColorName(i)" -->
                            </ng-container>
                        </div>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </ng-container>

        <div class="steps"><label class="step">Step 1 of 2: Select Your Tickets</label></div>
    </div>

    <div class="button">
        <button (click)="backClick()" class="btn">Back</button>
    </div>
</div>
<app-not-found *ngIf="!ticketTypeValid && !loading"></app-not-found>