import { ISubText } from '../../../../shared/components/successful-api-call/interface/successful-api-call.interface';

export const successData: ISubText = {
  subHeading: 'Thank you for your Purchase',
  content: `An email with the relevant invoice has been forwarded to your inbox. You will also be able to find the login code granting you access to our i-Con portal. Please make sure to take some time and fill in the required information of each attendee.`,
  note: 'Trouble finding our email? Check your spam folder or contact our customer support team.',
};

export const successReserveData: ISubText = {
  subHeading: 'Thank you for your Reservation',
  content: `The confirmation of your reservation along with the relevant invoice have been forwarded to your email inbox. Kind reminder to check your spam folder if the email does not seem to be delivered. Alternatively, feel free to contact our team with any issues you might have regarding your booth reservations.`,
  note: 'Please keep in mind that reservations are subject to be cancelled if they are not paid in full within 7 days after being booked.',
};

export const successFanSites: ISubText = {
  subHeading: 'Your reservation request for i-Con’s fan sites meetup has been received!',
  content: `An email has been sent to you with further information about the event.`,
  note: 'Please note a member of our team will be with you soon with further information regarding your request.',
};

export const successSEOWorkshops: ISubText = {
  subHeading: 'Your reservation request for i-Con’s SEO workshop has been received!',
  content: `An email has been sent to you with further information about the event.`,
  note: 'Please note a member of our team will be with you soon with further information regarding your request.',
};
