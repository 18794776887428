import { Component, Inject, OnInit } from '@angular/core';
import { IQuestionnaire, IQuestionnaireFinalResponse, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { QuestionnaireService } from '../../service/questionnaire.service';
import { StepService } from '../../service/step.service';
import { UserAnswersService } from '../../service/userAnswers.service';
import { I_CON_2025_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-i-con-2025',
  templateUrl: './i-con-2025.component.html',
  styleUrl: './i-con-2025.component.scss',
})
export class ICon2025Component implements OnInit {
  selectedItem: IQuestionnaireUserAnswers;
  constructor(
    @Inject(I_CON_2025_DATA) public content: IQuestionnaire,
    private userAnswersService: UserAnswersService,
    private stepService: StepService,
    private questionnaireService: QuestionnaireService
  ) {}

  ngOnInit(): void {
    this.userAnswersService.scrollToTop();
  }

  onClick(item: ISurveyAnswer) {
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: item.id,
          value: [item.value],
        },
      ],
    };

    this.userAnswersService.pushNewAnswer(this.selectedItem);
    this.stepService.goNext();

    const objectToSend: IQuestionnaireFinalResponse = {
      attendeeResponse: this.userAnswersService.getUserAnswers(),
    };

    if (this.questionnaireService.getEncodedEmailAddress().length > 0) this.questionnaireService.postUserAnswers(objectToSend).subscribe();
  }
}
