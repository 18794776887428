import { Component, Inject, ViewChild } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { StepService } from '../../service/step.service';
import { UserAnswersService } from '../../service/userAnswers.service';
import { ATTEND_PRESENTATIONS_DATA } from '../../tokens/tokens';
import { CTAButtonsComponent } from '../CTAButtons/CTAButtons.component';

@Component({
  selector: 'i-con-attendpresentations',
  templateUrl: './attendpresentations.component.html',
  styleUrl: './attendpresentations.component.scss',
})
export class AttendPresentationsComponent {
  constructor(
    @Inject(ATTEND_PRESENTATIONS_DATA) public content: IQuestionnaire,
    private userAnswersService: UserAnswersService,
    private stepService: StepService
  ) {}

  @ViewChild(CTAButtonsComponent) buttonsComponent: CTAButtonsComponent;
  selectedItem: IQuestionnaireUserAnswers;
  buttonClicked: boolean = false;

  imageClick(item: ISurveyAnswer) {
    this.buttonClicked = true;
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: item.id,
          value: [item.value],
        },
      ],
    };
  }

  nextClick() {
    if (this.selectedItem) {
      this.userAnswersService.pushNewAnswer(this.selectedItem);
      this.stepService.goToQuestionWithId(this.getNextQuestionId()!);
    }
  }

  private getNextQuestionId() {
    const item = this.content.surveyAnswer.find(answer => answer.id === this.selectedItem.answers[0].answerId);

    if (item) {
      return item.nextQuestion;
    }

    return -1;
  }
}
