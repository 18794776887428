<div class="unsubscribe" *ngIf="responseState$ | async as responseState">
    <div class="unsubscribe-header">
        <h3>Leaving</h3>
        <h1>FOR ANOTHER PLANET?</h1>
    </div>

    <div class="container">
        <div class="content">
            <img src="https://cdn-images.island-conference.com/unsubscribe/astronaut.svg" alt="">
            <h2>{{heading}}</h2>
            <div class="paragraphs">
                <p>{{text1}}</p>
                <p>{{text2}}</p>
            </div>

            <div class="buttons" *ngIf="buttonsVisible">
                <button class="main-btn white" (click)="unsubscribeClick()" [disabled]="btnClicked">Unsubscribe</button>
                <button class="main-btn" (click)="cancelClick()">Cancel</button>
            </div>
        </div>
    </div>
</div>