<div class="video-container">
	<div class="video-overlay"></div>
	<video id="myVideo" loop muted autoplay playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
		<source src="https://cdn-images.island-conference.com/mykonos/video.mp4" type="video/mp4" />
	</video>
</div>

<div class="miconos">
	<div class="container flexr">
		<h3 class="date">02<strong>JUN</strong>04<br>
			<p>2024</p>
		</h3>

		<div class="logo-container">
			<img class="logo" src="https://cdn-images.island-conference.com/mykonos/meetup.svg"
				alt="Miconos logo including meetup logo below. Logos are in white text" />
		</div>


		<div class="meetup">
			<p>The Miconos MeetUp is different, taking you to one of the most inspiring places on the map, allowing
				individuals from different industries across the globe to come together! It invites the masterminds
				behind the largest corporations in the industry and allows them to connect, socialise and work in a more
				playful and relaxed setting!</p>
		</div>

	</div>

	<div class="viplist">
		<div class="container">
			<h3>
				How To Get On The VIP List?
			</h3>
			<p>You can start registering for the Miconos MeetUp and secure your place on the VIP-List for two days of
				networking, business opportunities, and pleasure!</p>


			<div class="img">
				<img src="https://cdn-images.island-conference.com/mykonos/windmill.svg" alt="">
			</div>

			<p><b>This is an invitation-only private event tailor-made for:</b></p>
			<div class="categories flexr">
				<div class="left flexr">
					<img class="arrow" src="https://cdn-images.island-conference.com/mykonos/arrow_right_brown.svg"
						alt="Brown arrowhead pointing to the right " />
					<p>C-Level Executives</p>
				</div>
				<div class="right flexr">
					<img class="arrow" src="https://cdn-images.island-conference.com/mykonos/arrow_right_brown.svg"
						alt="Brown arrowhead pointing to the right " />
					<p>Business Owners</p>
				</div>

				<div class="right flexr">
					<img class="arrow" src="https://cdn-images.island-conference.com/mykonos/arrow_right_brown.svg"
						alt="Brown arrowhead pointing to the right " />
					<p>Super Affiliates</p>
				</div>
			</div>

			<p class="exclusive">
				Exclusive to i-Con's attendees only who will be participating in the Conference and supporting our vision with a minimum investment of 10K.
			</p>

			<p class="exclusive"><small>*There will be a participation fee for Miconos MeetUp</small>

			</p>
		</div>
	</div>

	<div class="third-section container">

		<div class="invite">
			<h3>Here's how you can put your names on the VIP-List:</h3>
			<p>Fill in your personal information, company details, and vertical! If you meet our requirements then a
				member of our team will be in touch with you!</p>
			<button class="main-btn" (click)="openTripDialog()">Enter the VIP-List</button>
			<div class="dashes"></div>
		</div>


		<div id="aftermovie" class="youtube">
			<p>Don't forget to watch the unforgettable times we experienced on last years' Miconos adventure! Check out
				i-Con's exclusive Miconos video on our YouTube channel!</p>
			<button><a href="https://www.youtube.com/watch?v=jMqGpiBO57I" target="_blank">Watch the video on YouTube
					<div class="polygon"></div>
				</a> </button>
		</div>

		<div class="organised">
			<span>Organised By</span>
			<div class="organisers">
				<img src="https://cdn-images.island-conference.com/mykonos/nexxie.svg" alt="Nexxie Group">
				<img src="https://cdn-images.island-conference.com/header/logo.png" alt="i-Con">
				<img src="https://cdn-images.island-conference.com/vision/haze_logo_white.svg" alt="Haze team">
			</div>
		</div>

		<div class="sponsored">
			<span>Sponsored By</span>
			<div class="sponsors">
				<img src="https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg" alt="Redwalking">
				<img src="https://cdn-images.island-conference.com/exhibitors/logos/adsempire.svg" alt="Ads Empire">
			</div>
		</div>
	</div>

	<div class="fourth-section">
		<div class="container">
			<p>
				"I've been travelling to Mykonos for more than 15 Years and every time this beautiful island has
				something new & unique to offer! The Myths are real! The spiritual journeys and the life experiences
				that can happen within a glimpse of an eye, make this Cycladic Island, like no other!
			</p>

			<p>
				Those who know me
				well, already know that I always like to share these experiences. That's why I took the decision to host
				this Invitation-Only trip to Mykonos, right after the bespoke i-Con conference, in my home country,
				Cyprus. I am confident that through this trip, we will be able to change the path and the future of our
				industry, through meaningful discussions and decisions, among some of the most influential people of our
				generation.

			</p>
			<!-- <p>Special thanks to the ExoClick team, who believed and supported our vision!</p> -->
			<p>See you soon in Cyprus & Mykonos" </p>

			<p>Love & Respect </p>
			<p>Charis Demetriou <br> CEO of Nexxie Group</p>

		</div>
	</div>


	<div class="trip-includes container">

		<h3>What Does This Trip Include?</h3>

		<div class="sixth-section section section-gallery">

			<div class="section-img-container"><img class="section-img"
					src="https://cdn-images.island-conference.com/mykonos/flight.png" alt="" /></div>


			<div class="detailsContainer">	
				<div class="details text-align-right">
					<h3>Flight<br /><strong>Paphos > Mykonos</strong></h3>				
				</div>
				<div id="plane" class="section-icon-container">
					<img class="section-icon" src="https://cdn-images.island-conference.com/mykonos/plane.svg"
						alt="Icon of a plane" />
				</div>
			</div>

		</div>


		<div class="fifth-section section section-gallery">

			<div class="detailsContainer">
				<div class="details row">
					<h3>
						Bed & Breakfast <br />
						<strong>Accommodation</strong>
					</h3>

				</div>
				<div id="hotel" class="section-icon-container">
					<img class="section-icon" src="https://cdn-images.island-conference.com/mykonos/hotel_black.svg"
						alt="Icon of person sleeping in bed. Background is in light grey colour" />
				</div>

			</div>
			<div class="section-img-container">
				<img class="section-img" src="https://cdn-images.island-conference.com/mykonos/image_1.jpg"
					alt="3 images. First is of sunbeds around pool area. Second is a bed with beige covers. Third is 2 chairs and small brown table" />
			</div>
		</div>

		<div class="sixth-section section section-gallery">

			<div class="section-img-container"><img class="section-img"
					src="https://cdn-images.island-conference.com/mykonos/nammos-img.png" alt="" /></div>


			<div class="detailsContainer">
				<!-- <div class="section-icon-container">
					<img class="section-icon" src="https://cdn-images.island-conference.com/mykonos/nammos logo.svg"
						alt="Opia company logo in white text" />
				</div> -->
				<div class="details">

					<h3>Relaxing Day<br /><strong>At The Beach</strong></h3>
					<!-- <div class="website">
						<img class="globe-img" src="https://cdn-images.island-conference.com/mykonos/globe_black.svg"
							alt="Globe icon in black colour" />
						<a href="https://www.nammos.com/" rel="nofollow" target="_blank">nammos.com</a>
					</div>
					<div class="insta">
						<img class="insta-img" src="https://cdn-images.island-conference.com/mykonos/insta_black.svg"
							alt="Icon of instagram logo in black colour" />
						<a href="https://www.instagram.com/nammosworld/" rel="nofollow" target="_blank">nammosworld</a>
					</div> -->
				</div>
			</div>

		</div>


		<div class="fifth-section section section-gallery">

			<div class="detailsContainer">
				<div class="details">
					<h3>A Night Out<br /><strong>At Scorpios</strong></h3>
					<div class="website">
						<img class="globe-img" src="https://cdn-images.island-conference.com/mykonos/globe_black.svg"
							alt="Globe icon in grey colour" />
						<a href="https://www.scorpiosmykonos.com/" rel="nofollow"
							target="_blank">scorpiosmykonos.com</a>
					</div>
					<div class="insta">
						<img class="insta-img" src="https://cdn-images.island-conference.com/mykonos/insta_black.svg"
							alt="Icon of instagram logo in grey colour" />
						<a href="https://www.instagram.com/scorpios.mykonos" target="_blank">scorpios.mykonos</a>
					</div>
				</div>
				<div class="section-icon-container">
					<img class="section-icon" src="https://cdn-images.island-conference.com/mykonos/scorpios_black.svg"
						alt="Skorpios company logo in black text" />
				</div>
			</div>


			<div class="section-img-container">
				<img class="section-img" src="https://cdn-images.island-conference.com/mykonos/image_2.png"
					alt=" images. First is of party with hands in the air. Second is people gathered at a party. third is a band playing at the sea" />
			</div>

		</div>


		<div class="seventh-section section section-gallery">
			<div class="detailsContainer">
				<div class="details">
					<h3>Dinner At<br /><strong>Noema</strong></h3>
					<div class="website">
						<img class="globe-img" src="https://cdn-images.island-conference.com/mykonos/globe_black.svg"
							alt="Globe icon in grey colour" />
						<a href="https://www.noemamykonos.com/" rel="nofollow" target="_blank">noemamykonos.com</a>
					</div>
					<div class="insta">
						<img class="insta-img" src="https://cdn-images.island-conference.com/mykonos/insta_black.svg"
							alt="Icon of instagram logo in grey colour" />
						<a href="https://www.instagram.com/noemamykonos" target="_blank">noemamykonos</a>
					</div>

				</div>
				<div class="section-icon-container">
					<img class="section-icon" src="https://cdn-images.island-conference.com/mykonos/noema_black.svg"
						alt="Noema company logo in white text" />
				</div>
			</div>

			<div class="section-img-container">
				<img class="section-img" src="https://cdn-images.island-conference.com/mykonos/image_4.png"
					alt="3 images. First is a man with a pan of food. Second, is a restaurant serving area. Third is a woman serving a man and a woman" />
			</div>


		</div>

		<!-- <div class="final-section container">
			<p>Don't Miss The Opportunity To Become The VIP Sponsor Of Our Mykonos Meetup This Year! Let Your Brand
				Shine
				And Capture The Attention Of Influential Individuals Across Multiple Industries. Allow Them To
				Experience
				The Beauty Of Mykonos And Taste The Amazing Flavours Offered At Every Corner Of The Island! You Can Find
				More Information On Our "Sponsorships" Page.</p>

			<button class="main-btn">Sponsor The MeetUp</button>
		</div> -->