import { Component, Inject, OnInit } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { UserAnswersService } from '../../service/userAnswers.service';
import { EVENTS_LIKE_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-events-like',
  templateUrl: './events-like.component.html',
  styleUrl: './events-like.component.scss',
})
export class EventsLikeComponent implements OnInit {
  selectedItem: IQuestionnaireUserAnswers;

  constructor(@Inject(EVENTS_LIKE_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService) {}

  ngOnInit(): void {
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: 0,
          value: [''],
        },
      ],
    };
  }

  onItemClick(item: ISurveyAnswer) {
    const existingIndex = this.selectedItem.answers.findIndex(answer => answer.answerId === item.id);
    if (existingIndex !== -1) {
      this.selectedItem.answers.splice(existingIndex, 1);
    } else {
      const newItem = {
        answerId: item.id,
        value: [item.value],
      };
      this.selectedItem.answers.push(newItem);
    }
  }

  isSelected(item: ISurveyAnswer) {
    return this.selectedItem.answers.some(answer => answer.answerId === item.id);
  }
  nextClick() {
    //if it's 1 then it means we only have the default state
    if (this.selectedItem.answers.length !== 1) {
      this.selectedItem.answers.shift();
      this.userAnswersService.pushNewAnswer(this.selectedItem);
    }
  }
}
