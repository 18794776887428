import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-privacy-policy',
  templateUrl: './app-privacy-policy.component.html',
  styleUrls: ['./app-privacy-policy.component.scss']
})
export class AppPrivacyPolicyComponent implements OnInit {

  @Input() class_name:string = 'contact_page';
	@Input() title1: string = 'Privacy Policy';
	@Input() title2: string = '';

  constructor(
  ) { }

  ngOnInit(): void {
    
  }

}
