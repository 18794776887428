<div class="stay-header">
	<div class="content">
		<div class="left">
			<h3>We'll make your stay</h3>
			<h1>UNFORGETTABLE</h1>
		</div>
		<div class="right">
			<img class="header-icon" src="https://cdn-images.island-conference.com/pageheader/header_logo.png">
		</div>
	</div>	
</div>

<app-stay-accommodation-list></app-stay-accommodation-list>

<div class="transportation">
	<div class="content">
		<h2><span>TRANSPORTATION</span><br/>TO AND FROM THE VENUE</h2>
		<p>
			Rest assured that your transportation needs during the days of the conference will be taken care of! The i-Con team is fully prepared to arrange your move to and from Larnaca & Paphos International airports and our Venue - City of Dreams Mediterranean, but also your transport from the Venue to our exclusive events and back!<br/><br/>We have secured several shuttle buses which will be operational on different intervals during the conference for your convenience. If you wish for a more private means of transportation, you can contact our Customer Support, to book for you a private ride (4 or 6 seats) at pre-set prices provided by our exclusive associates.
		</p>
		<app-nav-button class="main-btn" [type]="'internal'" [new_path]="['/stay/transportation']"
				[text]="'View More'" [target]="'_blank'"></app-nav-button>
	</div>
</div>
<div class="activities">
	<div class="container">
		<h2>ACTIVITIES<br/><br/>
		<span>BEYOND THE CONFERENCE - DISCOVER CYPRUS</span></h2>
		<p>Welcome to our home, Cyprus! While we all know you are here for those valuable new leads and to catch up with old friends, who says you can’t do that while also having some fun in the sun . .?!<br/><br/> Check out our top recommended activities to help you experience the essence of Cyprus and make the most of your time in Limassol:</p>
		<div class="title">
			<h3>ALL ACTIVITIES</h3>
			<app-nav-button class="main-btn" [type]="'internal'" [new_path]="['/stay/activities']"
				[text]="'View More'" [target]="'_blank'"></app-nav-button>
		</div>
		<div class="activities-list">
			<div class="activities-row row1">
				<div style="--url: url({{activityData[0].mainPageImageUrl}})">
					<div class="backdrop"></div>
					<p>{{activityData[0].shortTitle}}</p>
				</div>
				<div style="--url: url({{activityData[1].mainPageImageUrl}})">
					<div class="backdrop"></div>
					<p>{{activityData[1].shortTitle}}</p>
				</div>
			</div>
			<div class="activities-row row2">
				<div style="--url: url({{activityData[2].mainPageImageUrl}})">
					<div class="backdrop"></div>
					<p>{{activityData[2].shortTitle}}</p>
				</div>
				<div style="--url: url({{activityData[3].mainPageImageUrl}})">
					<div class="backdrop"></div>
					<p>{{activityData[3].shortTitle}}</p>
				</div>
			</div>
			<div class="activities-row row3">
				<div style="--url: url({{activityData[4].mainPageImageUrl}})">
					<div class="backdrop"></div>
					<p>{{activityData[4].shortTitle}}</p>
				</div>
			</div>
		</div>
		<app-nav-button class="main-btn activities-link-mobile" [type]="'internal'" [new_path]="['/stay/activities']"
				[text]="'View More'" [target]="'_blank'"></app-nav-button>
	</div>
</div>

<div class="care">
	<div class="container">
		<div class="info">
			<div class="content">
				<h2>Green & Eco-Friendly</h2>
				<p>Our commitment to providing you an eco-friendly affiliate conference has been one of our utmost priorities. This has lead us to employ sustainable practices such as water reuse, as well as the removal of plastic products from the conference.</p>
				<p>Additionally, our attendees will be able to enjoy vegan delicacies as we offer plenty of animal-friendly alternatives in our menus.</p>
				<p>Have we mentioned that you can also enjoy some family-friendly amenities, guaranteed to keep your little ones entertained, and allowing you to enjoy i-Con to the fullest!</p>
				<app-nav-button class="main-btn" type="internal" [new_path]="['/stay/care']"
				text="Read More" target="_blank"></app-nav-button>
			</div>
		</div>
		<div class="image"><img src="https://cdn-images.island-conference.com/care/no_planb.svg" alt=""></div>
	</div>
</div>