import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService, StateService } from '@i-con/shared/common';
import { Observable } from 'rxjs';
import { IUnsubscribeTypeResponse } from '../../../core/_models/responses/unsubscribe-type-response.interface';

interface IUnsubscribeState {
  postData: IUnsubscribeTypeResponse;
}

const initialStateValue: any = {
  postData: {},
};

@Injectable({ providedIn: 'root' })
export class UnsubscribeService extends StateService<IUnsubscribeState> {
  postData$: Observable<IUnsubscribeTypeResponse> = this.getState(state => state.postData);

  constructor(private http: HttpClient, private _environment: EnvironmentService) {
    super(initialStateValue);
  }

  unsubscribeService(userInfo: object) {
    this.http.post<IUnsubscribeTypeResponse>(`${this._environment.getApiUrl}/unsubscribe`, userInfo).subscribe({
      error: (error: any) => {
        this.setState({ postData: error.error });
      },
      next: (resposnse: any) => {
        this.setState({ postData: resposnse });
      },
    });
  }
}
