import { Component, OnInit, Input } from '@angular/core';
import { activityData } from '../stay-activities/data/activity-data';

@Component({
	selector: 'app-stay',
	templateUrl: './stay.component.html',
	styleUrls: ['./stay.component.scss']
})
export class StayComponent implements OnInit {

	class_name = 'stay_accommodation_page';
	phrase: string = 'We\'ll Make Your Stay';
	title1: string = 'UNFORGETTABLE';


	constructor() { }

	public activityData: any = activityData;

	ngOnInit(): void {

	}
}
