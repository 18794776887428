<div class="homepage">
	<video id="myVideo" loop muted autoplay playsinline oncanplay="this.play()"
		poster="https://cdn-images.island-conference.com/homepage/poster_2024.jpg"
		style=" border-bottom: 1px solid transparent;" onloadedmetadata="this.muted = true">
		<source src="https://cdn-images.island-conference.com/homepage/icon_2024.mp4" type="video/mp4" />
	</video>
	<div class="top-gradient"></div>
	<div class="video-overlay"></div>
	<div class="bottom-gradient"></div>
	<div class="container">
		<div class="fist-section section">
			<h1>
				Your Future<br>
				<span>Affiliate</span><br>
				Conference
				
			</h1>
			<p class="quote">#OutOfThisWorld</p>
		</div>
		<div class="second-section section">

			<h3>30&31 MAY 2024</h3>
			<p>Limassol - Cyprus</p>
		</div>
		<div class="third-section section">
			<p class="digital">
				Join digital marketing professionals from across the 
				globe in the ultimate affiliate conference of the year!
			</p>
		</div>
		<app-nav-button class="main-btn purchase" [class]="'purchase-btn'" [new_path]="['/attend/tickets']"
				[text]="'PURCHASE TICKETS'" [type]="'internal'" [disabled]="true"></app-nav-button>
		<div class="fourth-section section">
			<p class="download">Be The First To Download Our App</p>
			<div class="apps">
				<img (click)="appStore()" class="app-store mobile-app" src="https://cdn-images.island-conference.com/homepage/app_store.svg">
				<img (click)="playStore()" class="play-store mobile-app" src="https://cdn-images.island-conference.com/homepage/google_play.svg">
			</div>
		</div>
		<div class="fifth-section section">
			<p class="headline">Our Headline Sponsor</p>
				<img class="sponsor" src="https://cdn-images.island-conference.com/homepage/adnium_home.svg">
		</div>
	</div>
</div>