import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
// import { IBoothType } from '@i-con/shared/common';
import { IBoothType } from "../../../../core/_models/booth.interface";
import { BoothStateService } from '../../../../core/_services/booths/booth-state.service';
import { BoothService } from '../../../../core/_services/booths/booth.service';

import { MatDialog } from '@angular/material/dialog';
import { PhotosDialogComponent } from '@i-con/shared/ui';
import { SoldoutListComponent } from '../soldout-list/soldout-list.component';
import { boothPhotosData } from '../../../exhibitors/data/boothphotos-data';
import { PlatformService } from '@i-con/shared/common';

declare let window: any;

@Component({
	selector: 'select-booth-type',
	templateUrl: './select-booth-type.component.html',
	styleUrls: ['./select-booth-type.component.scss'],
})

export class SelectBoothTypeComponent implements OnInit {
	selected_booth_type$: Observable<IBoothType>;
	inStock: boolean = false;
	selected_booth_id: number | undefined;

	@Input() current_step: number = 1;
	@ViewChild('boothContent') boothContent!: ElementRef;
	@Output() onPreviousNextBoothChanged = new EventEmitter<'previous' | 'next'>();
	@Output() onNavigateToStep = new EventEmitter<number>();

	public boothPhotosData: any = boothPhotosData;

	constructor(
		private _platform: PlatformService,
		private boothService: BoothService, 
		private elem: ElementRef, 
		private boothState: BoothStateService, 
		public dialog: MatDialog
		) {}

	ngOnInit(): void {
		this.selected_booth_type$ = this.boothState.selected_booth_type$;


		// throw new Error('Method not implemented.');
		this.selected_booth_type$.subscribe((res) => {
			this.selected_booth_id = res.id;
			this.updateUI();
		});


		this.boothState.booth_types$.subscribe((booth_types: IBoothType[]) => {
			for (let i=0; i<booth_types.length; i++) {
				if (booth_types[i].inStock== true) {
				  this.inStock = true;
				}
			}

			this.updateUI();
		})

		this.updateUI();
	
	}

	ngAfterViewInit() {
		this.boothState.booth_types$.subscribe((booth_types: IBoothType[]) => {
			if (booth_types.length == 0) {
				return;
			}

			for (let i=0; i<booth_types.length; i++) {
				if (booth_types[i].inStock == true) {
					this.inStock = true;
				}
			}
			//console.log(this.inStock);

			if (this.inStock == false) {
				// this.openSoldoutDialog();
			}
		})

		this.updateUI();

		//this.openSoldoutDialog();
	}

	updateUI(): void {
		if (this._platform.isBrowser) {
			this.updateBoothBackgroundImage();
		}
	}

	viewBooth(direction: 'previous' | 'next'): void {
		this.onPreviousNextBoothChanged.emit(direction);
		this.updateUI();
	}

	/** Prevent image flickering on image background change */
	updateBoothBackgroundImage(): void {
		const img_tag = new Image();
		//var device = window.innerWidth <= 1100 ? '_mobile' : '';

		// var img_url = 'https://cdn-images.island-conference.com/attend/booths/' + this.selected_booth_id + '/booth-bgr' + device + '.jpg';
		const img_url = 'https://cdn-images.island-conference.com/attend/booths/' + this.selected_booth_id + '/booth-bgr.jpg';
		const parent = this;
		img_tag.onload = function () {
			parent.boothContent.nativeElement.style.backgroundImage = 'url(' + img_url + ')';
		};
		// setting 'src' actually starts the preload
		img_tag.src = img_url;
	}


	goToNextStep(): void {
		//console.log(this.selected_booth_id);
		const boothInfoObject: any = { step: 2, selectedBoothId: this.selected_booth_id };
		window?.gtag('event', 'page_view', {
			page_title: 'i-Con Booths, Affiliate Conference, Booth Reservation Form |',
			page_location: 'https://islandconference.com/attend/booths/reserve',
			send_to: 'G-B8XPWS7NB5',
		});
		// this.onNavigateToStep.emit(2);
		this.onNavigateToStep.emit(boothInfoObject);
	}

	openSoldoutDialog() {
		// event?.preventDefault();
		this.dialog.open(SoldoutListComponent, {
			maxHeight: '90vh',
			maxWidth: '95vw',
			width: '800px',
			autoFocus: false,
			disableClose: false,
			panelClass: 'sold-out-booth',
		});

	}

	openBoothPhotosDialog(booth_type_id: any) {
		event?.preventDefault();
		booth_type_id = booth_type_id - 1;
		this.dialog.open(PhotosDialogComponent, {
		  maxHeight: '80vh',
		  maxWidth: '95vw',
		  width: '850px',
		  autoFocus: false,
		  disableClose: false,
		  data: this.boothPhotosData[booth_type_id],
		});
	}

	getContent(includes: object){
		if("content" in includes){
			return includes.content;
		}
		return "";
	}

	getIconUrl(includes: object){
		if("iconUrl" in includes){
			return includes.iconUrl;
		}
		return "";
	}

	formatDate(dateObj: Date): string {
		const date = new Date(dateObj);
		const day = date.getUTCDate();
		const month = date.toLocaleString('default', { month: 'long' });
		const year = date.getFullYear();
	
		const nthNumber = (number: number) => {
		  if (number > 3 && number < 21) return 'th';
		  switch (number % 10) {
			case 1:
			  return 'st';
			case 2:
			  return 'nd';
			case 3:
			  return 'rd';
			default:
			  return 'th';
		  }
		};
	
		return `${day}${nthNumber(day)} of ${month} ${year}`;
	  }


	checkEarlyActive(dateObj: Date) {
		const currentDate = new Date();
		if (new Date(dateObj) <= currentDate) {
			return true;
		}
		return false;
	}


}
