<div class="card" [ngClass]="{ yellow: event.columnColor}">

    <div class="speakers" [ngClass]="{ yellow: event.columnColor}">
        <div *ngIf="event.type" class="pill" ngClass="{{event.typeColor}}"><span class="type">{{event.type}}</span></div>
        <div class="speaker-card" *ngFor="let speaker of event.speakers">
            <div class="prof-img">
                <div><img src="{{speaker.profile}}" alt="{{speaker.name}}"/></div>
            </div>
            <div class="prof-desc">
                <p>{{speaker.name}}</p>
                <p>{{speaker.position}}</p>
                <div class="company-logo">
                    <img src="{{speaker.companyLogo}}" alt="{{speaker.companyName}}"/>
                </div>
            </div>
        </div>
    </div>

    <div class="first" [ngClass]="{ yellow: event.columnColor}">
        <span class="title">{{event.title}}</span>
    </div>

    <div class="second" [ngClass]="{ yellow: event.columnColor}">
        <div *ngIf="event.areaText" class="area">
            <span class="areaText">{{event.areaText}}</span>
            <span class="areaLocation" *ngIf="event.areaLocation">{{event.areaLocation}}</span>
        </div>

    </div>

    <div class="third" [ngClass]="{ yellow: event.columnColor, alphabetic: event.timeIsAlphabetic}">
        <span class="time">{{event.time}}</span>
        <img src="https://cdn-images.island-conference.com/meetups/workshops/clock-dark.svg" alt="Time">
    </div>
</div>