<div class="top-banner">
    <h1>ACTIVITIES</h1>
</div>
<div class="main-content">
    <div class="container">
        <h2>
            BEYOND THE CONFERENCE - DISCOVER CYPRUS
        </h2>
        <p>
            Welcome to our home, Cyprus! While we all know you are here for those valuable new leads and to catch up with old friends, who says you can’t do that while also having some fun in the sun . .?!<br/><br/> Here are five of our top recommended activities to help you experience the essence of Cyprus and make the most of your time in Limassol:
        </p>
        <div class="items">
            <div class="item" *ngFor="let activity of activityData; let i = index">
                <activity-item [activity]="activity" [childIndex]="i">
                </activity-item>
            </div>
        </div>
    </div>
</div>