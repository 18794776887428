<div *ngIf="loaded && validPath">
    <div class="purchase-header">
        <div class="content">
            <h1>TICKET PURCHASE</h1>
        </div>
    </div>
    <ng-container *ngIf="!success && currentBundle">
        <div class="container">
            <div class="topLevelContainer" >
                <div class="heading">
                    <span *ngIf="isLoggedIn">One Step Away! You Won't Need To Fill In Your Information To Get Your i-Con Tickets!</span>
                    <span *ngIf="!isLoggedIn">One Step Away ! Drop Your Contact, Billing And Payment Info To Seal The Deal.</span>
                    <span class="step">Step 2 of 2: Fill in Your info</span>
                </div>
    
                <div class="formContainer">
                    <i-con-purchase-ticket-form-group [ticketId]="currentBundle.ticket.id"
                        [quantity]="currentBundle.realQuantity"
                        [ticketName]="currentBundle.ticket.description"
                        [confirmationName]="getConfirmationName(currentBundle.realQuantity, currentBundle.ticket.description)"
                        >
                    </i-con-purchase-ticket-form-group>
                </div>
    
                <div class="orderSummaryContainer">
                    <div class="orderSummary">
                        <span>Order Summary</span>
                    </div>
                    <hr>
    
                    <div class="items">
                        <div class="item">
                            <span>{{currentBundle.realQuantity}} x {{currentBundle.ticket.description}}{{currentBundle.realQuantity > 1 ?
                                's' : ''}}</span>
                            <span #price>{{getTicketPrice() | currency:'EUR':"symbol"}}</span>
                        </div>
                    </div>
    
                    <hr>
                    <div class="total">
                        <span>Total</span>
                        <span>{{price.innerText}} </span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="success">
        <successful-api-call heading="Payment Successful" [subText]='successData'
        [redirect]="true"
        [newPath]="'/attend/tickets'"
        ></successful-api-call>
    </ng-container>
</div>

<div *ngIf="loaded">
    <app-not-found *ngIf="!validPath"></app-not-found>
</div>

