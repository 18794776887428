import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, combineLatest, filter, map, switchMap } from 'rxjs';
import { TicketBundleService } from '../../../..//core/_services/tickets/ticket-bundle.service';
import { ITicketBundleType } from '../../../../core/_models/ticket-bundle.interface';
import { PurchaseTicketFormGroupService } from '../../../../core/_services/tickets/purchase-ticket-form-group/purchaseTicketFormGroup.service';
import { ISubText } from '../../../../shared/components/successful-api-call/interface/successful-api-call.interface';
import { SharedModule } from '../../../../shared/shared.module';
import { successData, successDataExistingRes, successDataNewRes } from '../../data/success.data';
import { ITicketBundleTypeResponse } from '../../../../core/_models/responses/ticket-type-bundle-response.interface';
import { TicketBundleStateService } from '../../../../core/_services/tickets/ticket-bundle-state.service';
import { IDiscountType } from '../../../..//core/_models/discount-type.interface';
import { AuthorizationService, PlatformService } from '@i-con/shared/common';

@Component({
  selector: 'purchase-ticket',
  standalone: true,
  templateUrl: './purchase-ticket.component.html',
  styleUrl: './purchase-ticket.component.scss',
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseTicketComponent implements OnInit {
  class_name = 'purchase-tickets';
  title1 = `TICKET PURCHASE`;
  currentBundle: ITicketBundleType;
  success$: Observable<boolean>;
  success: boolean = false;

  bundleTypes$: Observable<ITicketBundleTypeResponse>;
  bundleTypes: ITicketBundleType[] = [];

  validPath: boolean = false;
  loaded: boolean = false;

  successData: ISubText = successDataNewRes;
  ticketTypeFilter$: Observable<string | null>;
  ticketQuantityFilter$: Observable<string>;

  confirmationName: string = '';
  isLoggedIn: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private readonly ticketBundleService: TicketBundleService,
    private readonly purchaseTicketFormGroupService: PurchaseTicketFormGroupService,
    private readonly platformService: PlatformService,
    private readonly ticketBundleStateService: TicketBundleStateService,
    private ref: ChangeDetectorRef,
    private authService: AuthorizationService
  ) {}

  ngOnInit(): void {

    this.isLoggedIn = this.authService.isLoggedIn();

    if(this.isLoggedIn) {
      this.successData = successDataExistingRes;
    }

    this.ticketTypeFilter$ = this.route.paramMap.pipe(map((params: ParamMap) => params.get('type')));
    this.ticketQuantityFilter$ = this.route.paramMap.pipe(
      map((params: ParamMap) => {
        const quantity = params.get('quantity');
        if (quantity !== null) return quantity;
        else return '-1';
      })
    );

    this.loadBundle();

  }


  getConfirmationName(quantity: number, description: string) {
    if (quantity > 1) {
      return description + "s";
    }
    else {
      return description;
    }
  }

  loadBundle() {
    this.bundleTypes$ = this.ticketTypeFilter$.pipe(
      switchMap(ticketTypeId => this.ticketBundleService.getTicketBundlesByTicketId(ticketTypeId ? ticketTypeId : '0'))
    );

    combineLatest({ bundles: this.bundleTypes$, quantity: this.ticketQuantityFilter$ })
      .pipe(filter(val => !!val.quantity))
      .subscribe(val => {
        const bundles:ITicketBundleType[] = val.bundles.response.data.filter(bundle => bundle.ticketQuantity === +val.quantity);
        if (bundles.length == 1) {
          this.currentBundle = bundles[0];
          this.loaded = true;
          this.validPath = true;
          this.purchaseTicketFormGroupService.setSuccess(false);
          this.purchaseTicketFormGroupService.setInTransaction(false);
          this.purchaseTicketFormGroupService.setSubmitted(false);
          this.ticketBundleStateService.setSelectedBundleType(this.currentBundle);
          
          if(this.platformService.isBrowser){
            this.ref.detectChanges();
          }
        }else {
          this.validPath = false;
          this.loaded = true;
        }
      });

      this.success$ = this.purchaseTicketFormGroupService.success$;
      this.success$.subscribe(success => {
        this.success = success;
      });
  }

  getTicketPrice() {
    let price = this.currentBundle.ticket.price;

    if(this.currentBundle.ticket.discounts.length > 0){
      this.currentBundle.ticket.discounts.forEach((discount:IDiscountType) => {
        price = this.applyDiscount(price, discount);
      })
    }

    if(this.currentBundle.discounts.length > 0){
      this.currentBundle.discounts.forEach((discount:IDiscountType)=> {
        price = this.applyDiscount(price, discount);
      })
    }

    return price * this.currentBundle.realQuantity;
  }

  applyDiscount(price: number, discount: any){
    const endsOn = new Date(Date.parse(discount.endsOn));
    if(new Date() < endsOn){
      return Math.round(price * (1 - discount.percentage) * 100)/100;
    }else{
      return price;
    }
  }

}
