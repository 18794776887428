import { Component, Inject } from '@angular/core';
import { IQuestionnaire } from '../../interface/questionnaire.interface';
import { HAZE_EVENTS_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-haze-events',
  templateUrl: './haze-events.component.html',
  styleUrl: './haze-events.component.scss',
})
export class HazeEventsComponent {
  constructor(@Inject(HAZE_EVENTS_DATA) public content: IQuestionnaire) {}
}
