import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'become-a-speaker',
  templateUrl: './become-a-speaker.component.html',
  styleUrl: './become-a-speaker.component.scss',
})
export class BecomeASpeakerComponent {
  constructor(private router: Router, public dialogRef: MatDialogRef<BecomeASpeakerComponent>) {}

  submitClick() {
    this.dialogRef.close();
    this.router.navigate(['/contact'], { queryParams: { enquiry: 'speaker' } });
  }

  cancelClick() {
    this.dialogRef.close();
  }
}
