import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { StateService } from '@i-con/shared/common';
import { Observable } from 'rxjs';
import { IUnsubscribeTypeResponse } from '../../core/_models/responses/unsubscribe-type-response.interface';
import { UnsubscribeService } from './service/unsubscribe.service';

interface IUnsubscribeState {
  success: boolean;
  return_msg: string;
}

const initialState: IUnsubscribeState = {
  success: false,
  return_msg: '',
};

@Component({
  selector: 'i-con-unsubscribe',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './unsubscribe.component.html',
  styleUrl: './unsubscribe.component.scss',
})
export class UnsubscribeComponent extends StateService<IUnsubscribeState> implements OnInit {
  heading: string = 'BEFORE YOU GO...';
  text1: string = "By unsubscribing you will no longer receive i-Con's latest news and updates.";
  text2: string = 'Are you sure you want to proceed?';
  buttonsVisible: boolean = true;
  emailAddress = '';
  postData$!: Observable<IUnsubscribeTypeResponse>;
  responseState$!: Observable<IUnsubscribeState>;
  btnClicked = false;
  constructor(private router: Router, private route: ActivatedRoute, private unsubscribeService: UnsubscribeService) {
    super(initialState);
  }

  ngOnInit(): void {
    this.postData$ = this.unsubscribeService.postData$;
    this.responseState$ = this.getState(state => state);

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.emailAddress = params.get('emailAddress') ?? '';
    });
  }

  unsubscribeClick() {
    this.btnClicked = true;
    const decryptedEmail = this.decodeBase64(this.emailAddress);

    const unsubscribe_object = {
      email: decryptedEmail,
    };

    this.unsubscribeService.unsubscribeService(unsubscribe_object);

    this.postData$.subscribe(res => {
      if (res.response?.statusCode == 201) {
        this.setState({
          success: true,
          return_msg: '',
        });
        this.heading = 'UNSUBSCRIBED SUCCESSFULLY';
        this.text1 = 'Goodbye from the i-Con Team';
        this.text2 = "We're sad to see you go.";
        this.buttonsVisible = false;
      } else {
        this.setState({
          success: false,
          return_msg: res.response?.message,
        });
        if (res.response?.message) {
          this.heading = 'CANNOT UNSUBSCRIBE!';
          this.text1 = res.response?.message;
          this.text2 = '';
          this.buttonsVisible = false;
        }
      }
    });
  }

  cancelClick() {
    this.router.navigate(['/']);
  }

  private decodeBase64(encodedString: string): string {
    let decodedString = '';
    try {
      decodedString = atob(encodedString);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(decodedString)) {
        decodedString = '';
      }
    } catch (e) {
      decodedString = '';
    }
    return decodedString;
  }
}
