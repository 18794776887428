<div class="reservation-container">
	<div class="container flexr">
		<div class="booking-form-container">
            <i-con-booking-form-group
                (bookingFormEmmitter)="createBookingForm($event)"
                (goToStepEmitter)="goToStep($event)"
                [btnText] = "'Reserve'"
                [confirmationName]="selected_booth_type.name"
                (loggedInEmitter)="loggedInReservation()"
                [errorMessages]="errorMessages"
            ></i-con-booking-form-group>
		</div>
        <div class="order-summary">
            <div class="order-header">
                <p>Order Summary</p>
            </div>
            <div class="order-body">
                <div class="order-item">
                    <div class="left"><p>{{selected_booth_type.name}} Booth</p></div>
                    <div class="right"><p>€{{ selected_booth_type.price | discountedPrice: selected_booth_type.discounts | number }}</p></div>
                </div>
            </div>
            <div class="order-footer">
                <div class="left"><p>Total</p></div>
                <div class="right"><p>€{{ selected_booth_type.price | discountedPrice: selected_booth_type.discounts | number }}</p></div>
            </div>
        </div>
	</div>
</div>
