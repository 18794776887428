<div class="card" [ngClass]="{ yellow: event.columnColor}">

    <div class="first" [ngClass]="{ yellow: event.columnColor}">
        <div *ngIf="event.type" class="pill" ngClass="{{event.typeColor}}"><span class="type">{{event.type}}</span>
        </div>
        <span class="title">{{event.title}}</span>
        <p class="description" [innerHTML]="event.description"></p>
        <div class="sponsored">
            <span class="sponsoredText" *ngIf="event.sponsored">Sponsored by </span>
            <img *ngIf="event.sponsoredByImgUrl" src="{{event.sponsoredByImgUrl}}" alt="{{event.sponsoredByImgAlt}}">
            <ng-container *ngIf="event.sponsors">
                <div *ngFor="let sponsor of event.sponsors;"><img src="{{sponsor.logo}}" alt="{{sponsor.name}}"></div>
            </ng-container>
        </div>
    </div>

    <div class="second" [ngClass]="{ yellow: event.columnColor}">
        <div *ngIf="event.areaText" class="area">
            <span class="areaText">{{event.areaText}}</span>
            <span class="areaLocation" *ngIf="event.areaLocation">{{event.areaLocation}}</span>
        </div>

        <div *ngIf="event.areaImg" class="area">
            <img src="{{event.areaImgUrl}}" alt="{{event.areaImgAlt}}">
        </div>

        <div *ngIf="event.spotBtn" class="area button">
            <button class="main-btn vip"
                (click)="openGuestListDialog('Spiritual Journey - Detox', 'spiritual', '')">
                Reserve Your Spot
            </button>
        </div>
        <div *ngIf="event.guestListBtn" class="area button">
            <a class="external_link" href="{{event.externalformUrl}}" target="_blank">Guest list</a>
        </div>
        <div *ngIf="event.areaBtn" class="area button">
            <button class="main-btn" *ngIf="event.guestlistEnabled" (click)="openGuestListDialog(event.formText!, event.formClass!, '')">Add your crew on the Guestlist</button>
            <button class="main-btn vip" *ngIf="event.vipEnabled"  (click)="openVIPReserveDialog(event.formText!, event.formClass!)">Reserve Your VIP Table</button>
        </div>
    </div>

    <div class="third" [ngClass]="{ yellow: event.columnColor, alphabetic: event.timeIsAlphabetic}">
        <span class="time">{{event.time}}</span>
        <img src="https://cdn-images.island-conference.com/meetups/workshops/clock-dark.svg" alt="Time">
    </div>
</div>