<div class="plan-schedule" id="home">
	<app-pageheader [heading_class]="class_name" [heading_phrase]="phrase" [heading_title1]="title1"></app-pageheader>

	<div class="container">
		<div class="dropdown-wrapper">
			<mat-form-field class="mat-form-field scheduleDrop" appearance="fill">
				<mat-select #selector (selectionChange)="onSelectionChange($event.value)" [(value)]="selected"
					panelClass="select-dropdown">
					<mat-option value="events">Events</mat-option>
					<mat-option value="speakers">Speakers</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>


	<ng-container *ngIf="selected === 'events'">
		<div class="container">
			<div class="content" *ngFor="let day of data">
				<div class="heading">
					<span class="day">{{day.day}}</span>
					<span class="date">{{day.date}}</span>
				</div>
				<schedule-card class="card" *ngFor="let event of day.events" [event]="event"></schedule-card>
			</div>
		</div>

		<div class="booths" *ngIf="boothData$ | async as booths">
			<div class="container" *ngIf="booths.length > 0">
				<h2>Our Booths Are <br><strong>OUT OF THIS WORLD</strong></h2>
				<div class="boothsContent">
					<booths-section *ngFor="let booth of booths" [booth]="booth"></booths-section>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="selected === 'speakers'">
		<div class="container">
			<div class="content" *ngFor="let day of data">
				<ng-container *ngIf="day.speeches">
					<div class="heading">
						<span class="day">{{day.day}}</span>
						<span class="date">{{day.date}}</span>
					</div>
					<speech-card class="card" *ngFor="let speech of day.speeches" [event]="speech"></speech-card>
				</ng-container>
			</div>
		</div>
	</ng-container>


	<!-- <div class="speakers" *ngIf="selected === 'speakers'">
		<div class="container">
			<div class="content">
				<div class="title">
					<h2>We Are Busy Preparing An Exciting Line-Up Of Speakers For 2024!</h2>
				</div>
				<div class="image">
					<img src="https://cdn-images.island-conference.com/plan/speakers/blurred-bg.png" alt="">

					<div class="spinner">
						<loading-spinner></loading-spinner>
						<img src="https://cdn-images.island-conference.com/plan/speakers/icon-logo.svg" alt="i-Con"
							class="logo">
					</div>
				</div>
				<div class="release">
					<span>Be Sure To Keep An Eye Out For The Release Coming Soon!</span>
				</div>
			</div>
		</div>
	</div> -->

</div>

<!-- <app-not-found *ngIf="!validParam"></app-not-found> -->