import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs';
import { IWinningPitchTypeResponse } from '../../../core/_models/responses/winning-pitch-type-response.interface';
import { IWinningPitch } from '../../../core/_models/winning-pitch.interface';
import { WinningPitchService } from '../../../core/_services/attend/winning-pitch/winning-pitch.service';
import { IFormData, IWinningPitchState } from './interfaces/startup-pitch-form.interface';
import { StateService } from '@i-con/shared/common';

const initialState: IWinningPitchState = {
  success: false,
  return_msg: '',
};

@Component({
  selector: 'i-con.app-startup-pitch-form',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  templateUrl: './startup-pitch-form.component.html',
  styleUrls: ['./startup-pitch-form.component.scss'],
})
export class StartupPitchFormComponent extends StateService<IWinningPitchState> implements OnInit {
  valuesList: string[] = ['Online App', 'Fintech Solution', 'AI Solution', 'Cyber Security Tools', 'Other Tech Solutions'];

  submitted = false;
  winningPitchForm: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public fd: IFormData,
    public dialogRef: MatDialogRef<StartupPitchFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private winningPitchService: WinningPitchService
  ) {
    super(initialState);
  }

  postData$: Observable<IWinningPitchTypeResponse>;
  responseState$: Observable<IWinningPitchState>;

  get f() {
    return this.winningPitchForm.controls;
  }

  ngOnInit(): void {
    this.postData$ = this.winningPitchService.postData$;
    this.responseState$ = this.getState(state => state);

    this.valuesList.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

    this.winningPitchForm = this.formBuilder.group({
      fullnameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      emailControl: ['', [Validators.required, Validators.email, Validators.minLength(6), Validators.maxLength(255)]],
      industryControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      topicsControl: ['', [Validators.required]],
      descriptionControl: ['', [Validators.required, Validators.minLength(100), Validators.maxLength(2000)]],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.winningPitchForm.invalid) {
      return;
    }

    const winningPitchObject: IWinningPitch = {
      fullname: this.winningPitchForm.value.fullnameControl,
      email: this.winningPitchForm.value.emailControl,
      industry: this.winningPitchForm.value.industryControl,
      topics: this.winningPitchForm.value.topicsControl,
      description: this.winningPitchForm.value.descriptionControl,
    };

    this.winningPitchService.winningPitchService(winningPitchObject);

    this.postData$.subscribe(res => {
      if (res.response?.statusCode == 201) {
        this.setState({
          success: true,
          return_msg: '',
        });
      } else {
        this.setState({
          success: false,
          return_msg: res.response?.message,
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
