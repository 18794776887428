
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input } from '@angular/core';
import { EventDialogFormComponent } from '../event-dialog-form/event-dialog-form.component';
import { VipTableFormComponent } from './vip-table-form/vip-table-form.component';
import { GuestListFormComponent } from './guest-list-form/guest-list-form.component';

import {dialogData} from '../plan-speakers/data/sdialog-data';  
import { SpeakersDynamicDialogComponent } from '../../shared/components/speakers-dynamic-dialog/speakers-dynamic-dialog.component';

@Component({
    selector: 'app-plan',
    templateUrl: './plan.component.html',
    styleUrls: ['./plan.component.scss']
})
export class PlanComponent  {

    @Input() class_name: string = 'plan_page';
    @Input() phrase: string = 'We are the masterminds behind';
    @Input() title1: string = 'THIS PLAN';
    @Input() title2: string = '';

    public dialogData: any = dialogData;

    constructor(public dialog: MatDialog,) { }


    playVid(video_id: string) {
        const myVideo: any = document.getElementById(video_id);
        myVideo.muted = true;
        myVideo.controls = false;
        myVideo.play();
    }

    pauseVid(video_id: string) {
       const myVideo: any = document.getElementById(video_id);
        myVideo.pause();
    }

    openReserveDialog(header_title: string) {
      event?.preventDefault();
		  this.dialog.open(EventDialogFormComponent, {
			  maxHeight: '80vh',
        maxWidth: '95vw',
        width: '850px',
			  autoFocus: false,
        data: header_title,
        disableClose: true
		  });
    }

    openVIPReserveDialog(header_title: string, event_class: string) {
      event?.preventDefault();
		  this.dialog.open(VipTableFormComponent, {
			  maxHeight: '80vh',
        maxWidth: '95vw',
        width: '850px',
			  autoFocus: false,
        data: {header_title, event_class},
        disableClose: true
		  });
    }

    openGuestListDialog(header_title: string, event_class: string, sponsor: string) {
      event?.preventDefault();
		  this.dialog.open(GuestListFormComponent, {
			  maxHeight: '80vh',
        maxWidth: '95vw',
        width: '850px',
			  autoFocus: false,
        data: {header_title, event_class, sponsor},
        disableClose: true
		  });
    }

    openDialog(index:any) {
      if (this.dialogData[index].speech_desc !== 'NA') {
        this.dialog.open(SpeakersDynamicDialogComponent,
          {
            data: this.dialogData[index],
            panelClass: 'custom-speakermodalbox' 
          });
      } else {
        return;
      }
    }

}
