import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ITicketType } from '../../core/_models';
import { AttendeesStateService } from '../../core/_services/attendees/attendees-state.service';
import { TicketBundleStateService } from '../../core/_services/tickets/ticket-bundle-state.service';
import { TicketBundleService } from '../../core/_services/tickets/ticket-bundle.service';
import { TicketStateService } from '../../core/_services/tickets/ticket-state.service';
import { TicketService } from '../../core/_services/tickets/ticket.service';
import { IVertical } from '../../core/_services/tickets/vertical-state.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
// declare let window: any;

export interface ICardholder {
  fullname: string;
}

export interface IContact {
  name: string;
  surname: string;
  email: string;
  termsAgreement: boolean;
  optinConsent: boolean;
}

export interface IBilling {
  address: string;
  country: string;
  postalCode: string;
  companyName: string;
  vatNumber: string;
}
export interface ITicketTypesReservation {
  ticketTypes: Array<ITicketTypeReservation>;
}

export interface ITicketTypeReservation {
  ticketTypeId: number;
  quantity: number;
}

export interface ITicketTypePayableReservation {
  ticketTypeReservation: ITicketTypeReservation;
}

export interface IAttendee {
  name: string;
  surname: string;
  email: string;
  companyName?: string | null;
  verticals: IVertical[];
  dialCode: string;
  phoneNumber: string;
  country: string;
  position?: string | null;
  entity: string;
  occupation?: string | null;
}

export interface ISecurionTransaction {
  tokenId: string;
  requireEnrolledCard: boolean;
  requireSuccessfulLiabilityShift: boolean;
}

@Component({
  selector: 'app-attend-tickets',
  templateUrl: './attend-tickets.component.html',
  styleUrls: ['./attend-tickets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendTicketsComponent implements OnInit {
  class_name = 'attend-tickets';
  phrase = `Don't Forget Your`;
  title1 = `CONFERENCE TICKETS!`;
  tickets: ITicketType[];
  ticket_types$: Observable<ITicketType[]>;

  discountEnd: boolean = false;

  // ticket_types: ITicketType[] = [];

  // bundle_types$: Observable<ITicketBundleType[]>;
  // bundle_types: ITicketBundleType[] = [];

  // current_step: number = 1;
  // attendees$: Observable<IAttendee[]>;
  // selected_ticket_type: ITicketType = {
  // 	id: 1,
  // 	description: '',
  // 	price: 0,
  // 	discount: 0,
  // 	doorPrice: 0,
  // 	discountEndsOn: new Date(),
  // 	includes: [{ content: '', iconUrl: '' }],
  // 	inStock: true,
  // };

  // selected_bundle_type: ITicketBundleType = {
  // 	id: 1,
  // 	name: '',
  // 	label: '',
  // 	description: '',
  // 	ticketQuantity: 0,
  // 	price: 0,
  // 	unitDiscount: 0,
  // 	ticket: {
  // 		id: 3,
  // 		description: '',
  // 		price: 0,
  // 		discount: 0,
  // 		doorPrice: 0,
  // 		discountEndsOn: new Date(),
  // 		includes: [{ content: '', iconUrl: '' }],
  // 		inStock: true,
  // 		quantity: 0
  // 	},
  // 	inStock: true,
  // 	gradientStart: '',
  // 	gradientEnd: '',
  // 	metadata: '',
  // 	imageFilename: ''
  // };

  // ticketTypesReservation: ITicketTypesReservation;
  // buyer: IBuyer;
  // attendees: IAttendee[];
  // securionTransaction: ISecurionTransaction;
  // successfulTransaction: boolean = false;
  // @Input() class_name: string = 'attendtickets_page';
  // confettiInterval: any;
  constructor(
    private readonly ticketService: TicketService,
    private readonly ticketStateService: TicketStateService,
    private readonly ticketBundleService: TicketBundleService,
    private readonly ticketBundleStateService: TicketBundleStateService,
    private readonly attendeesStateService: AttendeesStateService
  ) {}

  ngOnInit(): void {
    // this.attendees$ = this.attendeesStateService.attendeess$;
    // this.bundle_types$ = this.ticketBundleStateService.bundle_types$;

    this.ticket_types$ = this.ticketStateService.ticket_types$;
    this.ticket_types$.subscribe(resp => {
      this.tickets = resp;

      if (this.tickets[0]) {
      
        const discountEndDate = new Date(this.tickets[0].discounts[0].endsOn);
        const currentDate = new Date();

        if (currentDate > discountEndDate) {
          this.discountEnd = true;
        }
      }

      //this.tickets[0].includes = this.tickets[1].includes;
    });
    
    // this.bundle_types$.subscribe((bundle_types) => {
    // 	this.bundle_types = bundle_types;
    // });

    // this.ticket_types$.pipe().subscribe((ticket_types) => {
    // 	this.ticket_types = ticket_types;
    // });

    // this.attendees$.subscribe((attendees) => {
    // 	this.attendees = attendees;
    // });

    // this.ticketService.getTickets().subscribe((res: ITicketTypeResponse) => {
    //   this.tickets = res.response.data;
    //   console.log('this tickets', this.tickets);

    //   this.setState({ ticket_types: res.response.data });
    //   this.transferState.set(TRST_TICKETS, res.response.data as any);
    // });
  }

  formatDate(dateObj: Date): string {
    const date = new Date(dateObj);
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const nthNumber = (number: number) => {
      if (number > 3 && number < 21) return 'th';
      switch (number % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    return `${day}${nthNumber(day)} of ${month} ${year}`;
  }

  @ViewChild("slickModalTickets") slickModalTickets: SlickCarouselComponent;
  ticketsSlideConfig = {
    "slidesToShow": 1,
    // "slidesToScroll": 1,
    // "dots": false,
    //"infinite": false,
    "centerMode": true,
    "centerPadding": '0px',
    "cssEase": 'linear',
    "variableWidth": true,
    "variableHeight": true,
    "draggable": true,
    "initialSlide": 2,
    "focusOnSelect": true,
    "prevArrow": '<div class="ts-prev-arrow"><img class="prev-arrow" src="/assets/icons/chevron-left-white.svg"></div>',
    "nextArrow": '<div class="ts-next-arrow"><img class="next-arrow" src="/assets/icons/chevron-right-white.svg"></div>',
    "responsive": [
      {
        breakpoint: 1551,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
          variableWidth: true,
          variableHeight: true,
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
          variableWidth: true,
          variableHeight: true,
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '0px',
          variableWidth: true,
          variableHeight: true,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          variableWidth: false,
          variableHeight: false,
        }
      },
    ]
  };

  // goToStep(step: number): void {
  // 	this.current_step = step;
  // }

  // goToEmmitedStep($event: any): void {
  // 	const { step } = $event;
  // 	this.current_step = step;
  // }

  // setTicketType({ ticket_id }: { ticket_id: number }): void {
  // 	for (let i = 0; i < this.ticket_types.length; i++) {
  // 		if (this.ticket_types[i].id === ticket_id) {
  // 			this.selected_ticket_type = this.ticket_types[i];
  // 		}
  // 	}

  // 	this.ticketStateService.setSelectedTicketType(this.selected_ticket_type);
  // 	/*window?.gtag('event', 'page_view', {
  // 		page_title: 'Purchase i-Con Tickets, Island Conference, Select Ticket Quantity |',
  // 		page_location: 'https://islandconference.com/attend/tickets/purchase',
  // 		send_to: 'G-B8XPWS7NB5',
  // 	});
  // 	//this.goToStep(2);
  // 	*/
  // }

  // setBundleType({ bundle_id }: { bundle_id: number }): void {
  // 	for (let i = 0; i < this.bundle_types.length; i++) {
  // 		if (this.bundle_types[i].id === bundle_id) {
  // 			this.selected_bundle_type = this.bundle_types[i];
  // 		}
  // 	}

  // 	this.ticketBundleStateService.setSelectedBundleType(this.selected_bundle_type);
  // 	this.setTicketType({ticket_id: this.selected_bundle_type.ticket.id});
  // 	this.setTicketQuantity({ticketTypeId:this.selected_bundle_type.ticket.id, quantity: this.selected_bundle_type.ticketQuantity });
  // 	 window?.gtag('event', 'page_view', {
  // 	 	page_title: 'Purchase i-Con Tickets, Island Conference, Select Ticket Quantity |',
  // 	 	page_location: 'https://islandconference.com/attend/tickets/purchase',
  // 	 	send_to: 'G-B8XPWS7NB5',
  // 	 });
  // 	this.goToStep(3);
  // }

  // setTicketQuantity({ ticketTypeId, quantity }: ITicketTypeReservation) {
  // 	this.ticketTypesReservation = {
  // 		ticketTypes: [{ ticketTypeId: ticketTypeId, quantity: quantity }],
  // 	};
  // }

  // setBuyer({ buyer }: { buyer: IBuyer }) {
  // 	this.buyer = buyer;
  // }

  // setSecurionPayment({ securionTransaction }: { securionTransaction: ISecurionTransaction }) {
  // 	this.securionTransaction = securionTransaction;
  // 	if (!this.isEmpty(this.securionTransaction)) {
  // 		this.reserveTickets();
  // 	}
  // }

  // initializeAttendees(quantity: number) {
  // 	this.attendees = new Array<IAttendee>(quantity);
  // }

  // isEmpty(val: any) {
  // 	return Object.values(val).every((prop) => prop == null);
  // }

  // private reserveTickets() {
  // 	let request: {
  // 		reservation?: ITicketTypesReservation;
  // 		transaction?: ISecurionTransaction;
  // 		attendees?: IAttendee[];
  // 		buyer?: IBuyer;
  // 	} = {
  // 		reservation: this.ticketTypesReservation,
  // 		transaction: this.securionTransaction,
  // 		attendees: this.attendees,
  // 		buyer: this.buyer,
  // 	};
  // 	this.ticketService.reserveTickets(request);
  // 	this.ticketService.postData$.pipe().subscribe((res) => {
  // 		if (res.status === 201) {
  // 			if (res.response.code === 100 && res.response.data != null) {
  // 				this.successfulTransaction = true;
  // 				this.activateConfetti();
  // 				window?.gtag('event', 'conversion', {
  // 					send_to: 'AW-10940770656/I6jeCPy6zoYYEODS--Ao',
  // 				});
  // 				window?.lintrk('track', { conversion_id: 12195850 });
  // 				window?.fbq('trackCustom', 'TicketsPurchase', { currency: "EUR", value: this.selected_bundle_type.ticketQuantity * this.selected_bundle_type.ticket.price * (1-this.selected_bundle_type.unitDiscount)});
  // 			}
  // 		} else {
  // 			this.successfulTransaction = false;
  // 		}
  // 	});

  // 	return true;
  // }

  // private randomInRange(min: number, max: number): number {
  // 	return Math.random() * (max - min) + min;
  // }

  // activateConfetti() {
  // 	let duration = 3.5 * 1000;
  // 	let animationEnd = Date.now() + duration;
  // 	let defaults = { startVelocity: 15, spread: 360, ticks: 45, zIndex: 0 };
  // 	this.confettiInterval = setInterval(() => {
  // 		let timeLeft = animationEnd - Date.now();

  // 		if (timeLeft <= 0) {
  // 			return clearInterval(this.confettiInterval);
  // 		}

  // 		var particleCount = 50 * (timeLeft / duration);
  // 		// since particles fall down, start a bit higher than random
  // 		confetti(Object.assign({}, defaults, { particleCount, origin: { x: this.randomInRange(0.05, 0.1), y: Math.random() - 0.2 } }));
  // 		confetti(Object.assign({}, defaults, { particleCount, origin: { x: this.randomInRange(0.85, 0.95), y: Math.random() - 0.2 } }));
  // 	}, 250)
  // }
}
