import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { ITicketTypeResponse } from '../../_models/responses/ticket-type-response.interface';
import { ITicketType } from '../../_models/ticket.interface';

import { EnvironmentService, StateService } from '@i-con/shared/common';

const TRST_INVENTORY_TICKETS = makeStateKey('inventorytickets');

interface ITicketState {
	data: ITicketType[];
}

const initialStateValue: any = {
	data: [{}],
};

@Injectable({ providedIn: 'root' })
export class TicketService extends StateService<ITicketState> {
	data$: Observable<ITicketType[]> = this.getState((state) => state.data);

	constructor(private http: HttpClient,
		private transferState: TransferState,
		private _environment: EnvironmentService) {
		super(initialStateValue);
		this.getTickets();
	}

	getTickets(): void {
		let state_data = this.transferState.get(TRST_INVENTORY_TICKETS, null as any);
		if (state_data) {
			this.setState({ data: state_data });
		}
		else {
			this.http.get<ITicketTypeResponse>(`${this._environment.getApiUrl}/inventory/ticket`).subscribe((res) => {
				this.setState({ data: res.response.data });
				this.transferState.set(TRST_INVENTORY_TICKETS, res.response.data as any);
			});
		}
	}
}
