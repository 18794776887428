<div class="floor-page-wrapper">
    <div class="top-header">
        <div class="content">
            <h3>Check out our</h3>
            <h1>EXPO'S FLOOR PLAN</h1>
        </div>
    </div>

    <div class="floorplan_page">
        <hr />
        <div class="tabs">
            <div class="tabs-wrapper">
                <mat-tab-group class="mapsTabsGroup">
                    <mat-tab label="Map Overview">
                        <i-con-tab-content image="map_overview"></i-con-tab-content>
                    </mat-tab>
                    <mat-tab label="3D Map">
                        <i-con-tab-content image="3D_map"></i-con-tab-content>
                    </mat-tab>
                    <mat-tab label="City Map">
                        <i-con-tab-content image="city_map"></i-con-tab-content>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>