import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { VisionRecapAlbumCardSectionComponent } from './components/vision-recap-album-card-section/vision-recap-album-card-section.component';
import { VisionRecapAlbumCardComponent } from './components/vision-recap-album-card/vision-recap-album-card.component';
import { contentData } from './data/vision-recap-album-data';
import { videosData } from './data/vision-recap-videos-data';
import { IAfterMovie, IImageExpandedData, IVisionRecapAlbumCardContent } from './interfaces/vision-recap-album.interface';
import { afterMovieData } from './data/vision-recap-aftermovies-data';
import { MatDialog } from '@angular/material/dialog';
import { VisionRecapAlbumGalleryImageExpandedComponent } from './vision-recap-album-gallery/vision-recap-album-gallery-image-expanded/vision-recap-album-gallery-image-expanded.component';

@Component({
  selector: 'i-con-vision-recap-album',
  standalone: true,
  imports: [MatTabsModule, CommonModule, SharedModule, VisionRecapAlbumCardSectionComponent, VisionRecapAlbumCardComponent],
  templateUrl: './vision-recap-album.component.html',
  styleUrl: './vision-recap-album.component.scss',
})
export class VisionRecapAlbumComponent {
  contentData: IVisionRecapAlbumCardContent[] = contentData;
  videosData: IVisionRecapAlbumCardContent[] = videosData;
  tabIndex = 0;
  tabs: string[] = ['photo-albums', 'aftermovies'];
  tabValid = false;
  safeUrls: SafeResourceUrl[] = [];

  afterMovieData: IAfterMovie[] = afterMovieData;
  afterMovieSafeUrl: SafeResourceUrl[] = [];

  data: IImageExpandedData;

  constructor(private route: ActivatedRoute, private router: Router, private _sanitizer: DomSanitizer,
    private dialog: MatDialog) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const param = params.get('tab')?.toLowerCase();
      const index = this.tabs.indexOf(param!);
      if (index !== -1) {
        this.tabValid = true;
        this.tabIndex = index;
      }
    });

    this.videosData.forEach(item => this.safeUrls.push(this._sanitizer.bypassSecurityTrustResourceUrl(item.youtubeUrl!)));
    this.afterMovieData.forEach(item => {
      if (item.url !== undefined) {
        this.afterMovieSafeUrl.push(this._sanitizer.bypassSecurityTrustResourceUrl(item.url));
      }
    });
  }

  openImage(index: number, type: number){
    
    this.data = {
      gallery: {
        header: "",
        title: "",
        description: "",
        images: [{src:""}]
      },
      aftermovie: afterMovieData[0],
      type: type,
      index: index,
      safeUrl: this.afterMovieSafeUrl[index]
    }

    this.dialog.open(VisionRecapAlbumGalleryImageExpandedComponent, {
      maxWidth: '95vw',
      height: 'auto',
      maxHeight: '100vh',
      data: this.data
    });
  }

  tabChange() {
    this.route.paramMap.subscribe(() => {
      this.router.navigate([`vision/icon-recap/albums/${this.tabs[this.tabIndex]}`]);
    });
  }
}
