import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GuestListFormComponent } from '../../plan/guest-list-form/guest-list-form.component';
import { VipTableFormComponent } from '../../plan/vip-table-form/vip-table-form.component';
import { ISpeech } from '../interfaces/events-data.interface';

@Component({
  selector: 'speech-card',
  templateUrl: './speech-card.component.html',
  styleUrl: './speech-card.component.scss',
})
export class SpeechCardComponent {
  @Input() event: ISpeech;

  constructor(public dialog: MatDialog) {}
  openVIPReserveDialog(header_title: string, event_class: string) {
    event?.preventDefault();
    const dialogRef = this.dialog.open(VipTableFormComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      width: '850px',
      autoFocus: false,
      data: { header_title, event_class },
      disableClose: true,
    });
  }

  openGuestListDialog(header_title: string, event_class: string, sponsor: string) {
    event?.preventDefault();
    const dialogRef = this.dialog.open(GuestListFormComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      width: '850px',
      autoFocus: false,
      data: { header_title, event_class, sponsor },
      disableClose: true,
    });
  }

}
