import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IFormData } from '../../shared/components/startup-pitch-form/interfaces/startup-pitch-form.interface';
import { StartupPitchFormComponent } from '../../shared/components/startup-pitch-form/startup-pitch-form.component';
import { cardsData } from './data/winning-pitch-data';
import { formData } from './data/winning-pitch-form-data';
import { ICard } from './interfaces/winning-pitch.interface';

@Component({
  selector: 'winning-pitch',
  templateUrl: './winning-pitch.component.html',
  styleUrls: ['./winning-pitch.component.scss'],
})
export class WinningPitchComponent {
  class_name = 'winning-pitch';
  phrase = 'EXPLORING i-CON 2024';
  title1 = 'WINNING PITCH DECK';
  fd: IFormData = formData;
  cards: ICard[] = cardsData;
  constructor(public dialog: MatDialog) {}

  openFormDialog() {
    event?.preventDefault();
    const dialogRef = this.dialog.open(StartupPitchFormComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      height: '665px',
      width: '875px',
      autoFocus: true,
      disableClose: false,
      panelClass: 'paddless',
      data: this.fd,
    });
  }
}
