<div class="form-container" *ngIf="responseState$ | async as responseState">
	<div class="top {{event_class}}">
		<div class="bg-overlay"></div>
		<img class="logo" src="https://cdn-images.island-conference.com/pageheader/header_logo.png" />
		<div class="header">
			<h3>{{ header_title }}</h3>
		</div>
		<button class="close-btn" mat-button mat-dialog-close>
			<img src="https://cdn-images.island-conference.com/icons/close-icon.svg" alt="Close">
		</button>
	</div>
	<div class="bottom">
		<div class="header" *ngIf="!responseState.success">
			<div class="viptable-bgr"></div>
			<h4><span><img src="https://cdn-images.island-conference.com/attend/tickets/info-icon.svg" /></span>
				Important Information:</h4>
			<p>Reserving a <strong>VIP Table</strong> guarantees you and your crew 100% access to the Event Venue with
				an upgraded VIP Table in a Premier Location, <strong>Maximizing your Experience!</strong></p>
			<span><img
					src="https://cdn-images.island-conference.com/sponsorships/header_imgs/sponsorship_img_5.svg" /></span>
			<p>Our VIP Tables have Bottle Service with only <strong>Premium Drinks</strong>, at a Min. Spend for 8 - 12
				people, depending on the Reservation Type.<br /><br />Following your request, a Member from the
				<strong>Haze Team</strong> will Approach you for the Final Confirmation & the Payment Details.</p>
			<p class="form-hashtags">#OutOfThisWorldEvents #QualityMatters</p>
		</div>
		<div class="success-msg flexr" *ngIf="responseState.success">
			<div class="left">
				<h2>Thank you for your reservation!</h2>
				<p>
					A member of our team will be in touch with you shortly. Please be patient as we get back you.
					<br />
					We would advise on checking your spam folder if you have not received a reply from us within 24
					hours.
				</p>
			</div>
			<div class="right">
				<img class="success" src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
			</div>
		</div>
		<div class="body" *ngIf="!responseState.success">
			<h3>Reserve a <strong>VIP Table</strong></h3>
			<hr />
			<p class="header-error" *ngFor="let message of responseState.return_msg">
				{{ message }}
			</p>
			<form #test="ngForm" class="form" [formGroup]="reserveTableForm" (ngSubmit)="onSubmit()">
				<div class="form-row flexr">
					<div class="field-container name-field">
						<input class="name-field" type="text" placeholder="Name" formControlName="nameControl" />
						<div *ngIf="submitted && f['nameControl'].errors" class="invalid-feedback">
							<div *ngIf="f['nameControl'].errors['required']">Name is required</div>
							<div *ngIf="f['nameControl'].errors['minlength']">
								A minimum of {{ f["nameControl"].errors["minlength"].requiredLength }} characters length
								is required
							</div>
						</div>
					</div>
					<div class="field-container surname-field">
						<input class="name-field" type="text" placeholder="Surname" formControlName="surnameControl" />
						<div *ngIf="submitted && f['surnameControl'].errors" class="invalid-feedback">
							<div *ngIf="f['surnameControl'].errors['required']">Surname is required</div>
							<div *ngIf="f['surnameControl'].errors['minlength']">
								A minimum of {{ f["surnameControl"].errors["minlength"].requiredLength }} characters
								length is required
							</div>
						</div>
					</div>
				</div>
				<div class="form-row flexr">
					<div class="field-container phone-field">
						<!-- <input type="tel" formControlName="phoneControl" class="form-control" id="phone" name="phone" placeholder="Phone Number" autocomplete="off"  /> -->
						<ngx-intl-tel-input [cssClass]="'phone-input'" [enablePlaceholder]="true"
							[searchCountryFlag]="true" [selectedCountryISO]="selected_countries.Cyprus"
							[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
							[enableAutoCountrySelect]="true" [maxLength]="15" [phoneValidation]="true"
							[separateDialCode]="true" name="phone" formControlName="phoneControl">
						</ngx-intl-tel-input>
						<div *ngIf="submitted && f['phoneControl'].errors" class="invalid-feedback">
							<div *ngIf="f['phoneControl'].errors['required']">Phone is required</div>
							<div *ngIf="f['phoneControl'].errors && !f['phoneControl'].errors['required']">
								Invalid Input
							</div>
						</div>
					</div>
					<div class="field-container type">
						<mat-form-field class="mat-form-field dropdown-reservation" appearance="fill">
							<mat-label>Reservation Type</mat-label>
							<mat-select formControlName="typeControl" panelClass="select-dropdown"
								*ngIf="event_class === 'opening'">
								<mat-option [value]="'VIP Min. Spend €800'" disabled>
									<span>VIP 8-12pax</span>&nbsp;
									<span>Min. Spend: &euro;800</span>
								</mat-option>
								<mat-option [value]="'Semi VIP Min. Spend €500'">
									<span>Semi VIP 8-12pax</span>&nbsp;
									<span>Min. Spend: &euro;500</span>
								</mat-option>
								<mat-option [value]="'Normal Min. Spend €300'">
									<span>Normal 5-10pax</span>&nbsp;
									<span>Min. Spend: &euro;300</span>
								</mat-option>
							</mat-select>

							<mat-select formControlName="typeControl" panelClass="select-dropdown"
								*ngIf="event_class === 'closing'">
								<mat-option [value]="'VIP Min. Spend €500'" disabled>
									<span>VIP 8-12pax</span>&nbsp;
									<span>Min. Spend: &euro;500</span>
								</mat-option>
								<mat-option [value]="'Semi VIP Min. Spend €300'" disabled>
									<span>Semi VIP 8-12pax</span>&nbsp;
									<span>Min. Spend: &euro;300</span>
								</mat-option>
								<mat-option [value]="'Normal Min. Spend €150'">
									<span>Normal 5-10pax</span>&nbsp;
									<span>Min. Spend: &euro;150</span>
								</mat-option>
							</mat-select>

							<mat-select formControlName="typeControl" panelClass="select-dropdown"
								*ngIf="event_class === 'pre-event'">
								<mat-option [value]="'VIP Min. Spend €500'">
									<span>VIP 8-12pax</span>&nbsp;
									<span>Min. Spend: &euro;500</span>
								</mat-option>
								<mat-option [value]="'Semi VIP Min. Spend €300'">
									<span>Semi VIP 8-12pax</span>&nbsp;
									<span>Min. Spend: &euro;300</span>
								</mat-option>
							</mat-select>
						</mat-form-field>
						<div *ngIf="submitted && f['typeControl'].errors" class="invalid-feedback">
							<div *ngIf="f['typeControl'].errors['required']">Type is required</div>
						</div>
					</div>
				</div>
				<div class="form-row flexr">
					<div class="field-container email-field">
						<input class="email-field" type="text" placeholder="Email Address"
							formControlName="emailControl" />
						<div *ngIf="submitted && f['emailControl'].errors" class="invalid-feedback">
							<div *ngIf="f['emailControl'].errors['required']">Email is required</div>
							<div *ngIf="f['emailControl'].errors['email']">Please write a valid email</div>
						</div>
					</div>
					<div class="field-container company-field">
						<input class="company-field" type="text" placeholder="Company Name"
							formControlName="companyControl" />
						<div *ngIf="submitted && f['companyControl'].errors" class="invalid-feedback">
							<div *ngIf="f['companyControl'].errors['required']">Company Name is required</div>
						</div>
					</div>
				</div>
				<button class="main-btn" type="submit">Submit Request</button>
			</form>
		</div>
	</div>
</div>