import { Component, Inject, OnInit } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers } from '../../interface/questionnaire.interface';
import { StepService } from '../../service/step.service';
import { UserAnswersService } from '../../service/userAnswers.service';
import { IMPROVEMENTS_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-improvements',
  templateUrl: './improvements.component.html',
  styleUrl: './improvements.component.scss',
})
export class ImprovementsComponent implements OnInit {
  selectedItem: IQuestionnaireUserAnswers;
  textAreaValue: string = '';
  maxlength: number = 500;

  constructor(@Inject(IMPROVEMENTS_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService, private stepService: StepService) {}
  ngOnInit(): void {
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: this.content.surveyAnswer[0].id,
          value: [this.textAreaValue],
          description: this.textAreaValue,
        },
      ],
    };
  }

  nextClick() {
    if (this.selectedItem && this.textAreaValue.length <= this.maxlength) {
      this.selectedItem.answers[0].value = [this.textAreaValue];
      this.selectedItem.answers[0].description = this.textAreaValue;
      this.userAnswersService.pushNewAnswer(this.selectedItem);
    }
  }
}
