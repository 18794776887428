import { ISpeakerDialogData } from '../interfaces/dialog-data.interface';

export const dialogData: ISpeakerDialogData[] = [
  {
    id: 1,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/salesforce-karen.png',
    profile_pic_alt: 'Karen De Sousa Pesse speaker profile photo',
    max_width: null,
    name: 'Karen',
    surname: 'De Sousa Pesse',
    position: 'Senior Executive',
    company: 'Salesforce',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/salesforce-logo.svg',
    company_logo_alt: 'Salesforce company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 2,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/google-konstantinos.png',
    profile_pic_alt: 'Konstantinos Papadopoulos speaker profile photo',
    max_width: null,
    name: 'Konstantinos',
    surname: 'Papadopoulos',
    position: 'Ad Specialist',
    company: 'Google',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/google-logo.svg',
    company_logo_alt: 'Google company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 3,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/sarahfurness-speaker.png',
    profile_pic_alt: 'Sarah Furness speaker profile photo',
    max_width: null,
    name: 'Sarah',
    surname: 'Furness',
    position: 'Founder',
    company: 'SarahFurness.com',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/sarahfurness-logo.jpg',
    company_logo_alt: 'SarahFurness.com company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 4,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/adsterra-gala.png',
    profile_pic_alt: 'Gala Grigoreva speaker profile photo',
    max_width: null,
    name: 'Gala',
    surname: 'Grigoreva',
    position: 'CMO',
    company: 'Adsterra',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/adsterra-logo-white.svg',
    company_logo_alt: 'Adsterra company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 5,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/chilli-fruit-milosz.png',
    profile_pic_alt: 'Milosz Krasinski speaker profile photo',
    max_width: null,
    name: 'Milosz',
    surname: 'Krasinski',
    position: 'CEO',
    company: 'Chillifruit',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/chilli-fruit-logo.svg',
    company_logo_alt: 'Chillifruit company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 6,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/cladx-alan.png',
    profile_pic_alt: 'Alan Cladx speaker profile photo',
    max_width: null,
    name: 'Alan',
    surname: 'Cladx',
    position: 'Founder',
    company: 'Cladx',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/cladx-logo.svg',
    company_logo_alt: 'Cladx company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 7,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/soft2bet-speaker.png',
    profile_pic_alt: 'Ivana Flynn speaker profile photo',
    max_width: null,
    name: 'Ivana',
    surname: 'Flynn',
    position: 'SEO Director',
    company: 'Soft2Bet',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/soft2bet-logo.svg',
    company_logo_alt: 'Soft2Bet company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 8,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/modelsearcher-andy.png',
    profile_pic_alt: 'Andy Wullmer speaker profile photo',
    max_width: null,
    name: 'Andy',
    surname: 'Wullmer',
    position: 'Traffic Captain',
    company: 'ModelSearcher',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/modelsearcher-logo.png',
    company_logo_alt: 'ModelSearcher company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 9,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/searchbrothers-kaspar.png',
    profile_pic_alt: 'Kaspar Szymanski speaker profile photo',
    max_width: null,
    name: 'Kaspar',
    surname: 'Szymanski',
    position: 'Co-Founder',
    company: 'SearchBrothers',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/searchbrothers-logo.svg',
    company_logo_alt: 'SearchBrothers company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 10,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/voluum-kamila.png',
    profile_pic_alt: 'Kamila Luksza-Szpyt speaker profile photo',
    max_width: null,
    name: 'Kamila',
    surname: 'Luksza-Szpyt',
    position: 'Head of Marketing & Partnerships',
    company: 'Voluum',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/voluum-logo.svg',
    company_logo_alt: 'Voluum company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 11,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/maja-jovancevic-2.png',
    profile_pic_alt: 'Maja Jovančević speaker profile photo',
    max_width: null,
    name: 'Maja',
    surname: 'Jovančević',
    position: 'SEO Director',
    company: 'GiG',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/GiG.png',
    company_logo_alt: 'GiG company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 12,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/artem-butov-2.png',
    profile_pic_alt: 'Artem Butov speaker profile photo',
    max_width: null,
    name: 'Artem',
    surname: 'Butov',
    position: 'Founder and CEO',
    company: 'Marksel',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/marksel-logo-2.png',
    company_logo_alt: 'Marksel company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 13,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Moritz-Von-Arnim.png',
    profile_pic_alt: 'Moritz Von Arnim speaker profile photo',
    max_width: null,
    name: 'Moritz',
    surname: 'Von Arnim',
    position: 'CMO',
    company: 'Traffic Partner',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Traffic-Partner.png',
    company_logo_alt: 'Traffic Partner company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 14,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/nilu-yusupova-4.png',
    profile_pic_alt: 'Nilu Yusupova speaker profile photo',
    max_width: null,
    name: 'Nilu',
    surname: 'Yusupova',
    position: 'CBDO',
    company: 'AdsEmpire',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/AdsEmpire.png',
    company_logo_alt: 'AdsEmpire company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 15,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Jenny-Gonzalez.png',
    profile_pic_alt: 'Jenny Gonzalez speaker profile photo',
    max_width: null,
    name: 'Jenny',
    surname: 'Gonzalez',
    position: 'Co Founder',
    company: 'Rocahead',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Rocahead.png',
    company_logo_alt: 'Rocahead company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 16,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/kyriakos-iacovides.png',
    profile_pic_alt: 'Kyriakos Iacovides speaker profile photo',
    max_width: null,
    name: 'Kyriakos',
    surname: 'Iacovides',
    position: 'General Manager',
    company: 'Great Place to Work®Cyprus',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/GPTW_Logo.svg',
    company_logo_alt: 'Great Place to Work®Cyprus company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 17,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Michael-Reul.png',
    profile_pic_alt: 'Michael Reul speaker profile photo',
    max_width: null,
    name: 'Michael',
    surname: 'Reul',
    position: 'CEO',
    company: 'Traffic Partner',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Traffic-Partner.png',
    company_logo_alt: 'Traffic Partner company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 18,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Charis-Demetriou.png',
    profile_pic_alt: 'Charis Demetriou speaker profile photo',
    max_width: null,
    name: 'Charis',
    surname: 'Demetriou',
    position: 'CEO',
    company: 'Nexxie Group',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Nexxie.png',
    company_logo_alt: 'Nexxie Group company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 19,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Tim-Ram.png',
    profile_pic_alt: 'Tim Ram speaker profile photo',
    max_width: null,
    name: 'Tim',
    surname: 'Ram',
    position: 'CEO',
    company: 'TrafficStars',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Traffic-Stars.png',
    company_logo_alt: 'TrafficStars company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 20,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Peter-Rabenseifner.png',
    profile_pic_alt: 'Peter Rabenseifner speaker profile photo',
    max_width: null,
    name: 'Peter',
    surname: 'Rabenseifner',
    position: 'CEO',
    company: 'TwinRed',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/TwinRed.png',
    company_logo_alt: 'TwinRed company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 21,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/alex-vasekin-2.png',
    profile_pic_alt: 'Alex Vasekin speaker profile photo',
    max_width: null,
    name: 'Alex',
    surname: 'Vasekin',
    position: 'CEO',
    company: 'PropellerAds',
    company_logo: 'https://cdn-images.island-conference.com/exhibitors/logos/propellerads.svg',
    company_logo_alt: 'PropellerAds company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 22,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/matvey-shmidt.png',
    profile_pic_alt: 'Matvey Shmidt speaker profile photo',
    max_width: null,
    name: 'Matvey',
    surname: 'Shmidt',
    position: 'Head of Advertisers',
    company: 'PropellerAds',
    company_logo: 'https://cdn-images.island-conference.com/exhibitors/logos/propellerads.svg',
    company_logo_alt: 'PropellerAds company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 23,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Adrien-Bacchi.png',
    profile_pic_alt: 'Adrien Bacchi speaker profile photo',
    max_width: null,
    name: 'Adrien',
    surname: 'Bacchi',
    position: 'CEO',
    company: 'Affil Gamer',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Affilgamer-logo.png',
    company_logo_alt: 'Affil Gamer company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  // {
  //   id: 24,
  //   profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Andra-Chirnogeanu.png',
  //   profile_pic_alt: 'Andra Chirnogeanu speaker profile photo',
  //   max_width: null,
  //   name: 'Andra',
  //   surname: 'Chirnogeanu',
  //   position: 'Head of Business Development',
  //   company: 'my.club',
  //   company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/myclub-logo.png',
  //   company_logo_alt: 'my.club company logo',
  //   speech_label: null,
  //   speech_title: null,
  //   speech_date: null,
  //   speech_time: null,
  //   speech_venue: null,
  //   speech_desc: null,
  //   bio: null,
  //   facebook: null,
  //   instagram: null,
  //   linkedin: null,
  // },
  {
    id: 25,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Lewis-Rough.png',
    profile_pic_alt: 'Lewis Rough speaker profile photo',
    max_width: null,
    name: 'Lewis',
    surname: 'Rough',
    position: 'Sales Director',
    company: 'DatingLeads',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/datingleads.svg',
    company_logo_alt: 'DatingLeads company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 26,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Alex-Sydorets.png',
    profile_pic_alt: 'Alex Sydorets speaker profile photo',
    max_width: null,
    name: 'Alex',
    surname: 'Sydorets',
    position: 'Senior Business Development Manager',
    company: 'Cpamatica',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/cpamatica.svg',
    company_logo_alt: 'Cpamatica company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 27,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Stefan-Muelhbauer.png',
    profile_pic_alt: 'Stefan Muehlbauer speaker profile photo',
    max_width: null,
    name: 'Stefan',
    surname: 'Muehlbauer',
    position: 'Head of Business Development',
    company: 'Masters in Cash',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Mastersincash-logo.png',
    company_logo_alt: 'Masters in Cash company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 28,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Urte-Mitschunas.png',
    profile_pic_alt: 'Urte Mitschunas speaker profile photo',
    max_width: null,
    name: 'Urte',
    surname: 'Mitschunas',
    position: 'Head of Media Buying',
    company: 'Dating Lions',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/datinglions-logo.png',
    company_logo_alt: 'Dating Lions company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 29,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/tom-marsh.png',
    profile_pic_alt: 'Tom Marsh speaker profile photo',
    max_width: null,
    name: 'Tom',
    surname: 'Marsh',
    position: 'Head of Media Buying & Affiliation',
    company: 'Redwalking',
    company_logo: 'https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg',
    company_logo_alt: 'Redwalking company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 30,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Dinesh-Sivapragsam.png',
    profile_pic_alt: 'Dinesh Sivapragsam speaker profile photo',
    max_width: null,
    name: 'Dinesh',
    surname: 'Sivapragsam',
    position: 'Global Head of SEO',
    company: 'Binance',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/binance-logo.png',
    company_logo_alt: 'Binance company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 31,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Jitendra-Vaswani.png',
    profile_pic_alt: 'Jitendra Vaswani speaker profile photo',
    max_width: null,
    name: 'Jitendra',
    surname: 'Vaswani',
    position: 'CEO',
    company: 'Digiexe.com',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Digiexe-logo.webp',
    company_logo_alt: 'Digiexe.com company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 32,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/michael-melen.png',
    profile_pic_alt: 'Mike Melen speaker profile photo',
    max_width: null,
    name: 'Mike',
    surname: 'Melen',
    position: 'Co-Founder',
    company: 'SmartSites',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/smartsites-logo.png',
    company_logo_alt: 'SmartSites company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 33,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Komninos-Chatzipapas.png',
    profile_pic_alt: 'Komninos Chatzipapas speaker profile photo',
    max_width: null,
    name: 'Komninos',
    surname: 'Chatzipapas',
    position: 'Co-Founder',
    company: 'LunaLust',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/LunaLust-logo.svg',
    company_logo_alt: 'LunaLust company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 34,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Lars-Scholman.png',
    profile_pic_alt: 'Lars Scholman speaker profile photo',
    max_width: null,
    name: 'Lars',
    surname: 'Scholman',
    position: 'Head Partners & Campaigns',
    company: 'OfferPump',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/offerpump.png',
    company_logo_alt: 'OfferPump company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 35,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Vassos_Paraskevas.png',
    profile_pic_alt: 'Vassos Paraskevas speaker profile photo',
    max_width: null,
    name: 'Vassos',
    surname: 'Paraskevas',
    position: 'Founder & CEO',
    company: 'ServiceNow Corporate Solutions',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/servicenow-header-logo.png',
    company_logo_alt: 'ServiceNow Corporate Solutions company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 36,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Andreas-Vladimirou-Trppides.png',
    profile_pic_alt: 'Andreas Vladimirou speaker profile photo',
    max_width: null,
    name: 'Andreas',
    surname: 'Vladimirou',
    position: 'Director',
    company: 'Treppides Group',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Treppides-logo.png',
    company_logo_alt: 'Treppides Group company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 37,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Marios-Tannousis.png',
    profile_pic_alt: 'Marios Tanousis speaker profile photo',
    max_width: null,
    name: 'Marios',
    surname: 'Tanousis',
    position: 'CEO',
    company: 'Invest Cyprus',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/InvestCyprus-logo.jpeg',
    company_logo_alt: 'Invest Cyprus company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  // {
  //   id: 38,
  //   profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Andreas-Hadjikyrou.png',
  //   profile_pic_alt: 'Andreas Hadjikyrou speaker profile photo',
  //   max_width: null,
  //   name: 'Andreas',
  //   surname: 'Hadjikyrou',
  //   position: 'Founder & Director',
  //   company: '7Q',
  //   company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/7Q-logo.png',
  //   company_logo_alt: '7Q company logo',
  //   speech_label: null,
  //   speech_title: null,
  //   speech_date: null,
  //   speech_time: null,
  //   speech_venue: null,
  //   speech_desc: null,
  //   bio: null,
  //   facebook: null,
  //   instagram: null,
  //   linkedin: null,
  // },
  {
    id: 39,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/diana-sultanova.png',
    profile_pic_alt: 'Diana Sultanova speaker profile photo',
    max_width: null,
    name: 'Diana',
    surname: 'Sultanova',
    position: 'Business Development Manager',
    company: 'Kadam',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/kadam-dark-background.svg',
    company_logo_alt: 'Kadam company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 40,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/patrick-friedrich.png',
    profile_pic_alt: 'Patrick C. Friedrich speaker profile photo',
    max_width: null,
    name: 'Patrick',
    surname: 'C. Friedrich',
    position: 'CEO',
    company: 'Finest PCF GmbH',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/finest-logo-dark-background.svg',
    company_logo_alt: 'Finest PCF GmbH company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 41,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/daniel-friedrich.png',
    profile_pic_alt: 'Daniel Friedrich speaker profile photo',
    max_width: null,
    name: 'Daniel',
    surname: 'Friedrich',
    position: 'Lawyer/Partner',
    company: 'Winterestein',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/winterestein-dark-background-1.svg',
    company_logo_alt: 'Winterestein company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 42,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/mike-golubev.png',
    profile_pic_alt: 'Mike Golubev speaker profile photo',
    max_width: null,
    name: 'Mike',
    surname: 'Golubev',
    position: 'Principal Solutions Architect',
    company: 'Amazon Web Services',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/amazon-web-services-logo-light-background.png',
    company_logo_alt: 'Amazon Web Services company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 43,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/egor-miasnikov.png',
    profile_pic_alt: 'Egor Miasnikov speaker profile photo',
    max_width: null,
    name: 'Egor',
    surname: 'Miasnikov',
    position: 'Senior Solutions Architect',
    company: 'Amazon Web Services',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/amazon-web-services-logo-light-background.png',
    company_logo_alt: 'Amazon Web Services company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 44,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/bob-kelland.png',
    profile_pic_alt: 'Bob Kelland speaker profile photo',
    max_width: null,
    name: 'Bob',
    surname: 'Kelland',
    position: 'CEO',
    company: 'CloneTwin.AI',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/clone-twin-logo-light-background.png',
    company_logo_alt: 'CloneTwin.AI company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 45,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/jasmin-alic.png',
    profile_pic_alt: 'Jasmin Alic speaker profile photo',
    max_width: null,
    name: 'Jasmin',
    surname: 'Alic',
    position: 'LinkedIn Growth Specialist',
    company: 'Hey Jay',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/hey-jay-logo-dark-background.png',
    company_logo_alt: 'Hey Jay company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 46,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/koray-tugberk.png',
    profile_pic_alt: 'Koray Tugberk Gubur speaker profile photo',
    max_width: null,
    name: 'Koray',
    surname: 'Gubur',
    position: 'CEO',
    company: 'Holistic SEO & Digital',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/holistic-seo-logo-dark-backround.png',
    company_logo_alt: 'Holistic SEO & Digital company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 47,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/jim-austin.png',
    profile_pic_alt: 'Jim Austin speaker profile photo',
    max_width: null,
    name: 'Jim',
    surname: 'Austin',
    position: 'Founder',
    company: 'Jim Austin Global',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/jim-austin-global-light-backround.svg',
    company_logo_alt: 'Jim Austin Global company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 48,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/dmitri-bezdetny.png',
    profile_pic_alt: 'Dmitri Bezdetny speaker profile photo',
    max_width: null,
    name: 'Dmitri',
    surname: 'Bezdetny',
    position: 'CEO',
    company: 'CBD Local Inc',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/cbd-logo.png',
    company_logo_alt: 'CBD Local Inc company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  // {
  //   id: 49,
  //   profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/sobhi-smat.png',
  //   profile_pic_alt: 'Sobhi Smat speaker profile photo',
  //   max_width: null,
  //   name: 'Sobhi',
  //   surname: 'Smat',
  //   position: 'SEO Consultant',
  //   company: 'Freelance',
  //   company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/sobhi-smat-logo-light-backround.png',
  //   company_logo_alt: 'Freelance company logo',
  //   speech_label: null,
  //   speech_title: null,
  //   speech_date: null,
  //   speech_time: null,
  //   speech_venue: null,
  //   speech_desc: null,
  //   bio: null,
  //   facebook: null,
  //   instagram: null,
  //   linkedin: null,
  // },
  {
    id: 50,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/roman-khmelnytskyy.png',
    profile_pic_alt: 'Roman Khmelnytskyy speaker profile photo',
    max_width: null,
    name: 'Roman',
    surname: 'Khmelnytskyy',
    position: 'CBDO',
    company: 'Mirelia',
    company_logo: 'https://cdn-images.island-conference.com/exhibitors/logos/mirelia-logo2.png',
    company_logo_alt: 'Mirelia company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 51,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/sam-goldfarb.png',
    profile_pic_alt: 'Sam Goldfarb speaker profile photo',
    max_width: null,
    name: 'Sam',
    surname: 'Goldfarb',
    position: 'CEO',
    company: 'Tradimax',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/tradimax-logo-light-background.png',
    company_logo_alt: 'Tradimax company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 52,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/polina.png',
    profile_pic_alt: 'Polina Kapitonova speaker profile photo',
    max_width: null,
    name: 'Polina',
    surname: 'Kapitonova',
    position: 'HR Advisor',
    company: 'Alpha Affiliates',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/alpha-affiliates.png',
    company_logo_alt: 'Alpha Affiliates company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 53,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Irina-Pisanko.png',
    profile_pic_alt: 'Irina Pisanko speaker profile photo',
    max_width: null,
    name: 'Irina',
    surname: 'Pisanko',
    position: 'CPO',
    company: 'Alpha Affiliates',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/alpha-affiliates.png',
    company_logo_alt: 'Alpha Affiliates company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 54,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Michelle-Marriott-3.png',
    profile_pic_alt: 'Michelle Marriott speaker profile photo',
    max_width: null,
    name: 'Michelle',
    surname: 'Marriott',
    position: 'Founder',
    company: 'Carla Sez',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/CarlaSez-logo.png',
    company_logo_alt: 'Carla Sez company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 55,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Uwe-Ruck.png',
    profile_pic_alt: 'Uwe Ruck speaker profile photo',
    max_width: null,
    name: 'Uwe',
    surname: 'Ruck',
    position: 'CEO',
    company: '4based.com',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/4based-logo.svg',
    company_logo_alt: '4based.com company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  {
    id: 56,
    profile_pic: 'https://cdn-images.island-conference.com/plan_speakers/speakers/sanita-catovic.png',
    profile_pic_alt: 'Sanita Catovic speaker profile photo',
    max_width: null,
    name: 'Sanita',
    surname: 'Catovic',
    position: 'Model Manager',
    company: 'ModelSearcher',
    company_logo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/modelsearcher-logo.png',
    company_logo_alt: 'ModelSearcher company logo',
    speech_label: null,
    speech_title: null,
    speech_date: null,
    speech_time: null,
    speech_venue: null,
    speech_desc: null,
    bio: null,
    facebook: null,
    instagram: null,
    linkedin: null,
  },
  
];

//will need an interface later on
export const speakersData: any[] = [
  {
    text: 'STAY TUNED FOR MORE',
  },
  {
    text: 'STAY TUNED FOR MORE',
  },
  {
    text: 'STAY TUNED FOR MORE',
  },
  {
    text: 'STAY TUNED FOR MORE',
  },
  {
    text: 'STAY TUNED FOR MORE',
  },
  {
    text: 'STAY TUNED FOR MORE',
  },
  {
    text: 'STAY TUNED FOR MORE',
  },
  {
    text: 'STAY TUNED FOR MORE',
  },
];
