import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IBoothType } from '../../../core/_models/booth.interface';

@Component({
  selector: 'booths-section',
  templateUrl: './booths-section.component.html',
  styleUrl: './booths-section.component.scss',
})
export class BoothsSectionComponent {
  @Input() booth!: IBoothType;
  constructor(private router: Router) {}
  onClick() {
    this.router.navigate(['/attend/booths'], { queryParams: { booth: this.booth.name.toLowerCase() } });
  }
}
