import { IAfterMovie } from "../interfaces/vision-recap-album.interface";

export const afterMovieData: IAfterMovie[] = [
    {
        title: "i-Con 2023 → Relive the Journey ",
        description: "Take a glimpse into i-Con's 2023 best moments!",
        type: 2,
        thumb: "https://cdn-images.island-conference.com/vision/recap/aftermovie-thumbs/aftermovie.jpg",
        url: "https://www.youtube.com/embed/BOknxp7-CQc?si=yR2_Q5pi3TRHa_2v&autoplay=true",
        category: 1
    },
    {
        title: "Mykonos VIP Trip 2023",
        description: "Reflecting on the Mykonos VIP Trip 2023!!",
        type: 1,
        thumb: "https://cdn-images.island-conference.com/vision/recap/aftermovie-thumbs/mykonos.jpg",
        url: "https://www.youtube.com/embed/jMqGpiBO57I?si=x4Doi01kfAaO8f8u&autoplay=true",
        category: 2
    },
    {
        title: "i-Conic Welcome Party Unforgettable Vibes",
        description: "Reliving the Magic of the Welcome Sunset Party at La Caleta, sponsored by Nova!",
        type: 2,
        thumb: "https://cdn-images.island-conference.com/vision/recap/aftermovie-thumbs/welcome-party.jpg",
        url: "https://www.youtube.com/embed/hY0H3u0PKG8?si=GM_weX0m2e9Lok0f&autoplay=true",
        category: 3
    },
    {
        title: "i-Conic Opening Party, The future is Here!",
        description: "Dive Back into the i-Conic Opening Party!",
        type: 2,
        thumb: "https://cdn-images.island-conference.com/vision/recap/aftermovie-thumbs/opening-party.jpg",
        url: "https://www.youtube.com/embed/q7SSJSfWhzo?si=SKqDLJ13-WqkmJ_D&autoplay=true",
        category: 3
    },
    {
        title: "i-Conic Sunset Closing Party. Can't stop the Tribe.",
        description: "The ultimate i-Con wrap-up: An insane sunset beach bash at Captain’s Cabin, all thanks to TwinRed",
        type: 2,
        thumb: "https://cdn-images.island-conference.com/vision/recap/aftermovie-thumbs/closing-party.jpg",
        url: "https://www.youtube.com/embed/fP79bw8wr44?si=NEmCPawK1pGA80yJ&autoplay=true",
        category: 3
    }

]