import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, shareReplay } from 'rxjs';
import { ITicketTypeResponse } from '../../_models/responses/ticket-type-response.interface';
import { ITicketType } from '../../_models/ticket.interface';
import { TicketService } from './ticket.service';
import { StateService } from '@i-con/shared/common';

const TRST_TICKETS = makeStateKey('tickets');

interface ITicketState {
  ticket_types: ITicketType[];
  selected_ticket_type: ITicketType;
  selected_ticket_id: string;
  ticket_quantity: number;
}

const initialState: ITicketState = {
  ticket_types: [],
  selected_ticket_type: {
    id: 1,
    description: 'a',
    price: 1,
    discounts: [{ id: 1, percentage: 0.15, name: 'name', description: 'desc', startsOn: new Date(), endsOn: new Date() }],
    includes: [{ content: 'a' }],
    inStock: true,
    discount: 0,
    onTheDoor: 0,
    discountEndsOn: new Date(),
    color: ''
  },
  selected_ticket_id: '1',
  ticket_quantity: 0,
};

@Injectable({ providedIn: 'root' })
export class TicketStateService extends StateService<ITicketState> {
  public ticket_types$: Observable<ITicketType[]> = this.getState(state => state.ticket_types);

  public setTicketTypes() {
    let state_data = this.transferState.get(TRST_TICKETS, null as any);
    if (state_data) {
      this.setState({ ticket_types: state_data });
    } else {
      this.ticketService.getTickets().subscribe((res: ITicketTypeResponse) => {
        this.setState({ ticket_types: res.response.data });
        this.transferState.set(TRST_TICKETS, res.response.data as any);
      });
    }
  }

  public selected_ticket_type$: Observable<ITicketType> = this.getState(state => state.selected_ticket_type);

  public setSelectedTicketType(selected_ticket_type: ITicketType) {
    this.setState({ selected_ticket_type });
  }

  public selected_ticket_id$: Observable<string> = this.getState(state => state.selected_ticket_id);

  public setSelectedTicketId(selected_ticket_id: string) {
    this.setState({ selected_ticket_id });
  }

  public ticket_quantity$: Observable<number> = this.getState(state => state.ticket_quantity);

  ticket_state$: Observable<ITicketState> = this.getState(state => state).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  constructor(private readonly ticketService: TicketService, private transferState: TransferState) {
    super(initialState);
    this.setTicketTypes();
  }
}
