import { IBoothPhotosData } from '../interfaces/boothphotos-data.interface';

export const boothPhotosData: IBoothPhotosData[] = [
	{
		id: 1,
		booth_type_id: 1,
		images: [
			{ url: 'https://cdn-images.island-conference.com/booths/1/booth_photos/DSC01029.jpg', altText: "Moon photo sample - 1" },
			{ url: 'https://cdn-images.island-conference.com/booths/1/booth_photos/DSC01926.jpg', altText: "Moon photo sample - 2" },
			{ url: 'https://cdn-images.island-conference.com/booths/1/booth_photos/vlp_0255.jpg', altText: "Moon photo sample - 3" },
			{ url: 'https://cdn-images.island-conference.com/booths/1/booth_photos/vlp_0257.jpg', altText: "Moon photo sample - 4" },
			{ url: 'https://cdn-images.island-conference.com/booths/1/booth_photos/vlp_0265.jpg', altText: "Moon photo sample - 5" },
			{ url: 'https://cdn-images.island-conference.com/booths/1/booth_photos/vlp_0266.jpg', altText: "Moon photo sample - 6" }
		]
	},
	{
		id: 2,
		booth_type_id: 2,
		images: [
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/IMG_9023.jpg', altText: "Mercury photo sample - 1" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/DSC01551.jpg', altText: "Mercury photo sample - 2" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/DSC01554.jpg', altText: "Mercury photo sample - 3" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/DSC01586.jpg', altText: "Mercury photo sample - 4" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/Icon-78.jpg', altText: "Mercury photo sample - 5" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/Icon-179.jpg', altText: "Mercury photo sample - 6" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/Icon-181.jpg', altText: "Mercury photo sample - 7" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/IMG_0580.jpg', altText: "Mercury photo sample - 8" },
			{ url: 'https://cdn-images.island-conference.com/booths/2/booth_photos/IMG_8846.jpg', altText: "Mercury photo sample - 9" }
		]
	},
	{
		id: 3,
		booth_type_id: 3,
		images: [
			{ url: 'https://cdn-images.island-conference.com/booths/3/booth_photos/DSC01732.jpg', altText: "Galaxy photo sample - 1" },
			{ url: 'https://cdn-images.island-conference.com/booths/3/booth_photos/IMG_8762.jpg', altText: "Galaxy photo sample - 2" },
			{ url: 'https://cdn-images.island-conference.com/booths/3/booth_photos/IMG_9006.jpg', altText: "Galaxy photo sample - 3" },
			{ url: 'https://cdn-images.island-conference.com/booths/3/booth_photos/vlp_0262.jpg', altText: "Galaxy photo sample - 4" }
		]
	},
	{
		id: 4,
		booth_type_id: 4,
		images: [
			{ url: 'https://cdn-images.island-conference.com/booths/4/booth_photos/DSC01630.jpg', altText: "Mars photo sample - 1" },
			{ url: 'https://cdn-images.island-conference.com/booths/4/booth_photos/DSC01592.jpg', altText: "Mars photo sample - 2" },
			{ url: 'https://cdn-images.island-conference.com/booths/4/booth_photos/DSC01598.jpg', altText: "Mars photo sample - 3" },
			{ url: 'https://cdn-images.island-conference.com/booths/4/booth_photos/Icon-19.jpg', altText: "Mars photo sample - 4" },
			{ url: 'https://cdn-images.island-conference.com/booths/4/booth_photos/Icon-80.jpg', altText: "Mars photo sample - 5" },
			{ url: 'https://cdn-images.island-conference.com/booths/4/booth_photos/Icon-81.jpg', altText: "Mars photo sample - 6" }
		]
	},
	{
		id: 5,
		booth_type_id: 5,
		images: [
			{ url: 'https://cdn-images.island-conference.com/booths/5/booth_photos/IMG_0578.jpg', altText: "Venus photo sample - 1" },
			{ url: 'https://cdn-images.island-conference.com/booths/5/booth_photos/IMG_8858.jpg', altText: "Venus photo sample - 2" },
			{ url: 'https://cdn-images.island-conference.com/booths/5/booth_photos/IMG_9008.jpg', altText: "Venus photo sample - 3" },
			{ url: 'https://cdn-images.island-conference.com/booths/5/booth_photos/IMG_9021.jpg', altText: "Venus photo sample - 4" },
			{ url: 'https://cdn-images.island-conference.com/booths/5/booth_photos/Icon-61.jpg', altText: "Venus photo sample - 5" },
			{ url: 'https://cdn-images.island-conference.com/booths/5/booth_photos/Icon-74.jpg', altText: "Venus photo sample - 6" }
		]
	}

];
