import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAttendee } from '../../../features/attend-tickets/attend-tickets.component';
import { StateService } from '@i-con/shared/common';

const initialState: IAttendeesState = {
	submitted: false,
	attendees: [],
};

interface IAttendeesState {
	attendees: IAttendee[];
	submitted: boolean;
}

@Injectable({ providedIn: 'root' })
export class AttendeesStateService extends StateService<IAttendeesState> {
	public attendeess$: Observable<IAttendee[]> = this.getState((state) => state.attendees);

	public setAttendee(attendee: IAttendee, index: number) {
		let temp = this.state.attendees;
		temp[index] = attendee;
		this.setState({ attendees: temp });
	}

	public setAllAttendees(newAttendees: IAttendee[]) {
		this.setState({ attendees: [...newAttendees] });
	}

	public submitted$: Observable<boolean> = this.getState((state) => state.submitted);

	public setSubmitted(submit: boolean) {
		this.setState({ submitted: submit });
	}

	constructor() {
		super(initialState);
	}
}
