<div class="vision-container">
	<div class="header-wrapper">
		<div class="header">
			<h3>Creating The</h3>
			<h1>AFFILIATE CONFERENCE<br> OF THE FUTURE</h1>
		</div>
	</div>

	<div class="first-section">
		<div class="left">
			<img class="vision-img" src="https://cdn-images.island-conference.com/vision/vision_img.jpg" alt="">
		</div>
		<div class="right">
			<div class="content">
				<div class="details">
					<h2>Our Vision</h2>
					<h3>The wait is over. i-Con is back, and it's taking things to a whole new level once again!</h3>
					<p>
						i-Con became a reality through the vision of creating the ultimate conference experience, by affiliates for affiliates.
						 Being actively involved in this industry, our mission was to bring you a premium networking event that is both affordable and 
						 matches your affiliate conference needs, in the breathtaking island of Cyprus! By blending our knowledge and experience, we help 
						 companies maximize their ROIs across a variety of verticals and formats.
					</p>
				</div>
			</div>
		</div>
	</div>

	<div class="third-section" id="i-con">
		<div class="content">
			<div class="left">
				<img class="icon-logo" src="https://cdn-images.island-conference.com/vision/icon-logo.svg">
				<p class="recap">2023 Recap</p>
			</div>
			<div class="middle">
				<p>
					Have a taste of what i-Con has to offer! Check out the highlights of the very first affiliate
					conference we've hosted and witness how the future of affiliate conferences commenced! From the
					speakers area to the expo, we have collected the most special moments through our photo albums and
					our i-Conic after movie!
				</p>
			</div>
			<div class="right">
				<app-nav-button class="main-btn" [class]="''" [new_path]="['/vision/icon-recap/albums/photo-albums']" [type]="'internal'"
					[text]="'Check out the Album'" [target]="'_blank'"></app-nav-button>
			</div>
		</div>
	</div>

	<div class="second-section" id="nexxie">
		<div class="best-place">
			<img class="bptw-img" src="https://cdn-images.island-conference.com/vision/europes_bptw.svg"
				alt="Europes best workplaces 2021 badge, with white text. Logo is in red and blue colours" />
			<img class="bptw-img greate-place" src="https://cdn-images.island-conference.com/vision/bptw_badge.svg"
				alt="Great place To Work badge, with white text. Logo is in red and blue colours" />
			<img class="bptw-img" src="https://cdn-images.island-conference.com/vision/google_partner.svg"
				alt="Google Partner badge (white box with Google logo in the middle)" />
		</div>
		<div class="content">
			<img class="nexxie-logo" src="https://cdn-images.island-conference.com/vision/nexxie-white.svg">
			<img class="icon-team" src="https://cdn-images.island-conference.com/vision/icon-team-new.svg">
			<p>
				Our team is the beating heart of the i-Con concept, working tirelessly behind the scenes.
				<br><br>
				Well-established in the industry as an advertiser, publisher, marketer, and service provider, Nexxie
				Group has the experience, knowledge, and reach to bring i-Con to life. With this group of talented and
				highly motivated individuals, there's no one better to shape the future of affiliate conferences.
			</p>
			<app-nav-button class="main-btn" [class]="''" [new_path]="['https://nexxie.com/']" [type]="'external'"
				[text]="'Check Us Out'" [target]="'_blank'"></app-nav-button>
		</div>
	</div>

	<div class="fourth-section" id="haze">
		<div class="video-overlay"></div>
		<div class="video-container">
			<video id="myVideo" loop muted autoplay playsinline oncanplay="this.play()"
				onloadedmetadata="this.muted = true">
				<source src="https://cdn-images.island-conference.com/vision/haze.mp4" type="video/mp4" />
			</video>
		</div>
		<div class="content">
			<div class="left">
				<img class="arrows" src="https://cdn-images.island-conference.com/vision/right_arrows.svg"
					alt="Six blue outlined arrows pointing to the right on white background." />
				<img class="haze-icon" src="https://cdn-images.island-conference.com/vision/haze_logo_white.svg"
					alt="Haze team written in white letters in front of multiple haze logos on transparent background." />
			</div>
			<div class="right">
				<div class="title">
					<h3>
						THE HAZE <br><span>EXPERIENCE</span>
					</h3>
					<img class="speaker" src="https://cdn-images.island-conference.com/vision/speaker.svg"
						alt="White-lined vector of a music speaker with multiple notes around it on transparent background." />
				</div>
				<p>
					Every great event starts with organized details and ends with executing them brilliantly. This is
					where Haze plays its role! Having established itself in Greece and Cyprus as an event management and
					public relations team, Haze has been the driving force behind some of the most exclusive events of
					the last decade. With such a rich background and direct partnerships with top performers and DJs
					from around the world, your only task is to be present, and Haze will handle everything else!
				</p>
				<app-nav-button class="main-btn" [class]="''" [new_path]="['https://www.instagram.com/hazeteam/']"
					[type]="'external'" [text]="'Feel The Vibes'" [target]="'_blank'"></app-nav-button>
			</div>
		</div>
	</div>

	<div class="fifth-section" id="cod">
		<div class="left">
			<div class="content">
				<div class="details">
					<div class="title">
						<img class="venue" src="https://cdn-images.island-conference.com/vision/venue_icon.svg"
							alt="Purple-lined vector of a twin-sized bed on white background." />
						<h3>
							CONFERENCE <br><span>VENUE</span>
						</h3>
					</div>
					<p>
						What better location for our affiliate conference than the City of Dreams Mediterranean? As
						Europe's leading integrated casino resort, located in Limassol along the south coast of the
						island, it offers a premium Expo center as well as luxurious 5-star accommodation. What's more,
						City of Dreams features top-rated dining options, making it a perfect blend of business and
						pleasure.
					</p>
					<app-nav-button class="main-btn" [type]="'interal'"
						[new_path]="['/stay/accommodation/request/city-of-dreams']" [text]="'Book Your Stay'"></app-nav-button>
				</div>
			</div>
		</div>
		<div class="right">
			<img class="venue" src="https://cdn-images.island-conference.com/vision/cod-drone.jpg"
				alt="Aerial Photo of the City Of Dreams hotel." />
		</div>
	</div>

	<div class="sixth-section" id="merch">
		<div class="content">
			<h3>
				HAVE A LOOK AT <br>
				<span>OUR MERCH</span>
			</h3>
			<p>
				From sunglasses to swimwear and everything in between, you can get it all in one place! Our Miconos
				merchandise is specifically designed to keep you cool during the hot Summer days of Cyprus. Don't miss
				your chance to make an impression at the Expo!
			</p>
			<app-nav-button class="main-btn" [type]="'interal'" [new_path]="['/vision/merchandise']" [text]="'View All'"
				[target]="'_blank'"></app-nav-button>
		</div>
		<app-footer [screenclass]="'overlap'"></app-footer>
	</div>
</div>