import { IVisionRecapAlbumCardContent } from '../interfaces/vision-recap-album.interface';

export const videosData: IVisionRecapAlbumCardContent[] = [
  {
    header: 'AFTERMOVIE',
    title: `Relive i-Con's Top Moments through Our 2-day Playback AfterMovie!`,
    description:
      'Experience the complete i-Con story! From two days filled with business, forged connections, and insightful speeches by industry experts to high-energy parties. Step back and enjoy everything that i-Con 2023 had to offer!',
    youtubeUrl: `https://www.youtube.com/embed/BOknxp7-CQc`,
  },
  {
    imgSrc: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos.svg',
    imgAlt: 'Miconos',
    title: `What happens in Miconos doesn't have to stay in Mykonos!`,
    description:
      'i-Con signals the end of its very first conference by taking the CEOs, Business Owners and Super Affiliates from the biggest names in the industry on an unforgettable adventure! Discover how this never-done-before journey unfolded and experience the beauty of the island of Mykonos through the lens of our camera!',
    youtubeUrl: `https://www.youtube.com/embed/jMqGpiBO57I?si=aTY6PJjiVAdzPs6u`,
  },
];
