import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITripReservationTypeResponse } from "../../_models/responses/trip-reservation-response.interface";
import { Injectable } from '@angular/core';
import { EnvironmentService, StateService } from '@i-con/shared/common';

interface ITripReservationState {
    postData: ITripReservationTypeResponse
}

const initialStatusValue: any = {
    postData: {}
}

@Injectable({ providedIn: 'root' })
export class TripReservationService extends StateService<ITripReservationState> {

    postData$: Observable<ITripReservationTypeResponse> = this.getState((state) => state.postData);

    constructor(private http: HttpClient,
        private _environment: EnvironmentService) {
        super(initialStatusValue);
    }

    tripReservation(tripReservationInfo: object) {
        this.http
        .post<ITripReservationTypeResponse>(`${this._environment.getApiUrl}/trip-reservation`, tripReservationInfo)
        .subscribe({
            error: (error:any) => {
                this.setState({ postData: error.error});
            },
            next: (response: any) => {
                this.setState({ postData: response })
            }
        })
    }
}