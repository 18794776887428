import { IActivityItem } from "../interfaces/activity-item.interface";

export const activityData: IActivityItem[] = [
    {
        title: "Stroll through Limassol Old Town",
        shortTitle: "Old Town",
        imageUrl: "https://cdn-images.island-conference.com/stay/activities/old-town-limassol.jpg",
        description: "Start by discovering the heart of the city in Limassol's Old Town. Wander through charming streets lined with traditional houses, cafes, and boutiques. Visit the Medieval Castle and explore the Archaeological Museum nearby and learn about the city's rich history.",
        mainPageImageUrl: "https://cdn-images.island-conference.com/stay/activities/activity-main-old-town.jpg",
        linkUrl:"https://maps.app.goo.gl/qJ3QPgzLhfXTsAZo9",
    },
    {
        title: "Visit Limassol Marina",
        shortTitle: "Limassol Marina",
        imageUrl: "https://cdn-images.island-conference.com/stay/activities/marina-limassol.jpg",
        description: "Experience the modern side of Limassol at the Limassol Marina, a picturesque harbour featuring luxury yachts, chic restaurants, and vibrant nightlife. Walk along the promenade, enjoy a meal by the waterfront, or simply soak in the beautiful views of the Mediterranean.",
        mainPageImageUrl: "https://cdn-images.island-conference.com/stay/activities/activity-main-limassol-marina.jpg",
        linkUrl:"https://maps.app.goo.gl/sV8Zrct5RMB7tC5s6",
    },
    {
        title: "Day trip to the Troodos Mountains",
        shortTitle: "Troodos Mountains",
        imageUrl: "https://cdn-images.island-conference.com/stay/activities/troodos.jpg",
        description: "Cyprus is not just Sun, Sea and Sand. For nature lovers and outdoor enthusiasts, a day trip to the Troodos Mountains is a must. Hike through enchanting trails surrounded by lush forests, picturesque villages, and beautiful waterfalls.",
        mainPageImageUrl: "https://cdn-images.island-conference.com/stay/activities/activity-main-troodos.jpg",
        linkUrl:"https://maps.app.goo.gl/6CoWEuPxcKFdqvTz9",
    },
    {
        title: "Wine Tasting in Omodos",
        shortTitle: "Omodos",
        imageUrl: "https://cdn-images.island-conference.com/stay/activities/omodos-wine-tasting.jpg",
        description: "Venture into the quaint village of Omodos, a hidden gem just north of Limassol. Walk through its cobblestone streets, admire the traditional Cypriot architecture, and visit the famous Monastery of the Holy Cross. Don’t forget to sample some of the award-winning Cypriot wines in one of the many local wineries.",
        mainPageImageUrl: "https://cdn-images.island-conference.com/stay/activities/activity-main-omodos.jpg",
        linkUrl:"https://maps.app.goo.gl/KFDPj3QwF9nT5htd7",
    },
    {
        title: "Explore Kourion Archaeological Site",
        shortTitle: "Kourion Archaeological Site",
        imageUrl: "https://cdn-images.island-conference.com/stay/activities/kourion-site.jpg",
        description: "If you have a keen interest in the past, don't miss the chance to explore the Kourion Archaeological Site. This ancient city boasts a well-preserved Greco-Roman theatre with breathtaking views of the Mediterranean. Discover the ruins of the city, including Roman villas, bathhouses, and mosaics that offer a glimpse into Cyprus’ rich past.",
        mainPageImageUrl: "https://cdn-images.island-conference.com/stay/activities/activity-main-kourion.jpg",
        linkUrl:"https://maps.app.goo.gl/G8xXMBJVrCWpCNXv5",
    }
]