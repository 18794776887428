import { Component, Inject } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { StepService } from '../../service/step.service';
import { UserAnswersService } from '../../service/userAnswers.service';
import { NOT_ATTEND_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-notattend',
  templateUrl: './notattend.component.html',
  styleUrl: './notattend.component.scss',
})
export class NotAttendComponent {
  constructor(@Inject(NOT_ATTEND_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService, private stepService: StepService) {}

  selectedItem: IQuestionnaireUserAnswers;
  buttonClicked: boolean = false;
  otherValue: string = '';
  otherSelected: boolean = false;

  onRadioChange(item: ISurveyAnswer) {
    this.buttonClicked = true;

    this.otherSelected = item.value === 'other' ? true : false;
    if (!this.otherSelected) this.otherValue = '';
    this.selectedItem = item.value.includes('other')
      ? {
          questionId: this.content.id,
          answers: [
            {
              answerId: item.id,
              value: [item.value],
              description: this.otherValue,
            },
          ],
        }
      : {
          questionId: this.content.id,
          answers: [
            {
              answerId: item.id,
              value: [item.value],
            },
          ],
        };
  }

  handleInputChange(newValue: string): void {
    this.otherValue = newValue;
    this.selectedItem.answers[0].description = this.otherValue;
  }

  nextClick() {
    if (this.selectedItem) {
      this.userAnswersService.pushNewAnswer(this.selectedItem);
      this.stepService.goToQuestionWithId(this.getNextQuestionId()!);
    }
  }

  private getNextQuestionId() {
    const item = this.content.surveyAnswer.find(answer => answer.id === this.selectedItem.answers[0].answerId);

    if (item) {
      return item.nextQuestion;
    }

    return -1;
  }

  shouldNextBeEnabled(): boolean {
    return this.selectedItem?.answers?.length > 0 && (!this.otherSelected || this.otherValue.length !== 0);
  }
}
