import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs';
import { EventReservationService } from '../../../core/_services/event-reservation/event-reservation.service';
import { IEventReservationTypeResponse } from '../../../core/_models/responses/event-reservation-type-response.interface';
import { CountryCodes } from '../../../shared/form/constants/country-codes';
import { StateService } from '@i-con/shared/common';
declare let window: any;
interface IEventReservationState {
	success: boolean;
	return_msg: string;
}

const initialState: IEventReservationState = {
	success: false,
	return_msg: '',
};

@Component({
	selector: 'app-guest-list-form',
	templateUrl: './guest-list-form.component.html',
	styleUrls: ['./guest-list-form.component.scss']
})
export class GuestListFormComponent extends StateService<IEventReservationState> implements OnInit {

	header_title: string;
	event_class: string;
	sponsor: string;
	tempShow: boolean = true;
	numShow: boolean = false;

	selected_countries = CountryISO;

	country_codes = CountryCodes;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: any,
		private formBuilder: UntypedFormBuilder,
		private eventReservationService: EventReservationService
	) {
		super(initialState);
		this.header_title = data.header_title;
		this.event_class = data.event_class;
		this.sponsor = data.sponsor;
	}

	SearchCountryField = SearchCountryField;
	numberOfPersons: number = 0;

	reservationType: string;
	type: number = 0;

	guestListForm: UntypedFormGroup;
	submitted = false;

	postData$: Observable<IEventReservationTypeResponse>;
	responseState$: Observable<IEventReservationState> = this.getState((state) => state);

	ngOnInit(): void {
		this.postData$ = this.eventReservationService.postData$;
		this.responseState$ = this.getState((state) => state);

		this.guestListForm = this.formBuilder.group({
			nameControl: ['', [Validators.required, Validators.minLength(2)]],
			surnameControl: ['', [Validators.required, Validators.minLength(2)]],
			emailControl: ['', [Validators.required, Validators.email]],
			companyControl: ['', [Validators.required]],
			phoneControl: ['', [Validators.required]],
			// personsControl: ['', [Validators.required]],
		});
	}

	get f() {
		return this.guestListForm.controls;
	}

	isOpening(event_class: string): boolean {
		if (event_class == 'opening') {
			return true;
		} else {
			return false;
		}
	}

	isSpiritual(event_class: string): boolean {
		if (event_class == 'spiritual') {
			return true;
		} else {
			return false;
		}
	}

	onSubmit() {
		this.submitted = true;

		if (this.guestListForm.invalid || this.numberOfPersons == 0) {
			return;
		}

		if (this.event_class == 'spiritual') {
			this.reservationType = 'Reserve your Spot';
			this.type = 3;
		} else {
			this.reservationType = 'Guest List';
			this.type = 1; //guest list event form type
		}

		//console.log(this.guestListForm);

		const reserveration_object = {
			title: this.header_title,
			name: this.guestListForm.value.nameControl,
			surname: this.guestListForm.value.surnameControl,
			companyName: this.guestListForm.value.companyControl,
			dialCode: this.guestListForm.value.phoneControl.dialCode,
			country: this.guestListForm.value.phoneControl.countryCode,
			phoneNumber: this.guestListForm.value.phoneControl.number,
			email: this.guestListForm.value.emailControl,
			numberOfPeople: this.numberOfPersons,
			reservationType: this.reservationType,
			type: this.type
		};

		this.eventReservationService.eventReservation(reserveration_object);

		this.postData$.subscribe((res) => {
			if (res.response?.statusCode == 201) {

				if (this.event_class === 'opening') {
					window?.fbq('trackCustom', 'GuestListOpeningSubmission');
				} else if (this.event_class === 'pre-event') {
					window?.fbq('trackCustom', 'GuestListPreEventSubmission');
				} else if (this.event_class === 'closing') {
					window?.fbq('trackCustom', 'GuestListClosingSubmission');
				}

				this.setState({
					success: true,
					return_msg: '',
				});
			} else {
				this.setState({
					success: false,
					return_msg: res.response?.message,
				});
			}
		});
	}

	increasePersons() {
		this.tempShow = false;
		this.numShow = true;
		if (this.numberOfPersons >= 10) {
			this.numberOfPersons = 10;
		}
		else {
			this.numberOfPersons++;
		}
	}

	decreasePersons() {
		this.tempShow = false;
		this.numShow = true;
		if (this.numberOfPersons <= 0) {
			this.tempShow = true;
			this.numShow = false;
			this.numberOfPersons = 0;
		}
		else {
			this.numberOfPersons--;
		}
	}

}
