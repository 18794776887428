<app-pageheader [heading_class]="class_name" [heading_phrase]="phrase" [heading_title1]="title1"></app-pageheader>

<div class="purpleBg" *ngIf="ticket_types$ | async as tickets">

	<div class="container">
		<div class="paragraphs">
			<p>Attend your future affiliate conference with some added perks! Purchase one of our tickets and witness
				the
				perfect combination of business networking and holiday fun! Join other digital marketing and affiliation
				experts and discover new partnership opportunities!</p>
			<br/>
			<p> Our conference tickets also grant you access to speeches on online dating, SEO, crypto and digital
				marketing.</p>
		</div>


		<span class="bookYours">BOOK YOURS NOW!</span>
		<div class="availableUntil" *ngIf="tickets.length > 0 && !discountEnd">
			<span>Early Bird price available until {{ formatDate(tickets[0].discounts[0].endsOn) }}</span>
		</div>

		<div class="tickets">
			<select-ticket-type *ngFor="let ticket of tickets;let i = index;" [ticket]="ticket"
				[color]="ticket.color" class="c-{{ticket.color}}"></select-ticket-type>
		</div>
		<ngx-slick-carousel class="ticketsCarousel" #slickModalTickets="slick-carousel" [config]="ticketsSlideConfig" *ngIf="tickets.length > 0">
			<div ngxSlickItem class="slide c-{{ticket.color}}" *ngFor="let ticket of tickets;let i = index;">
				<select-ticket-type [ticket]="ticket" [color]="ticket.color"></select-ticket-type>
			</div>
		</ngx-slick-carousel>
	</div>
</div>
<!-- <div class="attend_tickets" *ngIf="bundle_types$ | async as bundles">
	<div class="top-header">
		<div class="content" [ngClass]="{ main: current_step > 1 }">
			<h3 *ngIf="current_step === 1">Attendance</h3>
			<h1 [ngClass]="{ main: current_step > 1 }">
				{{ current_step === 1 ? "TICKETS" : "REGISTER ATTENDEES" }}
			</h1>
		</div>
	</div>
	<ng-container [ngSwitch]="current_step">
		<select-ticket-type *ngSwitchCase="1" (setBundleType)="setBundleType($event)" [tickets]="ticket_types"
			[bundles$]="bundle_types$"></select-ticket-type>
		<select-quantity *ngSwitchCase="2" [ticketTypesReservation]="ticketTypesReservation"
			(setTicketQuantity)="setTicketQuantity($event)" (goToStep)="goToEmmitedStep($event)">
		</select-quantity>
		<create-attendees *ngSwitchCase="3" [reservedTicketTypesQuantity]="ticketTypesReservation"
			(goToStep)="goToEmmitedStep($event)" [previousStep]="1">
		</create-attendees>
		<reserve-ticket-type *ngSwitchCase="4" [reservedTicketTypesQuantity]="ticketTypesReservation"
			(goToStep)="goToEmmitedStep($event)" (setSecurionPayment)="setSecurionPayment($event)"
			(setBuyer)="setBuyer($event)" [buyer]="buyer" [success]="successfulTransaction">
		</reserve-ticket-type>
	</ng-container>
</div> -->