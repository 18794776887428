<div class="care-header">
    <div class="content">
        <div class="left">
            <h3>There's no doubt that</h3>
            <h1>WE CARE</h1>
        </div>
        <div class="right">
            <div class="right">
                <img class="header-icon" src="https://cdn-images.island-conference.com/pageheader/header_logo.png">
            </div>
        </div>
    </div>
</div>
<div class="first-section">
    <div class="container flexr">
        <div class="left">
            <div class="content">
                <h2>Committed to Being Eco-Friendly</h2>
                <p>
                    Environmental awareness has become increasingly important so it is vital for all of us to embrace a new way of living when it comes to safeguarding the planet. Various practices have been adopted to turn our environment into a place of prosperity and well-being.
                    <br /><br />
                    This includes Water Reuse, the Elimination of Plastic, the use of Bamboo straws, Recycling, and Sustainable Waste Disposal.
                    <br /><br />
                    While embracing a new way of living, we have also included vegan food in our menus. By doing this we seek to exclude all forms of exploitation and promote the development and use of animal-friendly alternatives.
                    <br /><br />
                    <span>A lifestyle we wholeheartedly promote.</span>
                </p>
            </div>
        </div>
        <div class="right">
            <div class="scroll-img">
                <img class="scroll-icon" src="https://cdn-images.island-conference.com/vision/scroll.gif" alt="Gif of an arrow pointing down with the words 'Scroll Down' circling it, with an orange circle in the background.">
            </div>
            <div class="dots">
                <img class="dots-icon" src="https://cdn-images.island-conference.com/vision/dots.svg" alt="Rectangle shape made up of light blue dots">
            </div>
        </div>
    </div>
</div>
<div class="second-section">
    <div class="container flexr">
        <div class="left">
            <div class="items">
                <div class="item" *ngFor="let item of noplanetb_items">
                    <img src="https://cdn-images.island-conference.com/care/{{item.img}}" alt="{{item.alt}}">
                    <h2>
                        {{item.title}}
                    </h2>
                    <p>
                        {{item.description}}
                    </p>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="no-planet-b">
                <img src="https://cdn-images.island-conference.com/care/no_planb.svg" alt="White-coloured letters spelling 'there is no planet b' on transparent background.">
            </div>
        </div>
    </div>
</div>

<div class="third-section">
    <div class="container flexr">
        <div class="left">
            <h3>Family</h3>
            <h2>GETAWAY</h2>
            <h3>
                Considering merging your business trip
                with a family getaway? We've got you covered!
            </h3>
            <p>
                With its beautiful weather and a wide range of activities, Cyprus stands out as the perfect holiday spot. So, if you're thinking about blending business and family travel, you can relax knowing that your kids will have a wonderful time!<br/><br/>
                We're well aware of the intensity of conference schedules, often leaving little time for anything else. That's why we've made it a priority to offer you a worry-free environment where your children are cared for. This will allow you to focus on your business commitments.<br/><br/>
                The conference venue provides an array of services and activities designed to keep your children both entertained and safe, offering you peace of mind. Kids can indulge in activities like Kids City, Adventure Park, and the Tennis Academy, with the option of 24-hour babysitting available upon request.
            </p>
        </div>
        <div class="right flexr">
            <div class="top flexr">
                <div class="item item-1">
                    <img class="item-icon" src="https://cdn-images.island-conference.com/care/kids_city.svg" alt="Purple-lined vector of two people sitting on a table holding a circle and a square on which background.">
                    <p>Kids City</p>
                </div>
                <div class="item">
                    <img class="item-icon" src="https://cdn-images.island-conference.com/care/adventure_park.svg" alt="Purple-lined vector of a person going down a slide on white background">
                    <p>Adventure Park</p>
                </div>
            </div>
            <div class="bottom flexr">
                <div class="item item-1">
                    <img class="item-icon" src="https://cdn-images.island-conference.com/care/aqua_park.svg" alt="Purple-lined vector of a water slide on white background.">
                    <p>Aqua Park</p>
                </div>
                <div class="item">
                    <img class="item-icon" src="https://cdn-images.island-conference.com/care/tennis_academy.svg" alt="Purple-lined vector of a tennis racket and tennis ball on white background.">
                    <p>Tennis Academy</p>
                </div>
            </div>
        </div>
    </div>
</div>