<div class="content">
    <h1>{{content.title}}</h1>
    <img class="haze" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2>{{content.description}}</h2>
    <div class="faces">
        <div class="answers" *ngFor="let item of content.surveyAnswer; let i = index" (click)="imageClick(item)">
            <img [src]="buttonClicked && selectedItem.answers[0].answerId === item.id 
                ? 'https://cdn-images.island-conference.com/questionnaire/' + item.imageUrl[1] 
                : 'https://cdn-images.island-conference.com/questionnaire/' + item.imageUrl[0]" />
            <span class="value">{{ i === 0 ? item.description + ' \\m/' : item.description }}</span>
        </div>

    </div>

    <i-con-ctabuttons [nextEnabled]="selectedItem !== undefined" (nextClick)="nextClick()"></i-con-ctabuttons>
</div>