<div class="background">
    <div class="container">
        <div class="iconLogo">
            <img src="https://cdn-images.island-conference.com/pageheader/header_logo.png" alt="I-Con">
        </div>
        <h1>Interested in hosting a speech at i-Con?</h1>
        <h2>Submit your application below.</h2>
        <div class="paragraphs">
            <p>Talks are usually 30 minutes long, with an additional 15-minute discussion session depending on the
                speaker. We want to offer our attendees quality and valuable talks with useful insights.
            </p>
            <p>
                <span>Topic</span>
                <br>
                We want to keep the topics relevant to the conference and the goals of the people attending. If you have
                unique
                and meaningful insights into the online industry with a focus on the adult and dating vertical we want
                to hear you out!
            </p>
        </div>
        <div class="buttons">
            <button class="main-btn" (click)="submitClick()">Submit Application</button>
            <button class="secondaryBtn" (click)="cancelClick()">Cancel</button>
        </div>
    </div>
</div>