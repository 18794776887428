<div class="recap-container">

	<div class="recap-header">
		<div class="content">
			<h3>i-Con 2023</h3>
			<h1>RECAP</h1>
		</div>
	</div>

	<div class="first-section">
		<div class="left">
			<div class="content">
				<div class="details">
					<div class="title">
						<img class="venue" src="https://cdn-images.island-conference.com/vision/recap/album-icon.svg"
							alt="Purple-lined vector of a twin-sized bed on white background." />
						<h3>
							Photo Albums
						</h3>
					</div>
					<p>
						Connect with thousands of advertisers, publishers, affiliates, and more, during two days filled
						with networking opportunities. Attend speeches and panels by the industry's key people and
						brightest minds, as they discuss the latest trends and topics. Experience thrilling business
						events and lively parties! And the best part? You can do all of this under the warm sun, by the
						beautiful sea, and on the sandy shores of Europe's largest casino resort, located on the
						beautiful island of Cyprus!
					</p>
					<app-nav-button class="main-btn" [type]="'internal'"
						[new_path]="['/vision/icon-recap/albums/photo-albums']" [text]="'Check out the Album'"
						[target]="'_blank'"></app-nav-button>
				</div>
			</div>
		</div>
		<div class="right">
			<img class="cover" src="https://cdn-images.island-conference.com/vision/recap/photo-cover.jpg" alt="" />
		</div>
	</div>

	<div class="second-section">
		<div class="content">
			<img class="cover" src="https://cdn-images.island-conference.com/vision/recap/movie-icon.svg" alt="" />
			<h3>
				i-Conic Aftermovie
			</h3>
			<p>
				With the buzz of i-Con still in the air, we're on the verge of an adventure like no other. So, to
				celebrate, we present you with our much-anticipated i-Con aftermovie!
				<br><br>
				Don't miss your chance to re-live the amazing times we all experienced during i-Con 2023, all collected
				and put together in a memorable video clip!
				<br><br>
				Click below to visit our official YouTube channel and watch the i-Conic aftermovie!
			</p>
			<app-nav-button class="main-btn" [type]="'external'"
				[new_path]="['https://www.youtube.com/watch?v=BOknxp7-CQc']" [text]="'i-Con After Movie'"
				[target]="'_blank'"></app-nav-button>
		</div>
	</div>
</div>