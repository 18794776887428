import { IFormData } from '../../../shared/components/startup-pitch-form/interfaces/startup-pitch-form.interface';

export const formData: IFormData = {
  heading: 'Winning Pitch',
  description: 'Fill in your details below and we will get back to you!',
  input1Attributes: {
    className: 'fullname',
    placeholder: 'Full Name',
    type: 'text',
    formControl: 'fullnameControl',
  },
  input2Attributes: {
    className: 'email',
    placeholder: 'Email',
    type: 'email',
    formControl: 'emailControl',
  },
  input3Attributes: {
    className: 'industry',
    placeholder: 'Industry',
    type: 'text',
    formControl: 'industryControl',
  },
  multiSelectAttributes: {
    className: 'topics',
    placeholder: 'Topics',
    type: 'text',
    formControl: 'topicsControl',
  },
  textareaPlaceholder: 'Brief description of your startup idea (max. 2000)',
  primaryButtonText: 'Submit',
  secondaryButtonText: 'Cancel',
};
