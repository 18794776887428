import { Component, Inject, OnInit } from '@angular/core';
import { IQuestionnaire } from '../../interface/questionnaire.interface';
import { UserAnswersService } from '../../service/userAnswers.service';
import { SEE_YOU_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-see-you-next-year',
  templateUrl: './see-you-next-year.component.html',
  styleUrl: './see-you-next-year.component.scss',
})
export class SeeYouNextYearComponent implements OnInit {
  imgUrl: string = 'https://cdn-images.island-conference.com/questionnaire/thank-you.png';
  constructor(@Inject(SEE_YOU_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService) {}

  ngOnInit(): void {
    this.userAnswersService.scrollToTop();
    const lastAnswer = this.userAnswersService.getUserAnswers().find(answer => answer.questionId === this.content.id - 1);
    const answerId = lastAnswer?.answers[0].answerId;
    if (answerId) {
      if (answerId === 55) this.imgUrl = 'https://cdn-images.island-conference.com/questionnaire/see-you.png';
    }
  }
}
