import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITicketType } from '../../../../core/_models/ticket.interface';
import { TicketStateService } from '../../../../core/_services/tickets/ticket-state.service';
// na to kamo je sto bundle component p to url
@Component({
  selector: 'select-ticket-type',
  templateUrl: './select-ticket-type.component.html',
  styleUrls: ['./select-ticket-type.component.scss'],
})
export class SelectTicketTypeComponent {
  Math: Math = Math;
  @Input() ticket: ITicketType;
  @Input() color: string;

  constructor(private route: ActivatedRoute, private router: Router, private readonly ticketStateService: TicketStateService) {}

  formatDate(dateObj: Date): string {
    const date = new Date(dateObj);
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    

    const nthNumber = (number: number) => {
      if (number > 3 && number < 21) return 'th';
      switch (number % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    return `${day}${nthNumber(day)} of ${month} ${year}`;
  }

  purchaseTicketClick(ticket: ITicketType) {
    this.ticketStateService.setSelectedTicketType(ticket);
    console.log(this.router.url);
    if(this.router.url === '/attend/tickets'){
      this.router.navigate([`${ticket.id}/bundle`], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([`tickets/${ticket.id}/bundle`], {
        relativeTo: this.route,
      });
    }
  }

  checkEarlyActive(dateObj: Date) {
		const currentDate = new Date();
		if (new Date(dateObj) <= currentDate) {
			return true;
		}
		return false;
	}


  // selectTicketType(ticket_id: number | undefined): void {
  //   window?.fbq('trackCustom', 'TicketsSelect');
  //   this.setTicketType.emit({ ticket_id });
  // }

  // selectBundleType(bundle_id: number | undefined): void {
  //   //window?.fbq('trackCustom', 'TicketsSelect');
  //   this.setBundleType.emit({ bundle_id });
  // }

  
}
