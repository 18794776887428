import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
// import { IBoothType } from '@i-con/shared/common';
import { IBoothType } from "../../core/_models/booth.interface";
import { ITicketType } from '../../core/_models/ticket.interface';
import { ITicketBundleType } from '../../core/_models/ticket-bundle.interface';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { BoothService } from '../../core/_services/booths/booth.service';
import { TicketBundleService } from '../../core/_services/tickets/ticket-bundle.service';
import { TicketService } from '../../core/_services/tickets/ticket.service';
import { SubscribeDialogService } from '../../core/_services/subscribe-dialog/subscribe-dialog.service';
import { PlatformService } from '@i-con/shared/common';

@Component({
  selector: 'app-attend',
  templateUrl: './attend.component.html',
  styleUrls: ['./attend.component.scss'],
})
export class AttendComponent implements OnInit {
  booths: IBoothType[] = [];
  tickets: ITicketType[] = [];

  ticketsTotal: number = 0;
  router: any;

  constructor(private boothService: BoothService, 
    private ticketService: TicketService, private ticketBundleService: TicketBundleService,
    private _platformService: PlatformService,
    private subscribeDialog: SubscribeDialogService) {
    } //private homepageService: HomepageService) {}

  @Input() class_name: string = 'attend_page';
  @Input() phrase: string = 'Be A Part Of';
  @Input() title1: string = 'YOUR FUTURE';
  @Input() title2: string = 'AFFILIATE';
  @Input() title3: string = 'CONFERENCE!';

  ticketData$: Observable<ITicketType[]>;
  boothData$: Observable<IBoothType[]>;

  bundleData$: Observable<ITicketBundleType[]>;

  checkBooth(availability: boolean) {
    return availability ? true : false;
  }

  ngOnInit(): void {
    this.ticketData$ = this.ticketService.data$;
    this.boothData$ = this.boothService.data$;
    this.bundleData$ = this.ticketBundleService.data$;

    // this.boothService.getBoothsAttend().subscribe(res => this.booths = res );
    /*console.log(res)*/

    // var cod_slider = $('.app-imgs');

    // $(window).on('load resize', function () {
    //   if (($(window).width() as any) < 769) {
    //     cod_slider.slick({
    //       infinite: true,
    //       // arrows: true,
    //       speed: 800,
    //       slidesToShow: 1,
    //       adaptiveHeight: true,
    //       autoplay: true,
    //       autoplaySpeed: 3000,
    //       fade: true,
    //     });
    //   } else {
    //     if (cod_slider.hasClass('slick-initialized')) {
    //       cod_slider.slick('unslick');
    //     }
    //   }
    // });
  }

  getPrice(bundle: ITicketBundleType) {
    return Math.round(bundle.ticket.price * bundle.realQuantity * 100)/100;
  }

  @ViewChild("slickModalAttendTickets") slickModalAttendTickets: SlickCarouselComponent;
  attendTicketsSlideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    "prevArrow": '<div class="ts-prev-arrow"><img class="prev-arrow" src="/assets/icons/chevron-left.svg"></div>',
    "nextArrow": '<div class="ts-next-arrow"><img class="next-arrow" src="/assets/icons/chevron-right.svg"></div>',
    "responsive": [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


}
