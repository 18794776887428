import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpeakersDynamicDialogComponent } from '../../shared/components/speakers-dynamic-dialog/speakers-dynamic-dialog.component';
import { BecomeASpeakerComponent } from './become-a-speaker/become-a-speaker.component';
import { dialogData, speakersData } from './data/sdialog-data';

@Component({
  selector: 'app-plan-speakers',
  templateUrl: './plan-speakers.component.html',
  styleUrls: ['./plan-speakers.component.scss'],
})
export class PlanSpeakersComponent implements OnInit {
  class_name = 'plan-speakers';
  phrase = `MEET i-CON'S`;
  title1 = 'Speakers & EXPERTS';
  speakers: any[] = speakersData;
  public dialogData: any = dialogData;

  constructor(private router: Router, public dialog: MatDialog) {}

  ngOnInit(): void {
    return;
  }

  openDialog(index: any) {
    if (this.dialogData[index].speech_desc !== null || this.dialogData[index].bio !== null) {
      this.dialog.open(SpeakersDynamicDialogComponent, {
        data: this.dialogData[index],
        panelClass: 'custom-speakermodalbox',
      });
    } else {
      return;
    }
  }

  // openReserveDialog(header_title: string) {
  //   event?.preventDefault();
  //   const dialogRef = this.dialog.open(EventDialogFormComponent, {
  //     maxHeight: '80vh',
  //     width: '850px',
  //     autoFocus: false,
  //     data: header_title,
  //     disableClose: true
  //   });
  // }

  becomeASpeakerClick() {
    let dialogRef = this.dialog.open(BecomeASpeakerComponent, {
      height: '100%',
      width: '100%',
      maxWidth: 'unset',
    });
  }
}
