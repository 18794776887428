import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { IVerticalResponse } from '../../_models/responses/vertical-response.interface';
import { EnvironmentService, StateService } from '@i-con/shared/common';

const TRST_VERTICALS = makeStateKey('verticals');

export interface IVertical {
	id: number;
	value: string;
	text: string;
}

interface IVerticalState {
	data: IVertical[];
}

const initialStateValue: any = {
	data: [{}],
};

@Injectable({ providedIn: 'root' })
export class VerticalService extends StateService<IVerticalState> {
	data$: Observable<IVertical[]> = this.getState((state) => state.data);

	constructor(private http: HttpClient,
		private _environment: EnvironmentService,
		private transferState: TransferState) {
		super(initialStateValue);
		this.getVerticals();
	}

	getVerticals(): void {
		let state_data = this.transferState.get(TRST_VERTICALS, null as any);
		if (state_data) {
			this.setState({ data: state_data });
		}
		else {
			this.http.get<IVerticalResponse>(`${this._environment.getApiUrl}/attendee/verticals`).subscribe((res) => {
				this.setState({ data: res.response.data });
				this.transferState.set(TRST_VERTICALS, res.response.data as any);
			});
		}
	}

}
