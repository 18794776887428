import { Component, Input, OnInit } from '@angular/core';
import { IActivityItem } from '../interfaces/activity-item.interface';

@Component({
  selector: 'activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss'],
})
export class ActivityItemComponent implements OnInit {
    constructor() { }
    @Input() activity: IActivityItem;
    @Input() childIndex: number;
  
    ngOnInit(): void {
    }
}
