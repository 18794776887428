import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IVisionRecapAlbumCardContent } from '../../interfaces/vision-recap-album.interface';

@Component({
  selector: 'vision-recap-album-card-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vision-recap-album-card-section.component.html',
  styleUrl: './vision-recap-album-card-section.component.scss',
})
export class VisionRecapAlbumCardSectionComponent {
  @Input() content: IVisionRecapAlbumCardContent;
}
