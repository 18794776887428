import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITicketBundleType } from '../../../../core/_models/ticket-bundle.interface';
import { TicketBundleStateService } from '../../../../core/_services/tickets/ticket-bundle-state.service';
import { SharedModule } from '../../../../shared/shared.module';
@Component({
  selector: 'bundle-card',
  templateUrl: './bundle-card.component.html',
  styleUrl: './bundle-card.component.scss',
  imports: [SharedModule, CommonModule],
  standalone: true,
})
export class BundleCardComponent {
  @Input() bundle!: ITicketBundleType;
  @Input() color!: string;
  constructor(private route: ActivatedRoute, private router: Router, private readonly ticketBundleStateService: TicketBundleStateService) {}

  getDiscountedPrice() {
    //return (Math.round(this.bundle.ticket.price * (1 - this.bundle.ticket.discounts[0].percentage) * 100) / 100) * this.bundle.realQuantity;
    const currentDate = new Date();
    const endDate = new Date(this.bundle.ticket.discounts[0].endsOn);
    const startDate = new Date(this.bundle.ticket.discounts[0].startsOn);

    if(currentDate <= endDate && currentDate >= startDate) {
      return (Math.round(this.bundle.ticket.price * (1 - this.bundle.ticket.discounts[0].percentage) * 100) / 100) * this.bundle.realQuantity;
    } else {
      return (Math.round(this.bundle.ticket.price)) * this.bundle.realQuantity;
    }

  }

  getDiscountedBundlePrice() {
    return Math.round(this.getDiscountedPrice() * (1 - this.bundle.discounts[0].percentage));
  }

  purchaseClick(bundle: ITicketBundleType) {
    this.ticketBundleStateService.setSelectedBundleType(bundle);

    this.router.navigate([`${this.router.url}/${bundle.ticketQuantity}/purchase`], {
      relativeTo: this.route,
    });
  }
}
