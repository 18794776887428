<div class="booths active-step-{{ this.current_step }}">
	<div class="header">
		<div class="container">
			<h1><span>Get Your</span><br/>ISLAND CONFERENCE BOOTHS</h1>
		</div>
	</div>
	<div class="booth" *ngIf="boothResponseState$ | async as boothResponseState">
		<div class="white-bgr"></div>
		<div class="container" *ngIf="!boothResponseState.success">
			<div #boothselector class="booth-selector" *ngIf="booth_types$ | async as booth_types">
				<div class="tabs-wrapper">
					<div #tabs class="tabs">
						<div
							#tabitems
							class="tab-item"
							*ngFor="let booth of booth_types; let i = index"
							(click)="selectBoothType(booth, i)"
							[ngClass]="{ 'active': selected_booth_type.id === booth.id, 'sold_out': booth.inStock === false || booth.quantity < 10 }"
							data-id="booth-tab-{{ booth.id }}">
							<span>{{ booth.name }}</span>
							<p class="sold_out_label" [ngClass]="{ show: booth.inStock === false }">Sold Out</p>
							<p class="sold_out_label" [ngClass]="{ show: booth.quantity > 0 && booth.quantity < 10 }">{{booth.quantity}} Left</p>
						</div>
						<!-- <div #tabitems class="tab-item custom" (click)="openCustomDialog()">
							<img class="custom-black" src="https://cdn-images.island-conference.com/attend/booths/custom_booths.svg" />
							<img class="custom-white" src="https://cdn-images.island-conference.com/attend/booths/custom_booths_white.svg" />
							<span>Custom</span>
						</div> -->
						<div #tabitemline class="tab-item-line"></div>
					</div>
					<div class="available-spots-text">
						<div *ngIf="this.current_step <= 2">One Step Away ! Drop Your Contact And Billing Info To Get Your Invoice.</div>
						<!-- <div *ngIf="this.current_step <= 2">Available spots</div> -->
						<div *ngIf="this.current_step >= 3">Your Details</div>
					</div>
				</div>
				<div class="text">
					<div *ngIf="this.current_step <= 2">Step {{ this.current_step }} of 2: Choose your Booth</div>
					<!-- <div *ngIf="this.current_step <= 2">Step {{ this.current_step }} of 3: Choose your Booth</div> -->
					<div *ngIf="this.current_step === 3">Step {{ this.current_step }} of 2: Final Step</div>
					<div *ngIf="this.current_step === 4">Reservation Complete</div>
				</div>
				<div class="back-mobile" (click)="goToStep(1)">Back</div>
			</div>
		</div>
		<div class="header-error" *ngIf="!boothResponseState.success">
			<div class="container">
				<p class="header-error" *ngFor="let message of boothResponseState.return_msg">
					{{ message }}
				</p>
			</div>
		</div>
		<select-booth-type 
			class="step-select-booth-type-component"
			(onPreviousNextBoothChanged)="setSelectedBoothByDirection($event)"
			(onNavigateToStep)="goToStep($event)">
		</select-booth-type>
		<!-- <app-reserve-primary-contact-form
			*ngIf="!boothResponseState.success"
			class="reserve-primary-contact-form-component"
			(setPrimaryContact)="setPrimaryContact($event)" 
			(onNavigateToStep)="goToStep($event)"
			(onNavigateToBoothTypeId)="selectBoothTypeById($event)"
			(onSelectedBoothNumChanged)="updatedBoothTypeById($event)"
			[current_step]="current_step">
		</app-reserve-primary-contact-form> -->
		<app-reserve-booking-form
			*ngIf="!boothResponseState.success"
			class="reserve-primary-contact-form-component"
			(setBookingForm)="setBookingForm($event)" 
			(setBookingFormLoggedIn)="setBookingFormLoggedIn($event)"
			(onNavigateToStep)="goToStep($event)"
			(onNavigateToBoothTypeId)="selectBoothTypeById($event)"
			(onSelectedBoothNumChanged)="updatedBoothTypeById($event)"
			[current_step]="current_step"
			[errorMessages]="[boothResponseState.return_msg]"
		>
		</app-reserve-booking-form>
		<!-- <select-booth-and-reserve
			class="step-select-and-reserve-component"
			(onNavigateToStep)="goToStep($event)"
			(onNavigateToBoothTypeId)="selectBoothTypeById($event)"
			[current_step]="current_step">
		</select-booth-and-reserve> -->


		<div class="success-container" *ngIf="boothResponseState.success">
			<div class="container">
				<!-- <div class="success-msg flexr">
					<div class="left">
						<h2>Thank you for your request!</h2>
						<p>
							A member of our team will be in touch with you shortly. Please be patient as we get back
							you.
							<br/><br/>
							We would advise on checking your spam folder if you have not received a reply from us within
							48 hours.
						</p>
						<a class="main-btn" href="/attend/booths">< Back to Booths</a>
					</div>
					<div class="right">
						<img class="success" src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
					</div>
				</div> -->
				<successful-api-call
				 heading="Reservation Successful" [subText]='successData'
				 (goBackInitialPage)="goToBoothSelection($event)"
				 ></successful-api-call>
			</div>
		</div>
	</div>
</div>
