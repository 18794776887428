import { IVisionRecapAlbumCardContent } from '../interfaces/vision-recap-album.interface';

export const contentData: IVisionRecapAlbumCardContent[] = [
  {
    header: 'Day 0',
    title: 'Get warmed up before business takes over!',
    description:
      "With a lot of anticipation building up, i-Con hosts a vibrant welcome party in the heart of Limassol! There's nothing better to give you a taste of the days to come. Enjoy the amazing atmosphere and feast on an explosion of flavours. Experience excitement like no other and get a sneak peek on the networking and valuable connections, as well as the renowned Haze events!",
    cards: [
      {
        day: 'Day 0',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/sky-suite-private-party/thumbnails/cover.png',
        title: 'Sky Suite Private Party',
        items: 16,
      },
      {
        day: 'Day 0',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-0/welcome-sunset-party/thumbnails/cover.png',
        title: 'Welcome Sunset Party',
        items: 19,
      },
    ],
  },
  {
    header: 'Day 1',
    title: 'i-Con has opened up its doors and the time to meet the future of affiliate conferences has finally come!',
    description:
      "Discover the highlights of i-Con's first day! Innovate alongside experts, influencers and professionals in the digital marketing world. Our featured albums outline the beauty of our Exhibit, the value of our speeches and the magic of our parties and events! Come along and let yourself be a part of the most anticipated affiliate conference of the year!",
    cards: [
      {
        day: 'Day 1',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/exhibition/thumbnails/cover.png',
        title: 'Exhibition',
        items: 28,
      },
      {
        day: 'Day 1',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/speeches/thumbnails/cover.png',
        title: 'Speeches',
        items: 10,
      },
      {
        day: 'Day 1',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/aura-lunch/thumbnails/cover.png',
        title: 'Aura Lunch Break',
        items: 6,
      },
      {
        day: 'Day 1',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/opening-party/thumbnails/cover.png',
        title: 'i-Conic Opening Party',
        items: 11,
      },
      {
        day: 'Day 1',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-1/registration/cover.jpg',
        title: 'Registration',
        items: 6,
      },
    ],
  },
  {
    header: 'Day 2',
    title: 'Another day filled with networking, connections, delicious food, and, of course, entertainment!',
    description:
      "Turning page on another incredible networking-filled day, i-Con presents you with even more knowledge, expertise and, of course, fun! Discover your new and improved conference experience and witness it come to an end with the colourful sunset on Lady's Mile beach!",
    cards: [
      {
        day: 'Day 2',

        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/yoga-session/thumbnails/cover.png',
        title: 'Spiritual Yoga Session',
        items: 5,
      },
      {
        day: 'Day 2',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/exhibition/thumbnails/cover.png',
        title: 'Exhibition',
        items: 20,
      },
      {
        day: 'Day 2',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/speeches/thumbnails/cover.png',
        title: 'Speeches',
        items: 10,
      },
      {
        day: 'Day 2',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/aura-lunch/thumbnails/cover.png',
        title: 'Aura Lunch Break',
        items: 6,
      },
      {
        day: 'Day 2',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/day-2/closing-party/thumbnails/cover.png',
        title: 'Closing Party',
        items: 11,
      },
    ],
  },
  {
    imgSrc: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos.svg',
    imgAlt: 'Miconos',
    title: `The event might be over but the party's just getting started!`,
    description:
      'Go on a trip alongside CEOs, Business Owners and Super Affiliates from the biggest names in the industry! Venture out to a never-seen before, invitation-only journey and make valuable connections with your peers and fellow executives!',
    cards: [
      {
        day: 'Miconos',
        imageUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos/sky-suite-private-party/thumbnails/cover.png',
        title: 'Miconos VIP Trip',
        items: 31,
      },
    ],
  },
];
