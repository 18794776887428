<div class="content">
    <h1>{{content.title}}</h1>
    <img class="logo-2025" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2>{{content.description}}</h2>

    <div class="buttons">
        <button (click)="onClick(btn)" class="main-btn" [ngClass]="{'purple' : i === 1}"
            *ngFor="let btn of content.surveyAnswer; let i = index">{{btn.description}} <div *ngIf="i === 1"
                class="dots">
                <div class="dot" *ngFor="let dot of [].constructor(3)"></div>
            </div></button>
    </div>
</div>