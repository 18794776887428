import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IImageExpandedData } from '../../interfaces/vision-recap-album.interface';

@Component({
  selector: 'i-con-vision-recap-album-gallery-image-expanded',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vision-recap-album-gallery-image-expanded.component.html',
  styleUrl: './vision-recap-album-gallery-image-expanded.component.scss',
})
export class VisionRecapAlbumGalleryImageExpandedComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IImageExpandedData, public dialogRef: MatDialogRef<VisionRecapAlbumGalleryImageExpandedComponent>,
  ) {
    addEventListener('keydown', event => {
      if (event.key === 'ArrowLeft') this.backClick();
      else if (event.key === 'ArrowRight') this.nextClick();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backClick() {
    if (this.data.index === 0) {
      this.data.index = this.data.gallery.images.length;
    }
    this.data.index--;
  }

  nextClick() {
    if (this.data.index === this.data.gallery.images.length - 1) {
      this.data.index = -1;
    }
    this.data.index++;
  }
}
