import { IBaseResponse } from '@i-con/shared/common';

export interface IQuestionnaire {
  id: number;
  title: string;
  description: string;
  type: QuestionType;
  order: number;
  imageUrl: string;
  surveyAnswer: ISurveyAnswer[];
}

export interface ISurveyAnswer {
  id: number;
  description: string | null;
  value: string;
  nextQuestion: number | null;
  imageUrl: string[];
  category?: string | null;
  surveySubAnswers?: ISpeakerInfo[];
}

export interface ISpeakerInfo {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
}

export enum QuestionType {
  FACES,
  MULTISELECT,
  RATING,
  CLOSED, // (yes/no)
  MULTISELECTGROUP,
  FACESEVENTS,
  MULTISELECTEVENTS,
  STARS,
  TEXTAREA,
  SELECT,
  SIMPLE,
  SIMPLEBUTTONS,
  THANKYOU,
}

export interface IQuestionnaireResponse extends IBaseResponse {
  response: {
    data: IQuestionnaire[];
    message?: string;
    statusCode: number;
  };
}

export interface ISpeakerChecked {
  speaker: ISpeakerInfo;
  answerId: number;
  isChecked: boolean;
}
export interface IQuestionnaireUserAnswers {
  questionId: number;
  answers: [{ answerId: number; value: string[]; description?: string; subAnswers?: number[] }];
}

export interface IQuestionnaireFinalResponse {
  attendeeResponse: IQuestionnaireUserAnswers[];
}

export interface IQuestionnaireFinalResponseFromAPI extends IBaseResponse {
  response: {
    data: IQuestionnaireFinalResponse[];
    message?: string;
    statusCode: number;
  };
}

export interface IErrorHandler {
  message: string;
  statusCode: number;
  progressBar: number;
  imageUrl: string;
}

export interface IRenderedView {
  loading: boolean;
  apiCallSucceeded: boolean;
}

export interface ISurveyViewState {
  errorHandler: IErrorHandler;
  renderedView: IRenderedView;
}
