import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IBoothType } from '../../core/_models/booth.interface';
import { BoothService } from '../../core/_services';
import { events } from './data/events-data';
import { IData } from './interfaces/events-data.interface';

@Component({
  selector: 'app-plan-schedule',
  templateUrl: './plan-schedule.component.html',
  styleUrls: ['./plan-schedule.component.scss'],
})
export class PlanScheduleComponent implements OnInit {
  @ViewChild('selector', { static: false }) selector: MatSelect;
  @Input() class_name: string = 'planschedule_page';
  phrase = `Navigate through`;
  title1 = `i-CON'S SCHEDULE`;
  selected = 'events';
  available_types = ['events', 'speakers'];
  validParam = false;
  data: IData[] = events;
  //booths: IBoothInfo[] = boothData;
  booths: IBoothType[] = [];
  boothData$: Observable<IBoothType[]>;
  constructor(private route: ActivatedRoute, private router: Router, private boothService: BoothService) {
    // this.route.paramMap.subscribe((params: ParamMap) => {
    //   const param = params.get('type')?.toLowerCase();
    //   const index = this.available_types.indexOf(param!);
    //   if (index !== -1) {
    //     this.validParam = true;
    //     this.selected = this.available_types[index];
    //     this.title1 = this.selected === 'events' ? `i-CON'S SCHEDULE` : `i-CON'S AFFILIATE SCHEDULE`;
    //   }
    // });
  }

  ngOnInit(): void {
    this.boothData$ = this.boothService.data$;
    this.boothData$.subscribe(data => {
      this.booths = data;
    });

  }

  onSelectionChange(event: any) {
    this.selected = this.selector.value;
    this.title1 = this.selected === 'events' ? `i-CON'S SCHEDULE` : `i-CON'S AFFILIATE SCHEDULE`;
    this.selector.close();
  }

  // openReserveDialog(header_title: string, event_class: string | null) {
  //   event?.preventDefault();
  //   this.dialog.open(EventDialogFormComponent, {
  //     maxHeight: '80vh',
  //     maxWidth: '95vw',
  //     width: '850px',
  //     autoFocus: false,
  //     data: { header_title, event_class },
  //     disableClose: true,
  //   });
  // }

  // openVIPReserveDialog(header_title: string, event_class: string | null) {
  //   event?.preventDefault();
  //   this.dialog.open(VipTableFormComponent, {
  //     maxHeight: '80vh',
  //     maxWidth: '95vw',
  //     width: '850px',
  //     autoFocus: false,
  //     data: { header_title, event_class },
  //     disableClose: true,
  //   });
  // }

  // openGuestListDialog(header_title: string, event_class: string | null, sponsor: string) {
  //   event?.preventDefault();
  //   this.dialog.open(GuestListFormComponent, {
  //     maxHeight: '80vh',
  //     maxWidth: '95vw',
  //     width: '850px',
  //     autoFocus: false,
  //     data: { header_title, event_class, sponsor },
  //     disableClose: true,
  //   });
  //}
}
