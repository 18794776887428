import { Component, Input } from '@angular/core';
import { ICard } from '../interfaces/winning-pitch.interface';

@Component({
  selector: 'winning-pitch-card',
  templateUrl: './winning-pitch-card.component.html',
  styleUrls: ['./winning-pitch-card.component.scss'],
})
export class WinningPitchCardComponent {
  @Input() card!: ICard;
}
