import { Injectable } from "@angular/core";
import { TransferState, makeStateKey } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IQuestionsResponse } from "../../_models/responses/questions-response.interface";
import { IAnswerResponse } from "../../_models/responses/answer-response.interface";
import { EnvironmentService, StateService } from "@i-con/shared/common";


const TRST_QUESTIONS = makeStateKey('questions');

interface IQuestionState {
    data: IQuestionsResponse;
    postData: IAnswerResponse
}

const initialStateValue: any = {
    data: [{}],
    postData: {}
}


@Injectable({providedIn: 'root'})
export class DynamicServeyService extends StateService<IQuestionState> {

    data$: Observable<IQuestionsResponse> = this.getState((state) => state.data)
    postData$: Observable<IAnswerResponse> = this.getState((state) => state.postData);

    constructor(
        private http: HttpClient,
        private _environment: EnvironmentService,
        private transferState: TransferState
    ){
        super(initialStateValue)
    }

    getQuestions(targetId: string | null): void {
        let stateData = this.transferState.get(TRST_QUESTIONS, null as any);

        if (stateData) {
            this.setState({data: stateData})
        }
        else {
            this.http.get<IQuestionsResponse>(`${this._environment.getApiUrl}/survey?targetId=` + targetId).subscribe((res) => {
                this.setState({data: res})
            })
        }
    }

    postQuestions(answerList: object) {
        this.http
        .post<IAnswerResponse>(`${this._environment.getApiUrl}/survey`, answerList)
        .subscribe({
            error: (error: any) => {
                this.setState({ postData: error.error });
            },
            next: (resposnse: any) => {
                this.setState({ postData: resposnse });
            },
        });
    }

}