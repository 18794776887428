<div class="content">
    <h1>{{content.title}}</h1>
    <img class="astro" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2 [innerHTML]="content.description"></h2>
    <div class="options">
        <div class="left"> <mat-checkbox class="check"
                *ngFor="let chk of content.surveyAnswer | slice:0:6; let i = index" [value]="chk.value"
                [disabled]="checkedValues && checkedValues.answers && checkedValues.answers.length >= 5 && !isValueIncluded(chk.value)"
                (change)="handleCheckboxChange(chk)">
                {{ chk.description }}
            </mat-checkbox>
        </div>

        <div class="right">
            <mat-checkbox class="check" *ngFor="let chk of content.surveyAnswer | slice:6; let i = index"
                [value]="chk.value"
                [disabled]="checkedValues && checkedValues.answers && checkedValues.answers.length >= 5 && !isValueIncluded(chk.value)"
                (change)="handleCheckboxChange(chk)">
                {{ chk.description }}
            </mat-checkbox>
            <input [(ngModel)]="otherValue" placeholder="Type here..." class="otherInput" type="text"
                *ngIf="isValueIncluded('other')" (ngModelChange)="handleInputChange($event)" [required]="true" />
        </div>


    </div>

    <i-con-ctabuttons [nextEnabled]="shouldNextBeEnabled()" (nextClick)="nextClick()"></i-con-ctabuttons>

</div>