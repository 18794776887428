import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IQuestionnaireUserAnswers } from '../interface/questionnaire.interface';

@Injectable({
  providedIn: 'root',
})
export class UserAnswersService {
  private subject = new BehaviorSubject<IQuestionnaireUserAnswers[]>([]);
  userAnswers$: Observable<IQuestionnaireUserAnswers[]> = this.subject.asObservable();

  scrollToTop() {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }

  pushNewAnswer(newAnswer: IQuestionnaireUserAnswers) {
    const currentAnswers = this.subject.value;
    const updatedAnswers = [...currentAnswers, newAnswer];
    this.subject.next(updatedAnswers);
  }

  removeLastAnswer() {
    const currentAnswers = this.subject.value;
    currentAnswers.pop(); // Remove the last element
    this.subject.next(currentAnswers);
  }

  getUserAnswers(): IQuestionnaireUserAnswers[] {
    return this.subject.value;
  }
}
