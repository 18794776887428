<div class="main-container">
    <div class="winning-header">
        <h3>{{phrase}}</h3>
        <h1>{{title1}}</h1>
    </div>
    <section class="rocketSection">
        <div class="paragraphs container">
            <p>In its mission to support Cyprus' entrepreneurial landscape, i-CON 2024 aspires to provide visionary
                small companies with growth potential a unique platform to shine. On May 30th and 31st, 2024, our
                conference will gather over 100 international companies and more than 1,000 attendees. The objective is
                clear: to offer talented entrepreneurs an ideal stage for raising awareness of their businesses, engage
                with professionals from high-tech industries across the globe, and explore avenues for fundraising
                through our "Winning Pitch Deck"!</p>
            <p>Through our official website, entrepreneurs can submit their applications to participate in the
                conference, along with a brief overview of their emerging business concepts. i-CON's team will evaluate
                and prioritize applications related to technological innovation, spanning Online Applications, Fintech
                Solutions, Artificial Intelligence, Cybersecurity, and other industry-relevant topics.</p>

        </div>
        <div class="rocketContainer">
            <div class="line"></div>
            <img src="https://cdn-images.island-conference.com/attend/winning-pitch/rocket.svg" alt="Image of a rocket">
        </div>
    </section>

    <div class="fullContainer">
        <div class="container">
            <h2>The selected emerging companies will be allocated a dedicated space at the "Winning Pitch Deck" located
                at the "Speakers Area" of our Conference in order to showcase their ideas. In addition to this, each
                participant will receive comprehensive support, which includes:
            </h2>
            <div class="cards">
                <winning-pitch-card *ngFor="let card of cards" [card]="card"></winning-pitch-card>
            </div>

            <div class="afterCards">
                <span>A group of 7-8 ventures will receive an invitation to participate in our conference.</span>
                <span class="middle">WILL YOU BE AMONG THEM?</span>
                <button class="main-btn" (click)="openFormDialog()">SUBMIT YOUR INTEREST NOW</button>
            </div>
        </div>
    </div>
</div>