import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { ISponsorshipTypeResponse } from '../../_models/responses/sponsorship-type-response.interface';
import { ISponsorshipType } from './../../_models/sposnorships.interface';
import { EnvironmentService, StateService } from '@i-con/shared/common';

const TRST_SPONSORSHIPS = makeStateKey('sponsorships');

interface ISponsorshipState {
	data: ISponsorshipType[];
	sortedSponsorhip: ISponsorshipType[];
}

const initialStateValue: ISponsorshipState = {
	data: [{
		id: 0,
		name: '',
		description: '',
		quantity: 0,
		inStock: false,
		price: 0,
		status: 0,
		backgroundImage: '',
		reservedBy: [],
		metadata: '',
		maxCapacity: 0,
		boothCombo: [{
			inStock: false,
			name: '',
			referenceName: '',
			size: '',
			price: 0,
			color: '',
			floorSize: 0,
			boothTicketType: [],
			modelUrl: '',
			positionWeight: 0,
			discounts:[],
			includes: [],
			comboImageUrl: '',
			quantity: 0
		}],
		discounts: [{
			id: 0,
			percentage: 1,
			name: '',
			description: '',
			startsOn: new Date(),
			endsOn: new Date()
		}],
		class: 0
	}],
	sortedSponsorhip: [{
		id: 0,
		name: '',
		description: '',
		quantity: 0,
		inStock: false,
		price: 0,
		status: 0,
		maxCapacity: 0,
		backgroundImage: '',
		reservedBy: [],
		metadata: '',
		boothCombo: [{
			inStock: false,
			name: '',
			referenceName: '',
			size: '',
			price: 0,
			color: '',
			floorSize: 0,
			boothTicketType: [],
			modelUrl: '',
			positionWeight: 0,
			discounts:[],
			includes: [],
			comboImageUrl: '',
			quantity: 0
		}],
		discounts: [{
			id: 0,
			percentage: 1,
			name: '',
			description: '',
			startsOn: new Date(),
			endsOn: new Date()
		}],
		class: 0
	}]
};

@Injectable({ providedIn: 'root' })
export class SponsorshipService extends StateService<ISponsorshipState> {
	data$: Observable<ISponsorshipType[]> = this.getState((state) => state.data);
	sortedSponsorhip$: Observable<ISponsorshipType[]> = this.getState((state) => state.sortedSponsorhip);

	constructor(private http: HttpClient,
		private _environment: EnvironmentService,
		private transferState: TransferState) {
		super(initialStateValue);
		//this.getSponsorships();
	}

	getSponsorships(): void {

		const state_data = this.transferState.get(TRST_SPONSORSHIPS, null as any);
		if (state_data) {
			this.setState({ data: state_data });
		}
		else {
			this.http.get<ISponsorshipTypeResponse>(`${this._environment.getApiUrl}/inventory/sponsorship`).subscribe((res) => {
				res.response.data.sort((a, b) => b.price - a.price);
				this.setState({ data: res.response.data });
				this.setState({ sortedSponsorhip: res.response.data });
				this.transferState.set(TRST_SPONSORSHIPS, res.response.data as any);
			});
		}
	}

	addValues(sortedArray: ISponsorshipType[]){
		this.setState({
			sortedSponsorhip: sortedArray
		})
	}

}
