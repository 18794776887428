<div class="bundle">
    <div class="soldout_label" *ngIf="!bundle.inStock">Sold Out</div>
    <div class="ticket">
        <img src="https://cdn-images.island-conference.com/attend/{{bundle.imageFilename}}" alt="">

        <div class="details">
            <span class="name">{{bundle.name}}</span>
            <span class="label c{{color}}">{{bundle.label}}</span>

        </div>
    </div>

    <hr *ngIf="bundle.discounts.length !== 0">

    <div class="prices">

        <div class="priceInitial" [ngClass]="{'strikethrough': bundle.discounts.length !== 0}">
            <!-- <img class="image" *ngIf="bundle.discounts.length !== 0" src="https://cdn-images.island-conference.com/attend/tickets/strike_through-{{color}}.svg" alt=""> -->
            <div class="strikeline c{{color}}" *ngIf="bundle.discounts.length !== 0"></div>
            <span class="price">{{getDiscountedPrice() | currency:'EUR':"symbol":'1.0-0'}}</span>
        </div>

        <div *ngIf="bundle.discounts.length !== 0" class="salePrice">
            <span class="salePriceSpan">SALE PRICE</span>
            <span class="price">{{getDiscountedBundlePrice() | currency:'EUR':"symbol":'1.0-0'}}</span>
        </div>

    </div>

    <div class="button">
        <button class="main-btn" (click)="purchaseClick(bundle)" [disabled]="!bundle.inStock">Purchase</button>
    </div>
</div>