
<!-- <app-pageheader [heading_class]="class_name" [heading_phrase]="phrase" [heading_title1]="title1"
	[heading_title2]="title2"></app-pageheader> -->

<div class="plan">
	<div class="header-wrapper">
		<div class="header">
			<div class="container">
				<h1 class="pageheader"><span>This Is Our Plan For The Future Of</span>AFFILIATE CONFERENCES</h1>
			</div>
		</div>
	</div>

	<div class="first-section section">
		<div class="container flexr">
			<div class="left">
				<div class="content">
					<h3>Innovate alongside the largest names in the digital marketing game!</h3>
					<p>
						Join an ocean of digital marketing professionals in one of the most anticipated networking events of the year! There's never been a better place and time to close deals, make partnerships and increase your brand's reach and exposure!
						<br/><br/>
						We're not all about networking though! Island Conference gives you access to valuable interactive panels, interviews and speeches conducted by well-known personalities of the affiliate world. Don't miss your chance to gain insights on the latest tactics and trends you should be taking advantage of!
					</p>
				</div>
			</div>
			<div class="right">
				<div></div>
				<!-- <img src="https://cdn-images.island-conference.com/plan/plan-1.jpg" /> -->
			</div>
		</div>
	</div>
	
	<div class="speakers section">
		<div class="top-row">
			<div class="container">
				<h3>KEYNOTE SPEAKERS</h3>
					<p>
						Discover how some of the most successful names in the online marketing world got where they are today. Gain valuable insights, learn about the latest strategies and benefit from our keynote speakers, and elevate your company to new heights!
					</p> 
			</div>
		</div>
		<div class="bottom-row">
			<div class="container">
				<div class="type-speakers">
					<div>
						<img src="https://cdn-images.island-conference.com/plan/clevel.svg"/>
						<p>C-Level<br/>Executives</p>
					</div>
					<div>
						<img src="https://cdn-images.island-conference.com/plan/seo.svg"/>
						<p>SEO<br/>Experts</p>
					</div>
					<div>
						<img src="https://cdn-images.island-conference.com/plan/buy.svg"/>
						<p>Media Buying<br/>Professionals</p>
					</div>
					<div>
						<img src="https://cdn-images.island-conference.com/plan/cart.svg"/>
						<p>Sales<br/>Administrators</p>
					</div>
				</div>
				<p>and many more!</p>
				<app-nav-button class="main-btn" [type]="'internal'" [new_path]="['/plan/speakers']"
				[text]="'View More'"></app-nav-button>
			</div>
		</div>
	</div>

	<div class="events section">
		<div class="container">
			<h3>LIFESTYLE & NETWORKING EVENTS</h3>
			<p>i-Con also comes with an extended list of networking opportunities outside the professional setting of our Expo. Having partnered up with Haze, a lifestyle event organizer based in Cyprus, we bring you some of the most thrilling and exclusive events on the island! Enjoy our scheduled parties featuring performers as well as resident and guest DJs from some of the hottest Greek clubs! This is your chance to network in a more relaxed setting, but also party the night away after two days filled with business opportunities and knowledge!</p>
			<app-plan-events [referenceClass]="'plan-page'"></app-plan-events>
		</div>
	</div>

	<div class="schedule section">
		<div class="container">
			<div class="dates-container">
				<div class="dates">
					<h2>30<sup>th</sup></h2>
					<h3>MAY</h3>
				</div>
				<div class="dates second">
					<h2>31<sup>st</sup></h2>
					<h3>MAY</h3>
				</div>
			</div>
			<div class="content">
				<h3>Find us in all events featured on the <span>ISLAND CONFERENCE SCHEDULE</span></h3>
				<p>
					Our conference schedule is perfectly designed to give each and every single one of our attendees the opportunity to meet, 
					network and partner up with whoever they want without having to worry about running out of time. Our conference speeches are scheduled in a way that allows everyone to attend 
					Pair that up with some chill-out time by the beer garden and we can guarantee that our 2-day affiliate conference will keep you busy!</p>
				<app-nav-button class="main-btn" [type]="'internal'" [new_path]="['/plan/schedule']"
							[text]="'View Schedule'"></app-nav-button>
			</div>
		</div>
	</div>

</div>
