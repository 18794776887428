import { Component, InjectionToken, Injector, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { StateService } from '@i-con/shared/common';
import { Observable, Subscription } from 'rxjs';
import { AttendPresentationsComponent } from './components/attendpresentations/attendpresentations.component';
import { EventsLikeComponent } from './components/events-like/events-like.component';
import { EventsSatisfiedComponent } from './components/events-satisfied/events-satisfied.component';
import { FindOutComponent } from './components/findout/findout.component';
import { GoodTimeComponent } from './components/good-time/good-time.component';
import { HazeEventsComponent } from './components/haze-events/haze-events.component';
import { HowHappyComponent } from './components/howhappy/howhappy.component';
import { ICon2025Component } from './components/i-con-2025/i-con-2025.component';
import { ImprovementsComponent } from './components/improvements/improvements.component';
import { NotAttendComponent } from './components/notattend/notattend.component';
import { RateFeaturesComponent } from './components/ratefeatures/ratefeatures.component';
import { SeeYouNextYearComponent } from './components/see-you-next-year/see-you-next-year.component';
import { SpeakersComponent } from './components/speakers/speakers.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { IErrorHandler, IQuestionnaire, IRenderedView, ISurveyViewState, QuestionType } from './interface/questionnaire.interface';
import { QuestionnaireService } from './service/questionnaire.service';
import { StepService } from './service/step.service';
import {
  ATTEND_PRESENTATIONS_DATA,
  EVENTS_LIKE_DATA,
  EVENTS_SATISFIED_DATA,
  FIND_OUT_DATA,
  GOOD_TIME_DATA,
  HAZE_EVENTS_DATA,
  HOW_HAPPY_DATA,
  IMPROVEMENTS_DATA,
  I_CON_2025_DATA,
  NOT_ATTEND_DATA,
  RATE_FEATURES_DATA,
  SEE_YOU_DATA,
  SPEAKERS_DATA,
} from './tokens/tokens';

const initialErrorHandlerState: IErrorHandler = {
  message: '',
  statusCode: 0,
  imageUrl: 'https://cdn-images.island-conference.com/questionnaire/no.svg',
  progressBar: 0,
};

const initialRenderedView: IRenderedView = {
  loading: true,
  apiCallSucceeded: false,
};

const initialState: ISurveyViewState = {
  errorHandler: initialErrorHandlerState,
  renderedView: initialRenderedView,
};

@Component({
  selector: 'i-con-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrl: './questionnaire.component.scss',
})
export class QuestionnaireComponent extends StateService<ISurveyViewState> implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private stepService: StepService, private questionnaireService: QuestionnaireService) {
    super(initialState);
  }

  data: IQuestionnaire[] = [];
  progressBar: number = 0;
  questionId: number = 0; //initially will always be 0
  private subscription: Subscription;
  loading: boolean = true;
  apiCallSucceeded: boolean = false;
  errorMessage: string = '';
  emailAddress: string = '';
  statusCode: number = 0;
  errorImageUrl: string = 'https://cdn-images.island-conference.com/questionnaire/no.svg';
  errorObj$!: Observable<IErrorHandler>;
  initialRenderedView$!: Observable<IRenderedView>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentMappings: Map<number, [Type<any>, Injector?]> = new Map<number, [Type<any>, Injector?]>([]);

  ngOnInit(): void {
    this.errorObj$ = this.getState(state => (state as ISurveyViewState).errorHandler);
    this.initialRenderedView$ = this.getState(state => (state as ISurveyViewState).renderedView);
    this.stepService.questionId$.subscribe(questionId => {
      this.questionId = questionId;
      this.progressBar = (this.questionId / this.data.length) * 100;
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.emailAddress = params.get('emailAddress') ?? '';
      this.questionnaireService.setEncodedEmailAddress(this.emailAddress);
    });

    this.errorObj$.subscribe(res => {
      if (res.statusCode === 409) {
        this.errorImageUrl = 'https://cdn-images.island-conference.com/questionnaire/thank-you.png';
        this.progressBar = 100;
      }
    });

    this.componentMappings.set(0, [WelcomeComponent]);
    if (this.questionnaireService.getEncodedEmailAddress().length > 0) {
      this.subscription = this.questionnaireService.getQuestionnaire().subscribe(
        res => {
          this.setState({
            renderedView: {
              loading: false,
              apiCallSucceeded: true,
            },
          });

          this.data = res.response.data.sort((a, b) => a.order - b.order);
          this.data.forEach(item => {
            switch (item.type) {
              case QuestionType.FACES:
                this.setComponent(item.id, HowHappyComponent, HOW_HAPPY_DATA);
                break;

              case QuestionType.MULTISELECT:
                this.setComponent(item.id, FindOutComponent, FIND_OUT_DATA);
                break;

              case QuestionType.RATING:
                this.setComponent(item.id, RateFeaturesComponent, RATE_FEATURES_DATA);
                break;

              case QuestionType.CLOSED:
                this.setComponent(item.id, AttendPresentationsComponent, ATTEND_PRESENTATIONS_DATA);
                break;

              case QuestionType.MULTISELECTGROUP:
                this.setComponent(item.id, SpeakersComponent, SPEAKERS_DATA);
                break;

              case QuestionType.FACESEVENTS:
                this.setComponent(item.id, GoodTimeComponent, GOOD_TIME_DATA);
                break;

              case QuestionType.MULTISELECTEVENTS:
                this.setComponent(item.id, EventsLikeComponent, EVENTS_LIKE_DATA);
                break;

              case QuestionType.STARS:
                this.setComponent(item.id, EventsSatisfiedComponent, EVENTS_SATISFIED_DATA);
                break;

              case QuestionType.TEXTAREA:
                this.setComponent(item.id, ImprovementsComponent, IMPROVEMENTS_DATA);
                break;

              case QuestionType.SELECT:
                this.setComponent(item.id, NotAttendComponent, NOT_ATTEND_DATA);
                break;

              case QuestionType.SIMPLE:
                this.setComponent(item.id, HazeEventsComponent, HAZE_EVENTS_DATA);
                break;

              case QuestionType.SIMPLEBUTTONS:
                this.setComponent(item.id, ICon2025Component, I_CON_2025_DATA);
                break;

              case QuestionType.THANKYOU:
                this.setComponent(item.id, SeeYouNextYearComponent, SEE_YOU_DATA);
                break;

              default:
                this.componentMappings.get(0);
                break;
            }
          });
        },
        err => {
          this.setState({
            errorHandler: {
              message: err.error.response.message,
              statusCode: err.error.response.statusCode,
              progressBar: err.error.response.statusCode === 409 ? 100 : 0,
              imageUrl:
                err.error.response.statusCode === 409
                  ? 'https://cdn-images.island-conference.com/questionnaire/thank-you.png'
                  : 'https://cdn-images.island-conference.com/questionnaire/no.svg',
            },
          });

          this.setState({
            renderedView: {
              loading: false,
              apiCallSucceeded: false,
            },
          });
        }
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setComponent(componentIndex: number, componentName: Type<any>, componentData: InjectionToken<IQuestionnaire>) {
    this.componentMappings.set(componentIndex, [
      componentName,
      Injector.create({ providers: [{ provide: componentData, useValue: this.data[componentIndex - 1] }] }),
    ]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRenderedComponent(): { componentType: Type<any>; injector?: Injector } {
    const [selectedComponent, customInjector] = this.componentMappings.get(this.questionId) || [WelcomeComponent];
    return {
      componentType: selectedComponent,
      injector: customInjector,
    };
  }
}
