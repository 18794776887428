<ng-container *ngIf="selected_booth_type$ | async as selected_booth">
	<div class="step1" *ngIf="selected_booth.name !== ''">
		<div class="container">
			<div class="booth-content">
				<!-- <div class="arrow-left" (click)="viewBooth('previous')">
					<img src="https://cdn-images.island-conference.com/attend/booths/arrow.svg" alt="left" />
				</div> -->
				<div #boothContent class="booths-up-row">
					<div class="content">
						<div class="booth-details">
							<div class="left">
								<img src="https://cdn-images.island-conference.com/attend/booths/{{ selected_booth.id }}/model.png"
									[alt]="selected_booth.name" />
								<div class="floor-size">
									Floor Size
									<span>{{ selected_booth.floorSize }}&#13217;</span>
								</div>
							</div>
						</div>
						<div class="pricing" [ngClass]="{ npactive: !checkEarlyActive(selected_booth.discounts[0].endsOn) === false }">
							<div class="sold-out-label" [ngClass]="{ show: selected_booth.inStock === false }">
								Sold Out
							</div>
							<div class="normal-price">
								<div class="label">Normal Price</div>
								<div class="price">€{{ selected_booth.price | number }}</div>
							</div>
							<div class="early-bird" *ngIf="!checkEarlyActive(selected_booth.discounts[0].endsOn)">
								<div class="label">Early Bird</div>
								<div class="price">
									€{{ selected_booth.price | discountedPrice: selected_booth.discounts | number }}
								</div>
							</div>
							<div class="valid-until" *ngIf="!checkEarlyActive(selected_booth.discounts[0].endsOn)">
								<p>Valid price until {{ formatDate(selected_booth.discounts[0].endsOn) }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="booths-mid-row">
					<div class="content">
						<div class="buttons-container">
							<div class="reserve-button">
								<div *ngIf="this.inStock else availableReservation">
									<radius-button text="Reserve your Booth"
										class="{{ selected_booth.inStock === false ? 'isDisabled' : '' }}"
										disabled="{{ selected_booth.inStock === false ? 'true' : 'false' }}"
										(click)="goToNextStep()">
									</radius-button>

									<span *ngIf="selected_booth.inStock && selected_booth.quantity < 10"
										style="font-weight: 600; font-size: 13px; color: red; margin-top: 10px;">Low
										availability: Only
										{{selected_booth.quantity}} left!
									</span>
								</div>
									
								<ng-template #availableReservation>
									<button class="main-btn" (click)="openSoldoutDialog()">
										Book Tickets Instead
									</button>
								</ng-template>
							</div>
							<!-- <div class="see-photos-btn">
								<button (click)="openBoothPhotosDialog(selected_booth.id)">
									See Photos
								</button>
							</div> -->
						</div>
					</div>
				</div>
				<!-- <div class=" arrow-right" (click)="viewBooth('next')">
					<img src="https://cdn-images.island-conference.com/attend/booths/arrow.svg" alt="right" />
				</div> -->
			</div>
		</div>
		<div class="reserver-mobile-button">
			<div *ngIf="this.inStock else availableReservation">
				<radius-button text="Reserve your Booth"
					class="{{ selected_booth.inStock === false ? 'isDisabled' : '' }}"
					disabled="{{ selected_booth.inStock === false ? 'true' : 'false' }}"
					(click)="goToNextStep()">
				</radius-button>
				<span *ngIf="selected_booth.inStock && selected_booth.quantity < 10"
					style="font-weight: 600; font-size: 13px; color: red; margin-top: 10px;">Low
					availability: Only
					{{selected_booth.quantity}} left!
				</span>
			</div>
				
				<ng-template #availableReservation>
					<button class="main-btn" (click)="openSoldoutDialog()" >
						Book Tickets Instead
					</button>
				</ng-template>

			<!-- <button *ngIf="isInStock() == false"
				class="main-btn disabled"
				*ngIf="selected_booth.inStock == true"
				disabled
				mat-raised-button
				#tooltip="matTooltip"
				matTooltip="Reservations soon to be available"
				matTooltipPosition="below"
				(mouseenter)="$event.stopImmediatePropagation()"
				(mouseleave)="$event.stopImmediatePropagation()"
				(click)="tooltip.show()">
				<mat-icon class="tooltip-icon" matTooltip="Reservations soon to be available"
					>Reserve your Booth</mat-icon
				>
			</button> -->
			<br />
		</div>
		<div class="booth-includes-wrapper">
			<div class="container">
				<div class="includes-header">
					<div class="top-gradient"></div>
					<h2>Booth Includes</h2>
				</div>
				<div class="booth-includes">
					<div class="top-gradient"></div>
					<div class="complimentary-tickets">
						<ng-container *ngFor="let ticket of selected_booth.boothTicketType | orderBy: 'id': 'desc';">
							<div class="cticket">
								<span>{{ ticket.quantity }}</span>
								<img class="ticket-icon" src="https://cdn-images.island-conference.com/attend/booths/ticket_icon_{{ticket.ticketType.color}}.svg" />
								<p>{{ ticket.ticketType.description }}s</p>
							</div>
						</ng-container>
					</div>
					<hr>
					<div class="items">
						<div class="include-item len-{{ selected_booth.includes.length }}"
							*ngFor="let include of selected_booth.includes">
							<img src="https://cdn-images.island-conference.com/attend/booths/{{ selected_booth.id }}/{{ getIconUrl(include) }}" />
							<p>
								{{ getContent(include) }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>