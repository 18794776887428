import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-care',
  templateUrl: './care.component.html',
  styleUrls: ['./care.component.scss']
})
export class CareComponent implements OnInit {

  @Input() class_name:string = 'care_page';
  @Input() phrase: string = `There's no doubt that`;
	@Input() title1: string = 'WE CARE';
  @Input() title2: string = '';

  noplanetb_items = [
    { alt:'Purple-lined vector of a ban circle with a plastic bottle, bag and coffee cup on white background.', img: 'no_plastic.svg', title: 'Plastic-Free', description: `Reducing plastic consumption results in positive environmental changes. Consequently, we've taken a substantial step by removing all single-use plastics from our 2-day affiliate conference.` },
    { alt:'Purple-lined vector of a plastic bottle with two arrows circling around it on white background.Purple-lined vector of a plastic bottle with two arrows circling around it on white background.', img: 'recycled_water.svg', title: 'Recycled Water', description: `In collaboration with NORDAQ, we will be offering a fresh alternative to ordinary bottled water. It emphasizes both taste and environmental responsibility.` },
    { alt:'Purple-lined vector of a straw with two arrows forming a circle in front of it on white background.', img: 'bamboo_straws.svg', title: 'Bamboo Straws', description:  `The chemical-free nature of bamboo straws, makes them safer for the wildlife and people alike. Since we aim to offer the best quality and most sustainable solutions, bamboo straws was the best option for us!`},
    { alt:'Purple-lined vector of two bin bags next to each other on white background.', img: 'waste_disposal.svg', title: 'Waste Disposal', description: `We are developing a comprehensive waste management strategy that includes measures to significantly reduce paper and plastic usage while actively promoting recycling!` },
    { alt:'Purple-lined vector of a bucket with the recycling symbol on it, filled with plastic bottles on white background.Purple-lined vector of a bucket with the recycling symbol on it, filled with plastic bottles on white background.', img: 'reusables.svg', title: 'Reusable Products', description: `Only reusable and eco-friendly products will be provided during the affiliate conference, which will be cleaned and sanitized according to strict food safety standards.` },
    { alt:'Purple-lined vector of a recycle bin with wheels on white background.', img: 'recycle_bins.svg', title: 'Recycle bins', description: `To ensure the sustainability of our networking event, we're introducing a well-structured recycling scheme, complete with the placement of recycling bins throughout the conference venue areas.` },
  ]

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
