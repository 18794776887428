<div class="content">
    <h1>{{content.title}}</h1>
    <img class="speaker" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2>{{content.description}}</h2>
    <mat-radio-group class="reasons" aria-label="Select an option">
        <mat-radio-button [ngClass]="{'lastRadio': i === content.surveyAnswer.length - 1}"
            *ngFor="let reason of content.surveyAnswer; let i = index" [value]="reason"
            (change)="onRadioChange(reason)">{{reason.description}} <input [(ngModel)]="otherValue"
                placeholder="Type here..." class="otherInput" type="text"
                *ngIf="otherSelected && i === content.surveyAnswer.length - 1"
                (ngModelChange)="handleInputChange($event)" [required]="true" /></mat-radio-button>
    </mat-radio-group>


    <i-con-ctabuttons [nextEnabled]="shouldNextBeEnabled()" (nextClick)="nextClick()"
        [shouldJustGoNext]="false"></i-con-ctabuttons>
</div>