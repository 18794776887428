import { Component, Input } from '@angular/core';

@Component({
  selector: 'i-con-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrl: './tab-content.component.scss',
})
export class TabContentComponent {
  @Input() image: string;
}
