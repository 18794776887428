import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-floor-map',
  templateUrl: './floor-map.component.html',
  styleUrls: ['./floor-map.component.scss']
})
export class FloorMapComponent implements OnInit {

  @Input() class_name: string = 'floorplan_page';
  @Input() phrase: string = '';
  @Input() title1: string = 'Check the map';
  @Input() title2: string = 'FIND YOUR SPOT';

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
