<div class="header">
	<button class="close-btn" mat-button mat-dialog-close (click)="closeDialog()">
		<img src="https://cdn-images.island-conference.com/icons/close-icon.svg" alt="Close">
	</button>
	<h3><img src="https://cdn-images.island-conference.com/pageheader/header_logo.png" /> Booths</h3>
	<div class="soldout_label">Sold Out</div>
</div>

<div class="container">
	<div class="content">
		<h4>Our Booths are fully booked!</h4>
		<p>Don't panic though! Our bundle tickets are still available</p>


		<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="soldOutSlideConfig">
			<div class="ticket" ngxSlickItem *ngFor="let type of ticketType; let i = index">
				<h5>{{type.replace("_", " & ")}} Tickets</h5>
				<img
					src="https://cdn-images.island-conference.com/attend/soldout-dialog/{{type.toLowerCase()}}_ticket.png" />
			</div>
		</ngx-slick-carousel>

		<button class="main-btn orange" (click)="navigateToTickets()">Book Your Tickets Now</button>
	</div>
</div>