
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';


@Component({
	selector: 'app-form-attendee',
	templateUrl: './attendee-form.html',
	styleUrls: ['./attendee-form.scss'],
})

export class FormAttendeeComponent implements OnInit{


    constructor(
        private _renderer2: Renderer2, 
        @Inject(DOCUMENT) private _document: Document,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {

        const formId = this.activatedRoute.snapshot.paramMap.get('id');

        let script = this._renderer2.createElement('script');
        script.type = `text/javascript`;
        script.id = `SS_SCRIPT`;
        script.text = `function sparrowLaunch(opts){var e="ss-widget",t="script",a=document,r=window,l=localStorage;var s,n,c,rm=a.getElementById('SS_SCRIPT');r.SS_WIDGET_TOKEN="tt-` + formId +`";r.SS_ACCOUNT="i-con.surveysparrow.com";r.SS_SURVEY_NAME="Attendee-Information1fsdfsd";if(!a.getElementById(e) && !l.getItem('removed-ss-widget-tt-` + formId +`')){var S=function(){S.update(arguments)};S.args=[];S.update=function(e){S.args.push(e)};r.SparrowLauncher=S;s=a.getElementsByTagName(t);c=s[s.length-1];n=a.createElement(t);n.type="text/javascript";n.async=!0;n.id=e;n.src=["https://","i-con.surveysparrow.com/widget/",r.SS_WIDGET_TOKEN,"?","customParams=",JSON.stringify(opts)].join("");c.parentNode.insertBefore(n,c);r.SS_VARIABLES=opts;rm.parentNode.removeChild(rm);}};sparrowLaunch({})`;

        this._renderer2.appendChild(this._document.body, script);
    }

}