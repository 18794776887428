import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { filter, pairwise } from 'rxjs';
import { UrlTrackService } from './shared/services/url-track.service';
// import { RootLayoutComponent } from './shared/root-layout/root-layout.component';
import { SharedModule } from './shared/shared.module';
import { MusicPlayerModule, RootLayoutComponent } from '@i-con/shared/ui';
import { AuthorizationService, ProfileService } from '@i-con/shared/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RootLayoutComponent, SharedModule, MusicPlayerModule],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private urlTrackService: UrlTrackService,
    private profileService: ProfileService,
    private authService: AuthorizationService
  ) {}

  @Input() main_layout: string = '';
  @Input() bgr_class: string = '';

  ngOnInit() {

    if (this.authService.isLoggedIn()) {
      this.profileService.getProfileData();
    }
    

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const active_route = this.getChild(this.activatedRoute);

        active_route.data.subscribe((data: any) => {
          this.main_layout = data.className;
        })

        active_route.data.subscribe((data: any) => {
          this.titleService.setTitle(data.title);
          this.metaService.addTag({
            property: 'og:title',
            content: data.title,
          });
          this.metaService.addTag({
            name: 'description',
            content: data.description,
          });
          this.metaService.addTag({
            property: 'og:description',
            content: data.description,
          });
          this.metaService.addTag({
            property: 'og:image',
            content:
              'https://cdn-images.island-conference.com/homepage/island_conference_open_graph.jpg',
          });
          this.metaService.addTag({ property: 'og:url', content: data.url });
          this.metaService.addTag({ property: 'og:type', content: data.type });
        });
      });

    this.router.events
      .pipe(
        filter((event: any) => event instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.urlTrackService.setPreviousUrl(events[0].urlAfterRedirects);
      });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
