import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs';
import { EventReservationService } from '../../../core/_services/event-reservation/event-reservation.service';
import { IEventReservationTypeResponse } from '../../../core/_models/responses/event-reservation-type-response.interface';
import { CountryCodes } from '../../../shared/form/constants/country-codes';
import { StateService } from '@i-con/shared/common';
declare let window: any;

interface IEventReservationState {
	success: boolean;
	return_msg: string;
}

const initialState: IEventReservationState = {
	success: false,
	return_msg: '',
};


@Component({
	selector: 'app-vip-table-form',
	templateUrl: './vip-table-form.component.html',
	styleUrls: ['./vip-table-form.component.scss']
})
export class VipTableFormComponent extends StateService<IEventReservationState> implements OnInit {

	header_title: string;
	event_class: string;

	selected_countries = CountryISO;

	country_codes = CountryCodes;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: any,
		private formBuilder: UntypedFormBuilder,
		private eventReservationService: EventReservationService
	) {
		super(initialState);
		this.header_title = data.header_title;
		this.event_class = data.event_class;
	}

	SearchCountryField = SearchCountryField;

	reserveTableForm: UntypedFormGroup;
	submitted = false;

	postData$: Observable<IEventReservationTypeResponse>;
	responseState$: Observable<IEventReservationState>;

	ngOnInit(): void {

		this.postData$ = this.eventReservationService.postData$;
		this.responseState$ = this.getState((state) => state);

		this.reserveTableForm = this.formBuilder.group({
			nameControl: ['', [Validators.required, Validators.minLength(2)]],
			surnameControl: ['', [Validators.required, Validators.minLength(2)]],
			emailControl: ['', [Validators.required, Validators.email]],
			companyControl: ['', [Validators.required]],
			phoneControl: ['', [Validators.required]],
			typeControl: ['', [Validators.required]],
		});
	}

	get f() {
		return this.reserveTableForm.controls;
	}

	isOpening(event_class: string): boolean {
		if (event_class == 'opening') {
			return true;
		}
		else {
			return false;
		}
	}

	onSubmit() {
		this.submitted = true;

		if (this.reserveTableForm.invalid) {
			return;
		}

		const reserveration_object = {
			title: this.header_title,
			name: this.reserveTableForm.value.nameControl,
			surname: this.reserveTableForm.value.surnameControl,
			companyName: this.reserveTableForm.value.companyControl,
			dialCode: this.reserveTableForm.value.phoneControl.dialCode,
			country: this.reserveTableForm.value.phoneControl.countryCode,
			phoneNumber: this.reserveTableForm.value.phoneControl.number,
			reservationType: this.reserveTableForm.value.typeControl,
			email: this.reserveTableForm.value.emailControl,
		};

		this.eventReservationService.eventReservation(reserveration_object);

		this.postData$.subscribe((res) => {
			if (res.response?.statusCode == 201) {

				if (this.event_class === 'opening') {
					window?.fbq('trackCustom', 'VipOpeningSubmission');
				} else if (this.event_class === 'pre-event') {
					window?.fbq('trackCustom', 'VipPreEventSubmission');
				} else if (this.event_class === 'closing') {
					window?.fbq('trackCustom', 'VipClosingSubmission');
				}

				this.setState({
					success: true,
					return_msg: '',
				});
			} else {
				this.setState({
					success: false,
					return_msg: res.response?.message,
				});
			}
		});
	}

}
