<div class="dialog-container">
	<div class="top">
		<button class="close-btn" mat-button mat-dialog-close>X</button>
		<img class="logo" src="https://cdn-images.island-conference.com/attend/booths/logo_purple.png" />
	</div>
	<div class="bottom">
		<div class="context">
			<img class="custom" src="https://cdn-images.island-conference.com/attend/booths/custom_booths.svg" />
			<h2>Custom Booths</h2>
			<h3>Here at Island Conference, we have the ability to turn your ideas into reality.</h3>
			<p>
				Contact our support team and discuss the details for a custom booth. Based on your custom requests, we
				will adjust the price and the offerings accordingly.
			</p>
			<button class="main-btn" (click)="contactUs()">Contact Us</button>
		</div>
	</div>
</div>
