import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
// import { IBoothType } from '@i-con/shared/common'
import { IBoothType } from "../../_models/booth.interface";
import { IBoothArrangementResponse } from '../../_models/responses/booth-arrangement-response-interface';
import { IBoothTypeResponse } from '../../_models/responses/booth-type-response.interface';
import { EnvironmentService, StateService } from '@i-con/shared/common';

const TRST_BOOTHSATTEND = makeStateKey('boothsattend');

interface IBoothState {
	data: IBoothType[];
}

const initialStateValue: any = {
	data: [{}],
};

@Injectable({ providedIn: 'root' })
export class BoothService extends StateService<IBoothState> {
	data$: Observable<IBoothType[]> = this.getState((state) => state.data);

	constructor(private http: HttpClient,
		private _environment: EnvironmentService,
		private transferState: TransferState) {
		super(initialStateValue);
		this.getBoothsAttend();
	}

	getBoothsAttend(): void {
		const state_data = this.transferState.get(TRST_BOOTHSATTEND, null as any);
		if (state_data) {
			this.setState({ data: state_data });
		}
		else {
			this.http
				.get<IBoothTypeResponse>(`${this._environment.getApiUrl}/inventory/booth`)
				.subscribe((res: IBoothTypeResponse) => {
					this.setState({ data: res.response.data.sort((a, b) => a.positionWeight - b.positionWeight) });
					this.transferState.set(TRST_BOOTHSATTEND, res.response.data.sort((a, b) => a.positionWeight - b.positionWeight) as any);
				});
		}
	}

	getBooths(): Observable<IBoothTypeResponse> {
		return this.http.get<IBoothTypeResponse>(`${this._environment.getApiUrl}/inventory/booth`);
	}
	getBoothsAvailability(): Observable<IBoothArrangementResponse> {
		return this.http.get<IBoothArrangementResponse>(`${this._environment.getApiUrl}/booth-availability`);
	}
}
