<div class="content">
    <h1>{{content.title}}</h1>
    <img class="speaker" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2>{{content.description}}</h2>


    <mat-accordion>
        <mat-expansion-panel *ngFor="let panel of content.surveyAnswer">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{panel.description}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="speakerCards">
                <i-con-speaker-card *ngFor="let speaker of panel.surveySubAnswers" [speaker]="speaker"
                    (checkboxChange)="onCheckboxChange($event)" [answerId]="panel.id"></i-con-speaker-card>
            </div>
        </mat-expansion-panel>


    </mat-accordion>
    <i-con-ctabuttons [shouldJustGoNext]="false" [nextEnabled]="selectedItem.answers.length > 1"
        (nextClick)="nextClick()"></i-con-ctabuttons>
</div>