import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { contentData } from '../data/vision-recap-album-data';
import { galleryData } from '../data/vision-recap-album-gallery-data';
import { IGallery, IImageExpandedData, IVisionRecapAlbumCardContent } from '../interfaces/vision-recap-album.interface';
import { VisionRecapAlbumGalleryImageExpandedComponent } from './vision-recap-album-gallery-image-expanded/vision-recap-album-gallery-image-expanded.component';

@Component({
  selector: 'vision-recap-album-gallery',
  standalone: true,
  templateUrl: './vision-recap-album-gallery.component.html',
  styleUrl: './vision-recap-album-gallery.component.scss',
  imports: [CommonModule, SharedModule],
})
export class VisionRecapAlbumGalleryComponent {
  @Input() gallery: IGallery;
  galleryData: IGallery[] = galleryData;
  albumsData: IVisionRecapAlbumCardContent[] = contentData;
  currentCategory: string = '';
  currentDay: string = '';
  data: IImageExpandedData;
  constructor(private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentDay = params.get('day')!.toLowerCase();
      this.currentCategory = params.get('gallery')!.toLowerCase();
      this.gallery = this.galleryData.find(
        item => item.header.replace(/ /g, '-').toLowerCase() === this.currentDay && item.title.replace(/ /g, '-').toLowerCase() === this.currentCategory
      )!;
    });
  }
  backClick() {
    this.router.navigate(['vision/icon-recap/albums/photo-albums'], {
      fragment: this.currentDay,
    });
  }

  openImage(index: number, type: number) {
    this.data = {
      gallery: this.gallery,
      index: index,
      type: type
    };

    this.dialog.open(VisionRecapAlbumGalleryImageExpandedComponent, {
      maxWidth: '95vw',
      height: 'auto',
      maxHeight: '100vh',
      data: this.data,
    });
  }
}
