import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BoothStateService } from './_services/booths/booth-state.service';
import { PlatformService } from '@i-con/shared/common';


@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [BoothStateService],
})
export class CoreModule {
	public constructor(
		@SkipSelf() @Optional() parentModule: CoreModule,
		iconRegistry: MatIconRegistry,
		domSanitizer: DomSanitizer,
		private platformService: PlatformService
	) {
		if (parentModule) {
			// Core module should only be loaded once (most likely in the root module).
			// This check acts as a safeguard against accidentally importing the core module multiple times.
			throw new Error(`${parentModule.constructor.name} has already been loaded.`);
		}

		iconRegistry.setDefaultFontSetClass('material-icons-outlined');
		// Register custom svg icons
		this.registerCustomSVGIcons(iconRegistry, domSanitizer);
	}

	/**
	 * https://material.angular.io/components/icon/overview#icon-sets
	 */
	private registerCustomSVGIcons(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {

		iconRegistry.addSvgIconInNamespace(
			'app',
			'tick',
			domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/tick.svg')
		);
		iconRegistry.addSvgIconInNamespace(
			'app',
			'chevron',
			domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-down.svg')
		);
		iconRegistry.addSvgIconInNamespace(
			'app',
			'chevron-right',
			domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-right.svg')
		);
		iconRegistry.addSvgIconInNamespace(
			'app',
			'chevron-left',
			domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-left.svg')
		);

		iconRegistry.addSvgIconInNamespace(
			'app',
			'close-icon',
			domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/close-icon.svg')
		);
	}
}
