import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {  Router } from '@angular/router';

@Component({
    selector: 'app-custom-booth-dialog',
    templateUrl: './custom-booth-dialog.component.html',
    styleUrls: ['./custom-booth-dialog.component.scss']
})
export class CustomBoothDialogComponent implements OnInit {

    constructor(private router: Router,
        public dialogRef: MatDialogRef<CustomBoothDialogComponent>) { }

    ngOnInit(): void {
        
    }


    contactUs(){
        this.dialogRef.close();
        this.router.navigateByUrl('/contact?enquiry=booth_request');
    }  
}
