<div class="form-container" *ngIf="responseState$ | async as responseState">
	<div class="top {{event_class}}">
		<div class="bg-overlay"></div>
		<img class="logo" src="https://cdn-images.island-conference.com/pageheader/header_logo.png" />
        <div class="header">
			<h3>{{ header_title }}</h3>
		</div>
		<button class="close-btn" mat-button mat-dialog-close>
			<img src="https://cdn-images.island-conference.com/icons/close-icon.svg" alt="Close">
		</button>
	</div>
	<div class="bottom {{event_class}}">
		<div class="header" *ngIf="!responseState.success">
            <div class="viptable-bgr"></div>
			<h4><span><img src="https://cdn-images.island-conference.com/attend/tickets/info-icon.svg"/></span> Important Information:</h4>
            <p *ngIf="event_class === 'opening'">Adding your Crew on our Guest List will allow you Entrance to the Event Venue.</p>

			<p *ngIf="event_class === 'pre-event'">Adding your Crew on our Guest List will allow you Entrance to the Event Venue & Free Regular Drinks from the Event Sponsor</p>

			<p *ngIf="event_class === 'closing'">Adding your Crew on our Guest List will allow you Entrance to the Event Venue & Free Regular Drinks from the Event Sponsor (Free drinks will be provided between 18:00 - 21:00)</p>

            <span><img src="https://cdn-images.island-conference.com/sponsorships/header_imgs/sponsorship_img_10.svg"/></span>
            <p>Please note, the capacity is Limited, that is why we have a First Come, First Served Policy at the Door.</p>
            <p class="form-hashtags">#OutOfThisWorldEvents #QualityMatters</p>
		</div>
		<div class="success-msg flexr" *ngIf="responseState.success">
			<div class="left">
				<h2>Thank you for your reservation!</h2>
				<p *ngIf="isSpiritual(event_class); else spiritualThanks">
					You reserved your Spot! You should receive an email confirmation shortly.
					<br />
					We would advise on checking your spam folder if you have not received a reply from us within 24
					hours.
				</p>
				<ng-template #spiritualThanks>
					<p>
						You are on the Guest List! You should receive an email confirmation shortly.
						<br />
						We would advise on checking your spam folder if you have not received a reply from us within 24
						hours.
					</p>
				</ng-template>
			</div>
			<div class="right">
				<img class="success" src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
			</div>
		</div>
		<div class="body" *ngIf="!responseState.success">
			<h3 *ngIf="isSpiritual(event_class); else spiritualText">Fill in your details to secure your spot</h3>
			<ng-template #spiritualText>
				<h3>Add your Crew on the <strong>Guestlist</strong></h3>
			</ng-template>
            <hr/>
			<p class="header-error" *ngFor="let message of responseState.return_msg">
				{{ message }}
			</p>
			<form #test="ngForm" class="form" [formGroup]="guestListForm" (ngSubmit)="onSubmit()">
				<div class="form-row flexr">
					<div class="field-container name-field">
						<input class="name-field" type="text" placeholder="Name" formControlName="nameControl" />
						<div *ngIf="submitted && f['nameControl'].errors" class="invalid-feedback">
							<div *ngIf="f['nameControl'].errors['required']">Name is required</div>
							<div *ngIf="f['nameControl'].errors['minlength']">
								A minimum of {{ f["nameControl"].errors["minlength"].requiredLength }} characters length
								is required
							</div>
						</div>
					</div>
					<div class="field-container surname-field">
						<input class="name-field" type="text" placeholder="Surname" formControlName="surnameControl" />
						<div *ngIf="submitted && f['surnameControl'].errors" class="invalid-feedback">
							<div *ngIf="f['surnameControl'].errors['required']">Surname is required</div>
							<div *ngIf="f['surnameControl'].errors['minlength']">
								A minimum of {{ f["surnameControl"].errors["minlength"].requiredLength }} characters
								length is required
							</div>
						</div>
					</div>
				</div>
				<div class="form-row flexr">
					<div class="field-container phone-field">
						<!-- <input type="tel" formControlName="phoneControl" class="form-control" id="phone" name="phone" placeholder="Phone Number" autocomplete="off"  /> -->
						<ngx-intl-tel-input
							[cssClass]="'phone-input'"
							[enablePlaceholder]="true"
							[searchCountryFlag]="true"
							[selectedCountryISO]="selected_countries.Cyprus"
							[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
							[enableAutoCountrySelect]="true"
							[maxLength]="15"
							[phoneValidation]="true"
							[separateDialCode]="true"
							name="phone"
							formControlName="phoneControl">
						</ngx-intl-tel-input>
						<div *ngIf="submitted && f['phoneControl'].errors" class="invalid-feedback">
							<div *ngIf="f['phoneControl'].errors['required']">Phone is required</div>
							<div *ngIf="f['phoneControl'].errors && !f['phoneControl'].errors['required']">
								Invalid Input
							</div>
						</div>
					</div>
					<div class="field-container email-field">
						<input
							class="email-field"
							type="text"
							placeholder="Email Address"
							formControlName="emailControl" />
						<div *ngIf="submitted && f['emailControl'].errors" class="invalid-feedback">
							<div *ngIf="f['emailControl'].errors['required']">Email is required</div>
							<div *ngIf="f['emailControl'].errors['email']">Please write a valid email</div>
						</div>
					</div>
				</div>
				<div class="form-row flexr">
                    <div class="field-container persons-field">
						<div class="quantity-container">
							<div class="minus" (click)="decreasePersons()">-</div>
                            <div id="temp-placeholder" *ngIf="tempShow">Persons</div>
							<div class="number" *ngIf="numShow">{{ numberOfPersons }}</div>  
                            <!-- <input
							class="personsNum"
							type="text"
							placeholder="Persons"
							*ngIf="numShow"
							formControlName="personsControl" value="{{ numberOfPersons }}" /> -->
							<div class="plus" (click)="increasePersons()">+</div>
						</div>
						<div *ngIf="submitted && numberOfPersons === 0" class="invalid-feedback start">
                            <div>Select at least one person</div>
						</div>
					</div>
					<div class="field-container company-field">
						<input
							class="company-field"
							type="text"
							placeholder="Company Name"
							formControlName="companyControl" />
						<div *ngIf="submitted && f['companyControl'].errors" class="invalid-feedback">
							<div *ngIf="f['companyControl'].errors['required']">Company Name is required</div>
						</div>
					</div>
				</div>
				<button class="main-btn" type="submit">Submit Request</button>
			</form>
		</div>
	</div>
</div>
