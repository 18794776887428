import { IBoothInfo, IData, IDayData } from '../interfaces/events-data.interface';

export const eventsData: IDayData[] = [
  {
    dayNo: 1,
    dayName: 'WEDNESDAY',
    dateName: 'JUNE 28',
    events: [
      {
        id: 1,
        eventType: 1,
        label: 'Registration',
        labelClass: 'registration',
        title: 'Conference Pre-registration',
        description: 'Avoid the line-ups and register the day before the conference starts',
        area: 'Registration Area',
        venue: 'City Of Dreams',
        venueLogo: null,
        timeStart: '12:00',
        timeEnd: '18:00',
        sponsors: [{ name: 'ExoClick', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/exoclick.png' }],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 2,
        eventType: 3,
        label: 'After Event',
        labelClass: 'after_event',
        title: 'Pre-Event Welcome Party',
        description:
          "Arrived already? Join our pre-conference gathering, and let the networking begin! Let's meet at La Caleta, a magnificent spot to enjoy the May sunset where the majestic views of the infinity pool and Mediterranean Sea perfectly capture the essence of the Haze LifeStyle",
        area: null,
        venue: null,
        venueLogo: 'https://cdn-images.island-conference.com/plan/events/lacaleta-blue.png',
        timeStart: '19:00',
        timeEnd: '23:00',
        sponsors: [{ name: 'Nova', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/nova.svg' }],
        eventClass: 'pre-event',
        guestlistBtn: true,
        vipBtn: true,
        reserveSlotBtn: false,
        isBooked: true,
        speakers: [],
      },
    ],
  },
  {
    dayNo: 2,
    dayName: 'THURSDAY',
    dateName: 'JUNE 29',
    events: [
      {
        id: 1,
        eventType: 3,
        label: null,
        labelClass: null,
        title: 'Spiritual Journey - Detox',
        description:
          'Start the 1st day of the conference rejuvenated, with an energy yoga healing session! Prepare yourself both mentally and physically for a day full of networking opportunities and business connections!',
        area: 'Pool Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '08:30',
        timeEnd: '09:30',
        sponsors: [{ name: 'LoudBids', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/loudbids.png' }],
        eventClass: 'spiritual',
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: true,
        isBooked: true,
        speakers: [],
      },
      {
        id: 2,
        eventType: 1,
        label: 'Registration',
        labelClass: 'registration',
        title: 'Registration Opens',
        description: 'Collect your badge, lanyard and a special gift that will enhance your experience at COD! Exhibitors are eligible for early access',
        area: 'Registration Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '09:00',
        timeEnd: null,
        sponsors: [{ name: 'ExoClick', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/exoclick.png' }],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 3,
        eventType: 1,
        label: 'Expo',
        labelClass: 'expo',
        title: 'Expo Opens for Exhibitors',
        description: 'Early access to the Expo hall for all exhibitors to set up their booths',
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '09:00',
        timeEnd: null,
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 4,
        eventType: 1,
        label: 'Expo',
        labelClass: 'expo',
        title: 'Expo Opens for all Attendees',
        description: 'Networking starts at the i-Con main Expo Area',
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '10:00',
        timeEnd: null,
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 5,
        eventType: 1,
        label: 'Break',
        labelClass: 'break',
        title: 'Coffee & Tea Stations',
        description: 'Kickstart your day with the simple pleasure of a cup of premium coffee or take your time to explore our selection of teas.',
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '10:00',
        timeEnd: '17:00',
        sponsors: [
          { name: 'StreamMate', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/streamate-logo.png' },
          { name: 'CamBuilder', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/cambuilder-logo.svg' },
        ],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 6,
        eventType: 4,
        label: 'Speech',
        labelClass: 'speech',
        title: 'Swipe right for your best payments match.',
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '11:00',
        timeEnd: '11:30',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Nikolett Palinkas',
            position: 'SVP of Client Relations',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/nikolett-palinkas.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/payabl.svg',
            companyName: 'Payabl',
          },
        ],
      },
      {
        id: 14,
        eventType: 4,
        label: 'Speech',
        labelClass: 'speech',
        title: 'How to capitalize on local sporting events: India, LATAM, USA',
        description: null,
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '11:00',
        timeEnd: '11:30',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Matvey Schmidt',
            position: 'Head of Advertisers Department',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/matvey-shmidt.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/propellerads.svg',
            companyName: 'Propellerads',
          },
        ],
      },
      {
        id: 7,
        eventType: 1,
        label: null,
        labelClass: null,
        title: 'Flash Parade',
        description: 'An impressive flash parade which will amusingly "disturb" the flow of the event and is expected to have all eyes on it',
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '12:00',
        timeEnd: '12:15',
        sponsors: [{ name: 'pukbo', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/pukbo.png' }],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 8,
        eventType: 4,
        label: 'MasterClass',
        labelClass: 'masterclass',
        title: 'The Dark Side of SEO: How to use Google to make €€€',
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '12:00',
        timeEnd: '12:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Alan Cladx',
            position: 'Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/alan-cladx.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/cladx-logo.svg',
            companyName: 'Cladx',
          },
        ],
      },
      {
        id: 15,
        eventType: 4,
        label: 'Interview',
        labelClass: 'interview',
        title: 'Interview with Dayna the COO of Adnium',
        description: null,
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '12:00',
        timeEnd: '12:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Dayna Deruelle',
            position: 'COO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/dayna-deruelle.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/adnium.svg',
            companyName: 'Adnium',
          },
        ],
      },
      {
        id: 10,
        eventType: 2,
        label: 'Break',
        labelClass: 'break',
        title: 'Aura Lunch Break',
        description: "After intensive Networking, let's have a boost with an impressive complimentary buffet prepared by the Chefs of City of Dreams!",
        area: 'Aura Restaurant',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '12:30',
        timeEnd: '15:30',
        sponsors: [
          { name: 'AdsEmpire', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/adsempire.svg' },
          { name: 'IamLive', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/imlive.svg' },
        ],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 11,
        eventType: 6,
        label: 'Break',
        labelClass: 'break',
        title: 'Break',
        description: null,
        area: null,
        venue: null,
        venueLogo: null,
        timeStart: '13:00',
        timeEnd: '13:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 12,
        eventType: 1,
        label: 'Break',
        labelClass: 'break',
        title: 'Beer for Everyone',
        description: "There is nothing more refreshing on a hot summer's day than a cold beer! Grab yours and keep networking!",
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '14:00',
        timeEnd: '17:00',
        sponsors: [{ name: 'BongaCash', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/bongacash.svg' }],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 13,
        eventType: 4,
        label: 'MasterClass',
        labelClass: 'masterclass',
        title: "Running Google Ads in high risk verticals - The Do's and Don'ts",
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '14:00',
        timeEnd: '14:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Tom Marsh',
            position: 'Head of Media Buying',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/tom-marsh.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg',
            companyName: 'Redwalking',
          },
          {
            name: 'Christina Dimitriou',
            position: 'Team Leader',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/christina-dimitriou.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg',
            companyName: 'Redwalking',
          },
        ],
      },
      {
        id: 9,
        eventType: 4,
        label: 'Panel',
        labelClass: 'panel',
        title: 'All About Online Dating! Is your EPC good enough?',
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '15:00',
        timeEnd: '15:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Moritz Von Arnim',
            position: 'CSO & CMO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/moritz-von-arnim.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/traffic.svg',
            companyName: 'Traffic Partner',
          },
          {
            name: 'Tom Marsh',
            position: 'Head of Media Buying',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/tom-marsh.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg',
            companyName: 'Redwalking',
          },
          // { name: 'Nilu Yusupova', position: 'Head of Business Development, PR', profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/nilu-usupova.png', companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/adsempire.svg', companyName: 'ADSEmprie' },
          {
            name: 'Roman Khmelnytskyy',
            position: 'Head of Business Development',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/roman-khmelnytskyy.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/mirelia-logo2.png',
            companyName: 'Mirelia',
          },
          {
            name: 'Stefan Muehlbauer',
            position: 'Head of Business Development',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/stefan.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/mastersincash.png',
            companyName: 'Masters in Cash',
          },
          {
            name: 'Jasin Impiglia',
            position: 'Senior Affiliate Manager',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/jasin-impiglia.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/nova.svg',
            companyName: 'Nova',
          },
        ],
      },
      {
        id: 12,
        eventType: 4,
        label: 'Speech',
        labelClass: 'speech',
        title: 'Is AI the Future of Moderation & Customer Support?',
        description: null,
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '15:00',
        timeEnd: '15:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Fotis Yiannakou',
            position: 'Managing Director',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/fotis-yiannakou.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/vdesk.svg',
            companyName: 'Vdesk',
          },
        ],
      },
      {
        id: 17,
        eventType: 4,
        label: 'Panel',
        labelClass: 'panel',
        title: 'The Hard Times of CEOs',
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '16:00',
        timeEnd: '16:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Michael Reul',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/michael-reul.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/traffic.svg',
            companyName: 'Traffic Partner',
          },
          {
            name: 'Charis Demetriou',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/charis-demetriou.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/nexxie-logo-blue.png',
            companyName: 'Nexxie Group',
          },
          {
            name: 'Peter Rabenseifner',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/peter-rabenseifner.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/twinred.svg',
            companyName: 'TwinRed',
          },
          {
            name: 'Tim Ram',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/tim-ram.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/trafficstars.svg',
            companyName: 'TrafficStars',
          },
        ],
      },
      {
        id: 16,
        eventType: 4,
        label: 'Product Presentation',
        labelClass: 'product',
        title: 'BongaCash. Easy way to earn BIG!',
        description: null,
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '16:00',
        timeEnd: '16:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Stacy BongaCash',
            position: 'Affiliate Manager',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/stacy-bongacash.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/bongacash.svg',
            companyName: 'BongaCash',
          },
          {
            name: 'Nina BongaCash',
            position: 'Affiliate Manager',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/nina-bongacash.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/bongacash.svg',
            companyName: 'BongaCash',
          },
        ],
      },
      {
        id: 18,
        eventType: 1,
        label: 'Expo',
        labelClass: 'expo',
        title: 'Expo Closes',
        description: "It was a busy one so let's call it a day! Take some time to relax before the Official Opening Party!",
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '17:00',
        timeEnd: null,
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 19,
        eventType: 3,
        label: 'After Event',
        labelClass: 'after_event',
        title: 'i-Conic Opening Party',
        description: 'Join the i-Conic Opening party at a distinctive venue and get to know what HazExperience is all about!',
        area: null,
        venue: null,
        venueLogo: 'https://cdn-images.island-conference.com/plan/events/breeze-logo.svg',
        timeStart: '22:00',
        timeEnd: '02:00',
        sponsors: [{ name: 'PropellerAds', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/propellerads.svg' }],
        eventClass: 'i-Conic Opening Party',
        guestlistBtn: true,
        vipBtn: true,
        reserveSlotBtn: false,
        isBooked: true,
        speakers: [],
      },
    ],
  },
  {
    dayNo: 3,
    dayName: 'FRIDAY',
    dateName: 'JUNE 30',
    events: [
      {
        id: 1,
        eventType: 1,
        label: 'Registration',
        labelClass: 'registration',
        title: 'Registration Opens (Day 2)',
        description: "In case you didn't make it yesterday, your badge will be waiting for you at our registration desks",
        area: 'Registration Area',
        venue: 'City Of Dreams',
        venueLogo: null,
        timeStart: '10:00',
        timeEnd: null,
        sponsors: [{ name: 'ExoClick', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/exoclick.png' }],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 2,
        eventType: 1,
        label: 'Expo',
        labelClass: 'expo',
        title: 'Expo Opens',
        description: 'Networking Day 2 is now On!',
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '10:00',
        timeEnd: null,
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 3,
        eventType: 1,
        label: 'break',
        labelClass: 'break',
        title: 'Coffee & Tea Stations',
        description: 'Treat your self with a cup of strong coffee and let the talking begin!',
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '10:00',
        timeEnd: '17:00',
        sponsors: [
          { name: 'StreamMate', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/streamate-logo.png' },
          { name: 'CamBuilder', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/cambuilder-logo.svg' },
        ],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 4,
        eventType: 4,
        label: 'MasterClass',
        labelClass: 'masterclass',
        title: 'Unleashing the Power of Smart Bid for Your Campaign Bid Strategy',
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '11:00',
        timeEnd: '11:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Evan Zirdelis',
            position: 'Director of Sales and Ad Operations',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/evan-zirdelis.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/exoclick.png',
            companyName: 'ExoClick',
          },
        ],
      },
      {
        id: 5,
        eventType: 4,
        label: 'Speech',
        labelClass: 'speech',
        title: 'Compliance Undressed: Decoding Payment Regulations',
        description: null,
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '11:00',
        timeEnd: '11:30',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Oleg Stefanets',
            position: 'Chief Risk Officer',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/oleg-stefanets.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/payabl.svg',
            companyName: 'Payabl',
          },
        ],
      },
      {
        id: 13,
        eventType: 4,
        label: 'Hybrid',
        labelClass: 'hybrid',
        title: 'Zero to Hero in the SEO world',
        description: 'Hybrid: Interview & Short presentation',
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '12:00',
        timeEnd: '12:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Craig Campbell',
            position: 'Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/craig-campbell.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/craigcampbellseo.png',
            companyName: 'Craig Campbell',
          },
        ],
      },
      {
        id: 7,
        eventType: 4,
        label: 'Speech',
        labelClass: 'speech',
        title: 'Be Bold, Be Different, Be an Online Marketer',
        description: 'Motivational Speech',
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '12:00',
        timeEnd: '12:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Jenny Gonzalez',
            position: 'Co Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/jenny-gonzalez-2.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/rocahead.svg',
            companyName: 'Rocahead',
          },
        ],
      },
      {
        id: 8,
        eventType: 2,
        label: 'break',
        labelClass: 'break',
        title: 'Aura Lunch Break',
        description: 'It is Lunch time again! Expect a new 5* buffet menu prepared from the executive chefs of Aura Restaurant.',
        area: 'Aura Restaurant',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '12:30',
        timeEnd: '15:30',
        sponsors: [
          { name: 'AdsEmpire', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/adsempire.svg' },
          { name: 'IamLive', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/imlive.svg' },
        ],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 9,
        eventType: 6,
        label: 'Break',
        labelClass: 'break',
        title: 'Break',
        description: null,
        area: null,
        venue: null,
        venueLogo: null,
        timeStart: '13:00',
        timeEnd: '13:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 10,
        eventType: 1,
        label: 'Break',
        labelClass: 'break',
        title: 'Beer for Everyone',
        description: "There is nothing more refreshing on a hot summer's day than a cold beer! Grab yours and keep networking!",
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '14:00',
        timeEnd: '17:00',
        sponsors: [{ name: 'BongaCash', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/bongacash.svg' }],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 14,
        eventType: 4,
        label: 'Panel',
        labelClass: 'panel',
        title: 'Latest Trends in the WebCam World! ',
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '14:00',
        timeEnd: '14:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Yuval Kijel',
            position: 'Director',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/yuval-kijel.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/streamate-logo.png',
            companyName: 'Streamate',
          },
          {
            name: 'Tal Bar Cohen',
            position: 'Chief Marketing Officer',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/tal-bar-cohen.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/imlive.svg',
            companyName: 'imlive',
          },
          {
            name: 'Aleksandr Shmelev',
            position: 'Business Developer',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/alex-bombus.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/stripcash.svg',
            companyName: 'StripCash',
          },
          {
            name: 'Anna Blaivas',
            position: 'Vice President of Sales',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/anna-blaivas.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/twinred.svg',
            companyName: 'TwinRed',
          },
        ],
      },
      {
        id: 11,
        eventType: 4,
        label: 'Product Presentation',
        labelClass: 'product',
        title: 'Onlyfans and Fansites Marketing Strategies',
        description: null,
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '14:00',
        timeEnd: '14:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Andy Wullmer',
            position: 'CMO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/andy-wullmer.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/CT-logo2.svg',
            companyName: 'Creator Traffic',
          },
        ],
      },
      {
        id: 6,
        eventType: 4,
        label: 'Product Presentation',
        labelClass: 'product',
        title: 'DatingClix - The Newest Dating Ad-Network',
        description: null,
        area: 'Stage A',
        venue: null,
        venueLogo: null,
        timeStart: '15:00',
        timeEnd: '15:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Lewis Rough',
            position: 'Sales Director',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/lewis-rough.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/datingleads.svg',
            companyName: 'Dating Leads',
          },
          {
            name: 'Federico Isla Vieyra',
            position: 'Advertiser Manager',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/federico-isla-vieyra.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/datingleads.svg',
            companyName: 'Dating Leads',
          },
        ],
      },
      {
        id: 15,
        eventType: 4,
        label: 'MasterClass',
        labelClass: 'masterclass',
        title: 'How to use top-notch ad creatives to boost campaign performance',
        description: null,
        area: 'Stage B',
        venue: null,
        venueLogo: null,
        timeStart: '15:00',
        timeEnd: '15:45',
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        isChecked: false,
        speakers: [
          {
            name: 'Gala Grigoreva',
            position: 'CMO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/gala-grigoreva.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/adsterra.svg',
            companyName: 'ADSterra',
          },
        ],
      },
      {
        id: 16,
        eventType: 1,
        label: 'Expo',
        labelClass: 'expo',
        title: 'Expo Closes',
        description: 'The 2-day Exciting and Intensive i-Con Networking Event, is over.',
        area: 'Main Expo Area',
        venue: 'City of Dreams',
        venueLogo: null,
        timeStart: '17:00',
        timeEnd: null,
        sponsors: [],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
      {
        id: 17,
        eventType: 3,
        label: 'After Event',
        labelClass: 'after_event',
        title: 'Official Closing Party',
        description:
          "It is time to say goodbye. Meet your partners and friends one last time at the Sunset Closing Party, at Captain's Cabin beach front venue. Haze's resident DJs will be offering ever-shifting music vibes.<br/><span>Shuttle bus service will be available for transfer from City of Dreams towards the venue between 17:30-18:15 and return between 23:00-23:45</span>",
        area: null,
        venue: null,
        venueLogo: 'https://cdn-images.island-conference.com/plan/events/Captains-Cabin-gold.png',
        timeStart: '18:00',
        timeEnd: '23:00',
        sponsors: [{ name: 'TwinRed', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/twinred.svg' }],
        eventClass: 'closing',
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: true,
        speakers: [],
      },
    ],
  },
  {
    dayNo: 4,
    dayName: 'SATURDAY',
    dateName: 'JULY 1',
    events: [
      {
        id: 1,
        eventType: 5,
        label: 'Private Event',
        labelClass: 'break',
        title: 'Mykonos Trip - Invitation Only',
        description:
          'i-Con has prepared a private 360˚ experience in Mykonos island for the Key People of our Industry. Want your name on the list? <a href="/mykonos">Find out more HERE</a>',
        area: null,
        venue: null,
        venueLogo: 'https://cdn-images.island-conference.com/plan_schedule/miconos.svg',
        timeStart: 'Private Schedule',
        timeEnd: null,
        sponsors: [{ name: 'Redwalking', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg' }],
        eventClass: null,
        guestlistBtn: false,
        vipBtn: false,
        reserveSlotBtn: false,
        isBooked: false,
        speakers: [],
      },
    ],
  },
];

export const events: IData[] = [
  {
    day: 'TUESDAY',
    date: 'MAY 28',
    events: [
      {
        type: 'Private Event',
        typeColor: 'gold',
        title: 'Sky Suite VIP Party',
        description: 'HOI Agency are kicking off the event early and hosting a VIP invite-only party in one of the exclusive Sky Suites at the City of Dreams. With panoramic views, premium drinks and a Haze resident DJ, what better way is there to get in the i-Con mood!',
        areaText: 'City of Dreams',
        time: '19:00 - 23:00',
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/plan/events/hoi-agency.svg',
        sponsoredByImgAlt: 'HOI Agency',
      }
    ],
  },
  {
    day: 'WEDNESDAY',
    date: 'MAY 29',
    events: [
      {
        type: 'Registration',
        typeColor: 'purple',
        title: 'Conference Pre-registration',
        description: 'Avoid the line-ups and register the day before the conference starts',
        areaText: 'Registration Area',
        areaLocation: 'City of Dreams',
        time: '12:00 - 18:00',
      },
      {
        type: 'After Event',
        typeColor: 'dark-blue',
        title: 'Pre-Event Welcome Party',
        description: 'Arrived already? Join our pre-conference gathering, and let the networking begin! Let\'s meet at La Caleta, a magnificent spot to enjoy May Sunset, the majestic views of the infinity pool and the Mediterranean Sea and capture the pure essence of a Haze LifeStyle',
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/plan/events/nova-logo.png',
        sponsoredByImgAlt: 'Nova',
        areaBtn: true,
        areaImg: true,
        areaImgUrl: 'https://cdn-images.island-conference.com/plan/events/lacaleta-logo.png',
        areaImgAlt: 'La Caleta',
        guestlistEnabled: false,
        vipEnabled: false,
        formText: 'Pre-Event Welcome Party',
        formClass: 'pre-event',
        time: '19:00 - 23:00',
      },
      {
        type: 'Private Event',
        typeColor: 'gold',
        title: 'Exclusive Speakers\' Dinner',
        description: 'An Exclusive dinner for our Keynote Conference Speakers. A unique networking opportunity to relax and create connections, under the unique ambiance of the Dionysus Mansion.',
        sponsored: false,
        areaImg: true,
        areaImgUrl: 'https://cdn-images.island-conference.com/plan/events/dionysus_mansion_logo.png',
        areaImgAlt: 'Dionysus Mansion',
        time: '19:00 - 21:00',
      }
    ],
  },
  {
    day: 'THURSDAY',
    date: 'MAY 30',
    events: [
      {
        type: 'Event',
        typeColor: 'dark-blue',
        title: 'Spiritual Journey - Detox',
        description: 'Start the 1st day of the conference rejuvenated, with an energy yoga healing session! Prepare yourself both mentally and physically for a day full of networking opportunities and business connections!',
        areaText: 'Pool Area',
        areaLocation: 'City of Dreams',
        spotBtn: true,
        formText: 'Spiritual Journey - Detox',
        formClass: 'spiritual',
        time: '08:30',
      },
      {
        type: 'Registration',
        typeColor: 'purple',
        title: 'Registration Opens',
        description: 'Get your Badge Lanyard and a special gift that will enhance your experience at COD! Exhibitors are eligible to earlier access',
        areaText: 'Registration Area',
        areaLocation: 'City of Dreams',
        time: '09:00',
      },
      {
        type: 'EXPO',
        typeColor: 'orange',
        title: 'Expo Opens for Exhibitors',
        description: 'Networking starts at the i-Con main Expo Area',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '09:00',
      },
      {
        type: 'EXPO',
        typeColor: 'orange',
        title: 'Expo Opens for All Attendees',
        description: 'Networking starts at the i-Con main Expo Area',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00',
      },
      {
        columnColor: true,
        title: 'Coffee and Refreshments',
        description: 'Start your day with a nice cup of coffee or tea!',
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/paxum.svg',
        sponsoredByImgAlt: 'Paxum',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00 - 17:00',
      },
      {
        columnColor: true,
        title: 'Juicy Time',
        description: `Let us cool you down from the busy Expo days of the conference! Free Refreshing juice to everyone!`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/imlive.svg',
        sponsoredByImgAlt: 'ImLive',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00 - 16:00',
      },
      {
        columnColor: true,
        title: 'Gelato',
        description: `Enjoy high-quality Mövenpick ice cream, made from natural ingredients`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/sepaga_logo.png',
        sponsoredByImgAlt: 'Sepaga',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00 - 16:00',
      },
      {
        columnColor: true,
        title: 'Food Court',
        description: 'Take a break and indulge yourself in the delicious options provided at the Food Court! Vegan options included.<br/><br/>*All attendees regardless of ticket tier can purchase dishes at the food court.',
        areaText: 'Expo Area Terrace',
        areaLocation: 'City of Dreams',
        time: '12:30 - 17:00',
      },
      {
        columnColor: true,
        title: 'Aura Lunch Break',
        description: 'After intensive networking, Exhibitors and Premium ticket holders can enjoy a well earned 5-star lunch in the Aura restaurant',
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/monrem.svg',
        sponsoredByImgAlt: 'MonRem',
        areaText: 'Aura Restaurant',
        areaLocation: 'City of Dreams',
        time: '12:30 - 15:30',
      },
      {
        columnColor: true,
        title: 'Beer for Everyone',
        description: `There is nothing more refreshing on a hot summer's day than a cold beer! Grab yours and keep networking!`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/bongacash.svg',
        sponsoredByImgAlt: 'Bong a Cash',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '14:00 - 17:00',
      },
      {
        columnColor: true,
        title: 'Secret Garden Networking Drinks',
        description: `Finish the first day of the conference, with some networking drinks, at Anaïs, Europe's first French Gastro Club. Between 16:00-18:30 our Premium attendees will be exclusively invited to gather all together in the "La Terrasse" area of Anaïs to relax after the first day of the conference and warm up for the i-CONIC Opening Party. Immerse yourself in an enchanting atmosphere with the sounds of our guest DJ while enjoying the aperitivo style cocktails and drinks.`,
        areaText: 'Anais',
        areaLocation: 'City of Dreams',
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/advidi.svg',
        sponsoredByImgAlt: 'Advidi',
        time: '16:00 - 18:30',
      },
      {
        type: 'EXPO',
        typeColor: 'orange',
        title: 'Expo Closes',
        description: `It was a busy one so let's call it a day! Get some time to relax before the Official Opening Party!`,
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '17:00',
      },
      {
        type: 'Private Event',
        typeColor: 'gold',
        title: 'TrafficStars Private Gathering',
        description: `TrafficStars is organizing a nice gathering at a Mystery Villa, for friends and partners, featuring complimentary drinks, hookah, food, and soulful music on Thursday the 30th of May between 17:30 - 22:00, right before the Grand Opening Party of i-Con.<br/><br/>Don't worry we've got you covered, i-Con Shuttle Buses will be waiting to take you from the Villa to Breeze Club for an unforgettable Opening Party!<br/><br/>This is an Invitation-Only event, please approach the TrafficStars Team for more details.`,
        areaText: 'Mystery Villa',
        areaLocation: 'Secret Location (Invitation Only)',
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/trafficstars.svg',
        sponsoredByImgAlt: 'TrafficStars',
        time: '17:30-22:00',
        guestListBtn: true,
        externalformUrl: 'https://forms.gle/efUaYHTZmgCdUxRD9'
      },
      {
        type: 'After Event',
        typeColor: 'dark-blue',
        title: 'i-Conic Opening Party',
        description: `Join the i-Con Opening party at a distinctive venue and get to know what HazExperience is all about!`,
        areaImg: true,
        areaImgUrl: 'https://cdn-images.island-conference.com/plan/events/breeze-logo.svg',
        areaImgAlt: 'Summer Breeze',
        areaBtn: true,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/propellerads.svg',
        sponsoredByImgAlt: 'Propeller Ads Advertising Platform',
        guestlistEnabled: true,
        vipEnabled: true,
        formText: 'i-Conic Opening Party',
        formClass: 'opening',
        time: '22:00-02:00',
      },
    ],
    speeches: [
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'The art and science of balancing iGaming media buying in the off-season',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '11:00 - 11:30',
        speakers: [
          {
            name: 'Matvey Schmidt',
            position: 'Head of Advertisers',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/matvey-shmidt.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/propellerads.svg',
            companyName: 'Propeller Ads',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Bet Big, Win Bigger: Mastering Gambling Ads with Push and Pop!',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '11:00 - 11:30',
        speakers: [
          {
            name: 'Diana Sultanova',
            position: 'Business Development Manager',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/diana-sultanova.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/Kadam_Logo.png',
            companyName: 'Kadam',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Forget PageRank. Just Rank instead!',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '11:45 - 12:15',
        speakers: [
          {
            name: 'Kaspar Szymanski',
            position: 'Co-Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/kaspar-szymanski.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/searchbrothers-logo.svg',
            companyName: 'SearchBrothers',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Traffic for video games: Everything you need to know about affiliation, buying, and selling traffic in the gaming industry!',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '11:45 - 12:15',
        speakers: [
          {
            name: 'Adrien Bacchi',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/adrien-bacchi.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Affilgamer-logo.png',
            companyName: 'Affilgamer',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Paid ads for iGaming: Make them work & scale your business',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '12:30 - 13:00',
        speakers: [
          {
            name: 'Kamila Łuksza-Szpyt',
            position: 'Head of Marketing & Partnerships',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/kamila-luksza-szpyt.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/voluum-logo.svg',
            companyName: 'Voluum',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Adapting to Change: Future Outlook for European Acquiring Banks and Effective Strategies for Fund Release Amid Market Shifts',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '12:30 - 13:00',
        speakers: [
          {
            name: 'Patrick C. Friedrich',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/patrick-friedrich.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/finest.svg',
            companyName: 'FINEST',
          },
          {
            name: 'Daniel Friedrich',
            position: 'Lawyer / Partner',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/daniel-friedrich.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/winterestein-light-background.svg',
            companyName: 'Winterestein',
          },
        ],
      },
      {
        columnColor: true,
        title: 'BREAK',
        description: null,
        time: '13:00 - 14:00'
      },
      {
        type: 'Motivational Speech',
        typeColor: 'gold',
        title: 'How to thrive “Under Fire”',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '14:00 - 14:30',
        speakers: [
          {
            name: 'Sarah Furness',
            position: 'Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/sarah-furness.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/sarahfurness-logo.jpg',
            companyName: 'sarahfurness.com',
          },
        ],
      },
      {
        type: 'SEO Panel',
        typeColor: 'purple',
        title: 'Navigating the Shades of SEO: White-Hat and Black-Hat Tactics in 2024',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '14:00 - 14:45',
        speakers: [
          {
            name: 'Dinesh Sivapragsam',
            position: 'Global Head Of SEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/dinesh-sivapragsam.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/binance-logo.png',
            companyName: 'Binance',
          },
          {
            name: 'Maja Jovančević',
            position: 'SEO Director',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/maja-jovancevic.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/GiG.png',
            companyName: 'GIG Media',
          },
          {
            name: 'Alan Cladx',
            position: 'Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/alan-cladx.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/cladx-logo.svg',
            companyName: 'H1SEO',
          },
          {
            name: 'Milosz Krasinski',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/milosz-krasinski.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/chilli-fruit-logo-light-background.svg',
            companyName: 'Chillifruit',
          },
          {
            name: 'Dmitry Bezdetny',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/dmitri-bezdetny.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/cbd-logo.png',
            companyName: 'CBD Local Inc',
          }
        ],
      },
      {
        type: 'Dating Panel',
        typeColor: 'orange',
        title: 'Not Another Dating Panel! Insider Insights for Affiliates and Advertisers',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '14:45 - 15:30',
        speakers: [
          {
            name: 'Nilu Yusupova',
            position: 'CBDO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/nilu-yusupova.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/AdsEmpire.png',
            companyName: 'AdsEmpire',
          },
          {
            name: 'Alex Sydorets',
            position: 'Senior Business Development Manager',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/alex-sydorets.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/cpamatica-logo.svg',
            companyName: 'CPamatica',
          },
          {
            name: 'Stefan Muehlbauer',
            position: 'Head of Business Development',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/stefan-muelhbauer.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/masters-in-cash-logo.png',
            companyName: 'Masters In Cash',
          },
          {
            name: 'Lars Scholman',
            position: 'Head Partners & Campaigns',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/lars-scholman.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/offerpump.png',
            companyName: 'OfferPump',
          },
          {
            name: 'Roman Khmelnytskyy',
            position: 'CBDO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/roman-khmelnytskyy.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/mirelia-logo2.png',
            companyName: 'Mirelia',
          },
          {
            name: 'Tom Marsh',
            position: 'Head of Media Buying & Affiliation',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/tom-marsh.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg',
            companyName: 'Redwalking',
          },
        ],
      },
      {
        type: 'Cyprus Panel',
        typeColor: 'purple',
        title: 'Cyprus as a Business Hub: What You Need to Know About Relocating your Company\'s Operations',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '15:00 - 15:45',
        speakers: [
          {
            name: 'Vassos Paraskevas',
            position: 'Founder & CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/vassos-paraskevas-2.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/servicenow-header-logo.png',
            companyName: 'ServiceNow Corporate Solutions',
          },
          {
            name: 'Andreas Vladimirou',
            position: 'Director',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/andreas-vladimirou-trppides.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/treppides-logo.png',
            companyName: 'Treppides Group',
          },
          {
            name: 'Marios Tannousis',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/marios-tannousis.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/InvestCyprus-logo.jpeg',
            companyName: 'Invest Cyprus',
          },
          // {
          //   name: 'Andreas Hadjikyrou',
          //   position: 'Founder & Director',
          //   profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/andreas-hadjikyrou.png',
          //   companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/7Q-logo.png',
          //   companyName: '7Q',
          // },
          {
            name: 'Jim Austin',
            position: 'Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/jim-austin-circle.png',
            companyLogo: 'https://cdn-images.island-conference.com/exhibitors/logos/jim-austin-global-bright.svg',
            companyName: 'Jim Austin Global',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Gamification is a New Retention Strategy',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '16:00 - 16:30',
        speakers: [
          {
            name: 'Irina Pisanko',
            position: 'CPO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/Irina-Pisanko-circle.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/alpha-affiliates.png',
            companyName: 'Alpha Affiliates',
          },
        ],
      },
      {
        type: 'CEO Panel',
        typeColor: 'purple',
        title: 'The Hard Times of CEOs (It\'s Getting Harder)',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '16:00 - 16:45',
        speakers: [
          {
            name: 'Charis Demetriou',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/charis-demetriou.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Nexxie.png',
            companyName: 'Nexxie Group',
          },
          {
            name: 'Michael Reul',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/michael-reul.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Traffic-Partner.png',
            companyName: 'Traffic Partner',
          },
          {
            name: 'Tim Ram',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/tim-ram.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Traffic-Stars.png',
            companyName: 'TrafficStars',
          },
          {
            name: 'Peter Rabenseifner',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/peter-rabenseifner.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/TwinRed.png',
            companyName: 'TwinRed',
          },
          {
            name: 'Alex Vasekin',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/ale-vasekin.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/PropellerAds.png',
            companyName: 'PropellerAds',
          },
        ],
      },
    ]
  },
  {
    day: 'FRIDAY',
    date: 'MAY 31',
    events: [
      {
        type: 'Registration',
        typeColor: 'purple',
        title: 'Registration Opens (Day 2)',
        description: `In case you didn't make it yesterday, your badge will be waiting for you at our registration desks`,
        areaText: 'Registration Area',
        areaLocation: 'City of Dreams',
        time: '10:00',
      },
      {
        type: 'EXPO',
        typeColor: 'orange',
        title: 'Expo Opens',
        description: `Networking Day 2 is now On!`,
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00',
      },
      {
        columnColor: true,
        title: 'Coffee and Refreshments',
        description: `Treat your self with a cup of strong coffee and let the talking begin!`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/paxum.svg',
        sponsoredByImgAlt: 'Paxum',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00 - 17:00',
      },
      {
        columnColor: true,
        title: 'Juicy Time',
        description: `Let us cool you down from the busy Expo days of the conference! Free Refreshing juice to everyone!`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/imlive.svg',
        sponsoredByImgAlt: 'ImLive',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00 - 16:00',
      },
      {
        columnColor: true,
        title: 'Gelato',
        description: `Enjoy high-quality Mövenpick ice cream, made from natural ingredients`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/sepaga_logo.png',
        sponsoredByImgAlt: 'Sepaga_logo',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '10:00 - 16:00',
      },
      {
        columnColor: true,
        title: 'Food Court',
        description: 'Take a break and indulge yourself in the delicious options provided at the Food Court! Vegan options included.<br/><br/>*All attendees regardless of ticket tier can purchase dishes at the food court.',
        areaText: 'Expo Area Terrace',
        areaLocation: 'City of Dreams',
        time: '12:30 - 17:00',
      },
      {
        columnColor: true,
        title: 'Aura Lunch Break',
        description: `After intensive networking. Exhibitors and Premium ticket holders can enjoy a well earned 5-star lunch in the Aura restaurant`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/monrem.svg',
        sponsoredByImgAlt: 'MonRem',
        areaText: 'Aura Restaurant',
        areaLocation: 'City of Dreams',
        time: '12:30 - 15:30',
      },
      {
        columnColor: true,
        title: 'Beer for Everyone',
        description: `There is nothing more refreshing on a hot summer's day than a cold beer! Grab yours and keep networking!`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/bongacash.svg',
        sponsoredByImgAlt: 'Bong a Cash',
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '14:00 - 17:00',
      },
      {
        type: 'EXPO',
        typeColor: 'orange',
        title: 'Expo Closes',
        description: `The 2-day Exciting and Intensive i-Con Networking Event, is over.`,
        areaText: 'Main Expo Area',
        areaLocation: 'City of Dreams',
        time: '17:00',
      },
      {
        type: 'After Event',
        typeColor: 'dark-blue',
        title: 'Official Closing Party',
        description: `It is time to say goodbye. Meet your partners and friends one last time at the Sunset Closing Party, right by the waves, at Captains Cabin. Haze's resident DJs will be offering ever-shifting music vibes.`,
        sponsored: true,
        sponsoredByImgUrl: 'https://cdn-images.island-conference.com/exhibitors/logos/twinred.svg',
        sponsoredByImgAlt: 'TwinRed',
        areaBtn: true,
        areaImg: true,
        areaImgUrl: 'https://cdn-images.island-conference.com/plan/events/Captains-Cabin-gold.png',
        areaImgAlt: 'Captain\'s Cabin',
        guestlistEnabled: false,
        vipEnabled: false,
        formText: 'Official Closing Party',
        formClass: 'closing',
        time: '18:00 - 23:00',
      },
    ],
    speeches: [
      {
        type: 'Motivational speech',
        typeColor: 'purple',
        title: 'What Makes a Company a Great Place to Work',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '11:00 - 11:30',
        speakers: [
          {
            name: 'Kyriakos Iacovides',
            position: 'General Manager',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/kyriakos-iacovides.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/GPTW_Logo.svg',
            companyName: 'Great Place to Work®Cyprus',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'SEM Strategies for Affiliate Marketers: Beyond the Basics',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '11:45 - 12:15',
        speakers: [
          {
            name: 'Konstantinos Papadopoulos',
            position: 'Ad Specialist',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/konstantinos-papadopoulos.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/google-logo.svg',
            companyName: 'Google',
          },
        ],
      },
      {
        type: 'Workshop',
        typeColor: 'orange',
        title: 'Unveil the Affiliate Marketing Force Within: Master Affiliate Tracking with Trackfinity',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '11:45 - 12:15',
        speakers: [
          {
            name: 'Jenny Gonzalez',
            position: 'Co-Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/jenny-gonzalez-2.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/trackfinity-logo.png',
            companyName: 'Trackfinity',
          },
        ]
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'How To Influence AI Using BlackHat SEO Techniques - Make  € £ ₿ easily!!!',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '12:30 - 13:00',
        speakers: [
          {
            name: 'Alan Cladx',
            position: 'Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/alan-cladx.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/cladx-logo.svg',
            companyName: 'H1SEO',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Leveraging Generative AI for Business Growth',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '12:30 - 13:00',
        speakers: [
          {
            name: 'Sam Goldfarb',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/sam-goldfarbg-circle.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/tradimax-logo-light-background.png',
            companyName: 'Tradimax',
          },
        ]
      },
      {
        columnColor: true,
        type: 'Break',
        typeColor: 'yellow',
        title: 'BREAK',
        description: null,
        time: '13:00 - 14:00'
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'Unlocking the Power of Cloud for Media, Entertainment and Advertising',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '14:00 - 14:45',
        speakers: [
          {
            name: 'Mike Golubev',
            position: 'Principal Solutions Architect',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/mike-golubev.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/amazon-web-services-logo-light-background.png',
            companyName: 'Amazon Web Services',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'The Magic of LinkedIn: Exploring 1 Billion Opportunities',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '14:00 - 14:45',
        speakers: [
          {
            name: 'Jasmin Alic',
            position: 'LinkedIn Growth Specalist',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/jasmin-alic.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/hey-jay-logo-light-background.png',
            companyName: 'Hey Jay',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'The importance of gender perspective in Technology and Artificial intelligence',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '15:00 - 15:45',
        speakers: [
          {
            name: 'Karen De Sousa Pesse',
            position: 'Senior Executive',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/karen-de-sousa-pesse.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/salesforce-logo.svg',
            companyName: 'SalesForce',
          },
        ],
      },
      {
        type: 'AI Panel',
        typeColor: 'orange',
        title: 'AI\'s Black Box: Secrets of Today\'s Digital Marketing Algorithms',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '15:00 - 15:30',
        speakers: [
          {
            name: 'Michael Melen',
            position: 'Co-Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/michael-melen.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/smartsites-logo.png',
            companyName: 'SmartSites',
          },
          {
            name: 'Jitendra Vaswani',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/jitendra-vaswani.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Digiexe-logo.webp',
            companyName: 'DigiExe',
          },
          {
            name: 'Komninos Chatzipapas',
            position: 'Co-Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/komninoz.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/lunalust-logo.svg',
            companyName: 'LunaLust',
          },
          {
            name: 'Bob Kelland',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/bob-kelland.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/clone-twin-logo-light-background.png',
            companyName: 'CloneTwin',
          },
        ],
      },
      {
        type: 'Workshop',
        typeColor: 'orange',
        title: 'Creating Hyper-Personalized Emails with Amazon Bedrock & Amazon Personalize',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '15:45 - 16:15',
        speakers: [
          {
            name: 'Egor Miasnikov',
            position: 'Senior Solutions Architect',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/egor-miasnikov.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/amazon-web-services-logo-light-background.png',
            companyName: 'Amazon Web Services',
          },
        ],
      },
      {
        type: 'Dating Panel (API)',
        typeColor: 'purple',
        title: 'API Unlocked - Everything you ever wanted to know about API Dating traffic',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '16:00 - 16:45',
        speakers: [
          {
            name: 'Moritz Von Arnim',
            position: 'CMO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/moritz-von-arnim.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/Traffic-Partner.png',
            companyName: 'Traffic Partner',
          },
          {
            name: 'Urte Mitschunas',
            position: 'Head of Media Buying',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/ute-mitschunas.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/datinglions-logo.png',
            companyName: 'DatingLions',
          },
          {
            name: 'Artem Butov',
            position: 'CEO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/artem-butov.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/marksel-logo.png',
            companyName: 'marksel',
          },
          {
            name: 'Lewis Rough',
            position: 'Sales Director',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/lewis-rough.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_speakers/speakers/datingleads.svg',
            companyName: 'Dating Leads',
          },
          {
            name: 'Jenny Gonzalez',
            position: 'Co-Founder',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/jenny-gonzalez.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/rocahead-logo.png',
            companyName: 'Rocahead',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'How to Outperform Your Competitors with a Strong Brand Idea and an IMC Approach',
        description: null,
        areaText: 'Stage B',
        areaLocation: 'City of Dreams',
        time: '16:30 - 17:00',
        speakers: [
          {
            name: 'Gala Grigoreva',
            position: 'CMO',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/gala-grigoreva.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/adsterra-logo.svg',
            companyName: 'Adsterra',
          },
        ],
      },
      {
        type: 'Presentation',
        typeColor: 'dark-blue',
        title: 'From Zero to Hero: Crafting a High-Performance Team in the iGaming Arena',
        description: null,
        areaText: 'Stage A',
        areaLocation: 'City of Dreams',
        time: '16:50 - 17:20',
        speakers: [
          {
            name: 'Polina Kapitonova',
            position: 'HR Advisor',
            profile: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/polinca-circle.png',
            companyLogo: 'https://cdn-images.island-conference.com/plan_schedule/schedule-speakers/logos/alpha-affiliates.png',
            companyName: 'Alpha Affiliates',
          },
        ]
      }
    ]
  },
  {
    day: 'SUNDAY',
    date: 'JUNE 2',
    events: [
      {
        type: 'Private Event',
        typeColor: 'gold',
        title: 'Miconos Meetup',
        description: 'i-Con has prepared a private 360˚ experience in Mykonos island for the Key People in our Industry. Want your name on the list? <a href="/miconos">Find out more HERE</a>',
        sponsored: true,
        sponsors: [ { name: 'Redwalking', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg' },
                    { name: 'AdsEmpire', logo: 'https://cdn-images.island-conference.com/exhibitors/logos/adsempire.svg' }
                  ],
        areaImg: true,
        areaImgUrl: 'https://cdn-images.island-conference.com/vision/recap/albums/miconos.svg',
        areaImgAlt: 'Miconos',
        timeIsAlphabetic: true,
        time: 'Private Schedule',
      },
    ],
  },
];

export const boothData: IBoothInfo[] = [
  {
    imageUrl: 'https://cdn-images.island-conference.com/attend/booths/1/model.png',
    imageAlt: 'Moon',
    inStock: true,
    name: 'Moon',
    size: 4,
  },
  {
    imageUrl: 'https://cdn-images.island-conference.com/attend/booths/2/model.png',
    imageAlt: 'Mercury',
    inStock: true,
    name: 'Mercury',
    size: 6,
  },
  {
    imageUrl: 'https://cdn-images.island-conference.com/attend/booths/3/model.png',
    imageAlt: 'Galaxy',
    inStock: false,
    name: 'Galaxy',
    size: 9,
  },
  {
    imageUrl: 'https://cdn-images.island-conference.com/attend/booths/4/model.png',
    imageAlt: 'Mars',
    inStock: false,
    name: 'Mars',
    size: 12,
  },
  {
    imageUrl: 'https://cdn-images.island-conference.com/attend/booths/5/model.png',
    imageAlt: 'Venus',
    inStock: false,
    name: 'Venus',
    size: 16,
  },
  {
    imageUrl: 'https://cdn-images.island-conference.com/attend/booths/6/model.png',
    imageAlt: 'Jupiter',
    inStock: false,
    name: 'Jupiter',
    size: 32,
  },
];
