import { Component, Inject, OnInit } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISurveyAnswer } from '../../interface/questionnaire.interface';
import { UserAnswersService } from '../../service/userAnswers.service';
import { EVENTS_SATISFIED_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-events-satisfied',
  templateUrl: './events-satisfied.component.html',
  styleUrl: './events-satisfied.component.scss',
})
export class EventsSatisfiedComponent implements OnInit {
  hoveredStarIndices: (number | null)[] = [];
  selectedRatings: number[] = [];
  selectedItem: IQuestionnaireUserAnswers;
  constructor(@Inject(EVENTS_SATISFIED_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService) {}

  ngOnInit(): void {
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: 0,
          value: [''],
          subAnswers: [0],
        },
      ],
    };
  }

  selectRating(item: ISurveyAnswer, rowIndex: number, ratingId: number, subAnswerIndex: number) {
    this.selectedRatings[rowIndex] = subAnswerIndex;

    const existingAnswer = this.selectedItem.answers.find(answer => answer.answerId === item.id);

    if (existingAnswer) {
      item.surveySubAnswers;
      existingAnswer.value = [item.surveySubAnswers![subAnswerIndex].name];
      existingAnswer.subAnswers = [ratingId];
    } else {
      this.selectedItem.answers.push({
        answerId: item.id,
        value: [item.surveySubAnswers![subAnswerIndex].name],
        subAnswers: [ratingId],
      });
    }
  }

  nextClick() {
    // + 1 because of default state
    if (this.selectedItem && this.selectedItem.answers.length === this.content.surveyAnswer.length + 1) {
      this.selectedItem.answers.shift();
      this.userAnswersService.pushNewAnswer(this.selectedItem);
    }
  }
}
