import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBoothTypeResponse } from '../../_models/responses/booth-type-response.interface';
// import { IBoothType } from '@i-con/shared/common';
import { AuthorizationService, EnvironmentService, IBoothTypeReservationLoggedIn, IOrder, IOrderResponse, StateService } from '@i-con/shared/common';

import { IBoothType } from '../../_models/booth.interface';
import { BoothService } from './booth.service';

interface IBoothState {
  data: IBoothType;
  postData: IBoothTypeResponse;
  orderData: IOrderResponse;
}

const initialStateValue: any = {
  data: {},
  postData: {},
  orderData: {}
};

@Injectable({ providedIn: 'root' })
export class ReserveBoothService extends StateService<IBoothState> {
  data$: Observable<IBoothType> = this.getState(state => state.data);

  orderData$: Observable<IOrderResponse> = this.getState(state => state.orderData);
  postData$: Observable<IBoothTypeResponse> = this.getState(state => state.postData);

  constructor(
    private http: HttpClient,
    private ReserveBoothData: BoothService,
    private _environment: EnvironmentService,
    private authService: AuthorizationService
  ) {
    super(initialStateValue);
  }

  getBoothItem(booth_id: number) {
    this.ReserveBoothData.data$.subscribe((data: any) => {
      const test = data.filter((item: any) => {
        return item.id == booth_id;
      });

      this.setState({ data: test[0] });
    });
  }

  reserveBoothLoggedIn(reserveDetails: IBoothTypeReservationLoggedIn): void {
    this.http
      .post<IOrderResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/order/booth`, reserveDetails, { withCredentials: true })
      .subscribe({
        error: (error: any) => {
          this.setState({ orderData: error.error });
        },
        next: (resposnse: any) => {
          this.setState({ orderData: resposnse });
        },
      });
  }

  reserveBoothItem(reserveDetails: object) {
    this.http
      // .post<IBoothTypeResponse>(
      //   `${this._environment.getApiUrl}/booth-reservation`,
      //   reserveDetails
      // )
      .post<IBoothTypeResponse>(`${this._environment.getApiUrl}/booking/booths`, reserveDetails)
      .subscribe({
        error: (error: any) => {
          this.setState({ postData: error.error });
        },
        next: (resposnse: any) => {
          this.setState({ postData: resposnse });
        },
      });
  }
}
