import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-soldout-list',
  templateUrl: './soldout-list.component.html',
  styleUrls: ['./soldout-list.component.scss'],
})
export class SoldoutListComponent {
  constructor(public dialog: MatDialog, private router: Router) {}
  ticketType: string[] = ['Standard', 'Premium', 'Stay_Attend'];
  closeDialog() {
    this.dialog.closeAll();
  }
  soldOutSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    centerMode: true,
    centerPadding: '0',
    // variableWidth: true,
    // variableHeight: true,
    draggable: false,
    initialSlide: 0,
    // focusOnSelect: true,
    // prevArrow: '<img class="prev-arrow" src="/assets/icons/chevron-left.svg"></img>',
    // nextArrow: '<img class="next-arrow" src="/assets/icons/chevron-right.svg"></img>',
  };
  navigateToTickets() {
    this.closeDialog();
    this.router.navigate(['/attend/tickets']);
  }
}
