import { Component, Inject } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers } from '../../interface/questionnaire.interface';
import { UserAnswersService } from '../../service/userAnswers.service';
import { RATE_FEATURES_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-ratefeatures',
  templateUrl: './ratefeatures.component.html',
  styleUrls: ['./ratefeatures.component.scss'],
})
export class RateFeaturesComponent {
  selectedValues: Map<number, { answerValue: string; ratingId: number }> = new Map<number, { answerValue: string; ratingId: number }>();

  constructor(@Inject(RATE_FEATURES_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService) {}

  onRadioChange(answerId: number, answerValue: string, ratingId: number): void {
    this.selectedValues.set(answerId, { answerValue, ratingId });
  }

  nextClick() {
    if (this.selectedValues) {
      const answersArray = Array.from(this.selectedValues, ([answerId, value]) => ({ answerId, value }));

      const objectToSend: IQuestionnaireUserAnswers = {
        questionId: this.content.id,
        answers: [
          {
            answerId: 0,
            value: [''],
            subAnswers: [0],
          },
        ],
      };

      answersArray.forEach(answer =>
        objectToSend.answers.push({ answerId: answer.answerId, value: [answer.value.answerValue], subAnswers: [answer.value.ratingId] })
      );

      //remove initial state
      objectToSend.answers.shift();
      this.userAnswersService.pushNewAnswer(objectToSend);
    }
  }
}
