import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISpeakerChecked, ISpeakerInfo } from '../../../interface/questionnaire.interface';

@Component({
  selector: 'i-con-speaker-card',
  templateUrl: './speaker-card.component.html',
  styleUrl: './speaker-card.component.scss',
})
export class SpeakerCardComponent {
  @Input() speaker: ISpeakerInfo;
  @Input() answerId: number;
  @Output() checkboxChange = new EventEmitter<ISpeakerChecked>();

  onCheckboxChange(speaker: ISpeakerInfo, answerId: number, isChecked: boolean) {
    this.checkboxChange.emit({ speaker, answerId, isChecked });
  }
}
