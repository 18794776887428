import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICard } from '../../interfaces/vision-recap-album.interface';

@Component({
  selector: 'vision-recap-album-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vision-recap-album-card.component.html',
  styleUrl: './vision-recap-album-card.component.scss',
})
export class VisionRecapAlbumCardComponent {
  @Input() card: ICard;
  constructor(private route: ActivatedRoute, private router: Router) {}

  onClick() {
    this.router.navigate([this.card.day.toLowerCase().replace(/ /g, '-'), this.card.title.toLowerCase().replace(/ /g, '-')], {
      relativeTo: this.route,
    });
  }
}
