import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBoothTypeReservationLoggedIn } from '@i-con/shared/common';
import { Observable } from 'rxjs';
import { ReserveBoothService } from '../../../../../app/core/_services/booths/reserve-booth.service';
import { IBoothType } from '../../../../core/_models/booth.interface';
import { BoothStateService } from '../../../../core/_services/booths/booth-state.service';

@Component({
  selector: 'app-reserve-booking-form',
  templateUrl: './reserve-booking-form.component.html',
  styleUrls: ['./reserve-booking-form.component.scss'],
})
export class ReserveBookingFormComponent implements OnInit {
  selected_booth_type$: Observable<IBoothType>;
  selected_booth_id$: Observable<string>;

  selected_booth_type: IBoothType;
  selected_booth_id: string = '';

  @Input() current_step: number = 2;
  @Output() onNavigateToStep = new EventEmitter<number>();
  @Output() onNavigateToBoothTypeId = new EventEmitter<number>();
  @Input() errorMessages: string[] = [];

  @Output() setBookingForm = new EventEmitter();
  @Output() setBookingFormLoggedIn = new EventEmitter();

  constructor(private boothState: BoothStateService, private reserveBoothService: ReserveBoothService) {}

  ngOnInit(): void {
    this.selected_booth_type$ = this.boothState.selected_booth_type$;
    this.selected_booth_id$ = this.boothState.selected_booth_id$;

    this.selected_booth_type$.subscribe(selected_booth_type => {
      this.selected_booth_type = selected_booth_type;
    });
    this.selected_booth_id$.subscribe(selected_booth_id => {
      this.selected_booth_id = selected_booth_id;
    });
  }

  goToStep(step: number): void {
    this.onNavigateToStep.emit(step);
  }

  createBookingForm($event: any) {
    this.setBookingForm.emit($event);
  }

  loggedInReservation() {

    if (this.selected_booth_type.id) {
      const bookingDetails: IBoothTypeReservationLoggedIn = {
        reservation: {
          boothTypes: [
            {
              boothTypeId: this.selected_booth_type.id,
            },
          ],
        },
      };

      this.setBookingFormLoggedIn.emit(bookingDetails);
    }
  }
}
