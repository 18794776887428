<div class="container" *ngIf="gallery">
    <div class="header">
        <h1>{{gallery.header}}</h1>
        <button class="backBtn" (click)="backClick()">Back</button>
    </div>
    <hr>
    <div class="content">
        <div class="info">
            <h2>{{gallery.title}}</h2>
            <span [innerHTML]="gallery.description"></span>
        </div>

        <div class="images">
            <img loading="lazy" *ngFor="let image of gallery.images; let i = index" [src]="image.src" alt=""
                (click)="openImage(i, 1)">
        </div>
    </div>
</div>

<app-not-found *ngIf="!gallery"></app-not-found>