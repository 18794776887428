import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule, provideRouter } from '@angular/router';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppModule } from './app.module';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(AppModule),
    importProvidersFrom(SharedModule),
    importProvidersFrom(FeaturesModule),
    importProvidersFrom(CoreModule),
    provideAnimations(),
    importProvidersFrom(RouterModule),
    importProvidersFrom(AppRoutingModule),
    provideHttpClient(withFetch()),
  ],
};
