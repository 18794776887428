<div class="content">
    <div class="heading">
        <!-- <img *ngIf="content.imgSrc" src="{{content.imgSrc}}" alt="{{content.imgAlt}}" /> -->
        <span *ngIf="content.header">{{content.header}}</span>
        <!-- <a *ngIf="content.youtubeUrl" href="https://www.youtube.com/@Island-Conference" target="_blank"><button
                class="btn">View Youtube Channel</button></a> -->

    </div>

    <div class="details">
        <div class="title">
            <span>{{content.title}}</span>
        </div>
        <div class="description">
            <p>{{content.description}}</p>
        </div>
    </div>
</div>