<div class="plan-speakers" id="home">
    <!--div class="speakers_bgr"></div>
    <div class="top-header">
        <div class="container">
            <h3>Meet i-Con's</h3>
            <h1>SPEAKERS AND EXPERTS</h1>
        </div>
    </div>

    <div class="become_speaker">
        <div class="container flexr">
            <div class="left">
                <p>Key People of the industry take the stage to provide their knowledge and expertise. Get access to the latest topics, strategies and tactics and benefit of big idea talks, debates, and life-changing networking.</p>
            </div>
            <div class="right"> -->
<!-- <button class="main-btn">Become a Speaker</button> -->

<!-- <app-nav-button
                    class="main-btn"
                    [type]="'internal'"
                    [new_path]="['/contact']"
                    [text]="'Become a Speaker'"
                    [queryParams]="{ enquiry: 'speaker' }">
                </app-nav-button>
            </div>
        </div>
    </div>
    <div class="speakers flexr">
        
        <div class="container">
            <div class="speaker" *ngFor="let dd of dialogData; let i = index" [ngClass]="{ filled: dialogData[i].speech_desc !== null || dialogData[i].bio !== null }" (click)="openDialog(i)">
                <div class="speaker_image">
                    <div class="speaker_bgr"></div>
                    <div class="prof_img"><img src="{{dd.profile_pic}}" alt="{{dd.profile_pic_alt}}" style="max-width:{{dd.max_width}}"/></div>
                    <div class="more_info flexr">
                        <button><span><img src="https://cdn-images.island-conference.com/plan_speakers/arrow.svg" alt="Down chevron"/></span></button>
                    </div>
                </div>
                <div class="speaker_name">{{dd.name}} {{dd.surname}}</div>
                <div class="position">{{dd.company}}<span *ngIf="dd.position">,</span> {{dd.position}}</div>
                <div class="company"><img src="{{dd.company_logo}}" alt="{{dd.company_logo_alt}}"/></div>
            </div>
            <div class="speaker tuned">
                <div class="speaker_image">
                    <div class="speaker_bgr"></div>
                    <p>STAY TUNED FOR MORE</p>
                </div>
                <div class="speaker_name">New Speakers Announced Soon</div>
            </div>
        </div>
    </div -->

    <div class="speakers-header">
        <div class="header-content">        
            <h3>MEET i-CON'S</h3>
            <h1>SPEAKERS & EXPERTS</h1>
        </div>
    </div>
    
    <div class="become_speaker">
        <div class="container flexr">
            <div class="left">
                <div class="knowledge">
                    <span>EXPAND YOUR DIGITAL <strong>MARKETING KNOWLEDGE</strong></span>
                    <hr>
                    <div class="paragraphs">
                        <p>All i-Con attendees have the chance to take their industry knowledge and expertise to the next level!
                            Join
                            our keynote speakers as they take the stage and share their insights on the latest marketing
                            trends,
                            tactics and topics.</p>
                        <p>Discover our array of different speech formats, ranging from panels, to interviews and presentations
                            among
                            others.</p>
                    </div>
                    <button class="main-btn" (click)="becomeASpeakerClick()">Become a Speaker</button>
                </div>
            </div>
            <div class="right">
                <div class="image"><img src="https://cdn-images.island-conference.com/plan/speakers/astronaut_speaker.png" alt=""></div>
            </div>
        </div>
    </div>

    <div class="speakers">
        <div class="speakers-bgr"></div>
        <div class="container">
            <div class="heading">
                <span>OUR SPEAKERS</span>
                <p>WE ARE BUSY PREPARING AN EXCITING LINE-UP OF SPEAKERS FOR 2024! BE SURE TO KEEP AN EYE OUT FOR THE
                    RELEASE COMING SOON!</p>

            </div>
            <!-- <div class="flexContainer">
                <speakers-card class="item" *ngFor="let speaker of speakers" [speaker]="speaker"></speakers-card>
            </div> -->
            <div class="speakers-wrapper flexr">
            
                <div class="container">
                    <div class="speaker" *ngFor="let dd of dialogData; let i = index" [ngClass]="{ filled: dialogData[i].speech_desc !== null || dialogData[i].bio !== null }" (click)="openDialog(i)">
                        <div class="speaker_image">
                            <div class="speaker_bgr"></div>
                            <div class="prof_img"><img src="{{dd.profile_pic}}" alt="{{dd.profile_pic_alt}}" style="max-width:{{dd.max_width}}"/></div>
                            <div class="more_info flexr">
                                <button><span><img src="https://cdn-images.island-conference.com/plan_speakers/arrow.svg" alt="Down chevron"/></span></button>
                            </div>
                        </div>
                        <div class="speaker_name">{{dd.name}} {{dd.surname}}</div>
                        <div class="position">{{dd.company}}<span *ngIf="dd.position">,</span> {{dd.position}}</div>
                        <div class="company"><img src="{{dd.company_logo}}" alt="{{dd.company_logo_alt}}"/></div>
                    </div>
                    <div class="speaker tuned">
                        <div class="speaker_image">
                            <div class="speaker_bgr"></div>
                            <p>STAY TUNED FOR MORE</p>
                        </div>
                    </div>
                    <div class="speaker tuned">
                        <div class="speaker_image">
                            <div class="speaker_bgr"></div>
                            <p>STAY TUNED FOR MORE</p>
                        </div>
                    </div>
                    <div class="speaker tuned">
                        <div class="speaker_image">
                            <div class="speaker_bgr"></div>
                            <p>STAY TUNED FOR MORE</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
