import { Component, Inject, OnInit } from '@angular/core';
import { IQuestionnaire, IQuestionnaireUserAnswers, ISpeakerChecked } from '../../interface/questionnaire.interface';
import { StepService } from '../../service/step.service';
import { UserAnswersService } from '../../service/userAnswers.service';
import { SPEAKERS_DATA } from '../../tokens/tokens';

@Component({
  selector: 'i-con-speakers',
  templateUrl: './speakers.component.html',
  styleUrl: './speakers.component.scss',
})
export class SpeakersComponent implements OnInit {
  constructor(@Inject(SPEAKERS_DATA) public content: IQuestionnaire, private userAnswersService: UserAnswersService, private stepService: StepService) {}
  selectedItem: IQuestionnaireUserAnswers;

  ngOnInit(): void {
    this.selectedItem = {
      questionId: this.content.id,
      answers: [
        {
          answerId: 0,
          value: [''],
        },
      ],
    };
  }

  onCheckboxChange(selectedSpeaker: ISpeakerChecked) {
    let answer = this.selectedItem.answers.find(answer => answer.answerId === selectedSpeaker.answerId);

    if (selectedSpeaker.isChecked) {
      if (!answer) {
        answer = {
          answerId: selectedSpeaker.answerId,
          value: [],
          subAnswers: [],
        };
        this.selectedItem.answers.push(answer);
      }
      answer.value.push(selectedSpeaker.speaker.name);
      answer.subAnswers?.push(selectedSpeaker.speaker.id);
    } else {
      if (answer) {
        const index = answer.value.indexOf(selectedSpeaker.speaker.name);
        if (index !== -1) {
          answer.value.splice(index, 1);
          answer.subAnswers?.splice(index, 1);
        }
        if (answer.value.length === 0) {
          const answerIndex = this.selectedItem.answers.indexOf(answer);
          this.selectedItem.answers.splice(answerIndex, 1);
        }
      }
    }
  }

  nextClick() {
    if (this.selectedItem && this.selectedItem.answers.length > 0) {
      //remove initial state
      this.selectedItem.answers.shift();
      this.userAnswersService.pushNewAnswer(this.selectedItem);
      this.stepService.goToQuestionWithId(this.getNextQuestionId()!);
    }
  }

  private getNextQuestionId() {
    const item = this.content.surveyAnswer.find(answer => answer.id === this.selectedItem.answers[0].answerId);

    if (item) {
      return item.nextQuestion;
    }

    return -1;
  }
}
