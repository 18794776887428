import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, shareReplay } from 'rxjs';
import { IBoothArrangement } from '../../_models';
// import { IBoothType } from '@i-con/shared/common';
import { IBoothType } from "../../../core/_models/booth.interface";
import { IBoothTicketType } from "../../../core/_models/booth-ticket.interface";
import { IBoothTypeResponse } from '../../_models/responses/booth-type-response.interface';
import { BoothService } from './booth.service';
import { StateService } from '@i-con/shared/common';

const TRST_BOOTHS = makeStateKey('booths');
const TRST_BOOTHSAVAILABILITY = makeStateKey('boothsavailability');

export interface IBoothState {
  booth_types: IBoothType[];
  selected_booth_type: IBoothType;
  booths_availability: IBoothArrangement[];
  selected_booth_id: string;
}
//{[key: number]: {[key: string]: IBoothArrangement}; } | any;

const initialState: IBoothState = {
  booth_types: [],
  selected_booth_type: {
    inStock: false,
    id: 1,
    name: '',
    referenceName: '',
    description: '',
    size: '',
    price: 0,
    color: '',
    floorSize: 0,
    boothTicketType: [{
      boothTypeId: 0,
      ticketTypeId: 0,
      quantity: 0,
      ticketType: {
        description: '',
        price: 0,
        discount: 0,
        onTheDoor: 0,
        discountEndsOn: new Date(),
        includes: [],
        inStock: false,
        id: 0,
        discounts: [],
        color: ''
      }
    }],
    modelUrl: '',
    includes: [],
    positionWeight: 0,
    discounts: [{
      id: 0,
      percentage: 0,
      name: '',
      description: '',
      startsOn: new Date(),
      endsOn: new Date(),
    }],
    comboImageUrl: '',
    quantity: 0
  },
  booths_availability: [],
  selected_booth_id: '',
    
};

@Injectable({
  providedIn: 'root',
})
export class BoothStateService extends StateService<IBoothState> {
  selected_booth_type$: Observable<IBoothType> = this.getState(
    (state) => state.selected_booth_type
  );
  //booths_availability$: Observable<{[key: number]: {[key: string]: IBoothArrangement}; }> =  this.getState((state) => state.booths_availability);
  booths_availability$: Observable<IBoothArrangement[]> = this.getState(
    (state) => state.booths_availability
  );
  booth_types$: Observable<IBoothType[]> = this.getState(
    (state) => state.booth_types
  );
  selected_booth_id$: Observable<string> = this.getState(
    (state) => state.selected_booth_id
  );

  booth_state$: Observable<IBoothState> = this.getState((state) => state).pipe(
    shareReplay({ refCount: true, bufferSize: 1 }) //make it multicast
  );

  constructor(
    private boothService: BoothService,
    private transferState: TransferState) {
    super(initialState);
    this.setBoothTypes();
  }

  updateSelectedBoothType(selected_booth_type: IBoothType) {
    //     console.log('IN UPDATE SELECTED BOOTH TYPE');
    this.setState({ selected_booth_type });
  }
  updateBoothsAvailability(booths_availability: IBoothArrangement[]) {
    this.setState({ booths_availability });
  }
  updateSelectedBoothId(selected_booth_id: string) {
    this.setState({ selected_booth_id });
  }

  setBoothTypes() {
    const state_data = this.transferState.get(TRST_BOOTHS, null as any);
    if (state_data) {
      this.setState({ booth_types: state_data });
      this.setState({ selected_booth_type: state_data[0] });
    }
    else {
      this.boothService.getBooths().subscribe((res: IBoothTypeResponse) => {
        this.setState({ booth_types: res.response.data.sort((a, b) => a.positionWeight - b.positionWeight) });
        this.setState({ selected_booth_type: res.response.data[0] });
        this.transferState.set(TRST_BOOTHS, res.response.data.sort((a, b) => a.positionWeight - b.positionWeight) as any);
      });
    }
  }
}
