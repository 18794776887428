<div class="information">
    <div class="bgImg" style="background-image: url({{card.imageUrl}})" (click)="onClick()">
        <img src="https://cdn-images.island-conference.com/vision/recap/albums/multiple-imgs.svg" alt="">
    </div>

    <div class="title">
        <span>{{card.title}}</span>
    </div>

    <div class="items">
        <span>{{card.items}} Items</span>
    </div>
</div>