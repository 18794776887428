<div class="content">
    <h1>{{content.title}}</h1>
    <img class="haze" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2 [innerHTML]="content.description"></h2>
    <div class="options">
        <div class="item" *ngFor="let item of content.surveyAnswer; let i = index" (click)="onItemClick(item)">
            <img [ngClass]="{'selected': isSelected(item)}"
                src="https://cdn-images.island-conference.com/questionnaire/{{item.imageUrl[1]}}" alt="">
            <p>{{item.description}}</p>
        </div>
    </div>

    <i-con-ctabuttons [nextEnabled]="selectedItem && selectedItem.answers.length > 1"
        (nextClick)="nextClick()"></i-con-ctabuttons>

</div>