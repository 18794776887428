<div class="content">
    <h1>{{content.title}}</h1>
    <img class="haze" src="https://cdn-images.island-conference.com/questionnaire/{{content.imageUrl}}" alt="">
    <h2 [innerHTML]="content.description"></h2>
    <div class="options">
        <div class="row" *ngFor="let item of content.surveyAnswer; let rowIndex = index">
            <span class="name">{{item.description}}</span>
            <div class="stars">
                <div class="star"
                    [ngClass]="{'hovered': hoveredStarIndices[rowIndex] !== null && subAnswerIndex <= hoveredStarIndices[rowIndex]!, 'selected': selectedRatings[rowIndex] !== undefined && subAnswerIndex <= selectedRatings[rowIndex]}"
                    *ngFor="let star of item.surveySubAnswers; let subAnswerIndex = index"
                    (click)="selectRating(item, rowIndex, star.id, subAnswerIndex )"
                    (mouseover)="hoveredStarIndices[rowIndex] = subAnswerIndex"
                    (mouseout)="hoveredStarIndices[rowIndex] = null">
                </div>
            </div>
        </div>


    </div>
    <i-con-ctabuttons
        [nextEnabled]="this.selectedItem && this.selectedItem.answers.length === this.content.surveyAnswer.length + 1"
        (nextClick)="nextClick()"></i-con-ctabuttons>

</div>